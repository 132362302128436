/* eslint-disable max-depth */
/* eslint-disable array-callback-return */
/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, getDate, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { GROUP_COLORS, priviliges } from '../../../Components/constants/constants';
import deleteGroup from '../../../assets/images/delmov.png';
import delGroup from '../../../assets/images/groupDelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { deviceGroupsSchema, moveGroupsSchema } from '../../AndroidEnterprise/Devices/TableSchema';

const DeviceGroupsTable = () => {
    document.title = 'Device Groups';
    const urlconf = useEnv();
    let history = useHistory();
    let api = new APIClient();

    const [loading, setLoading] = useState(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState([{ name: 'Main Group', icon: 'ri-home-8-fill' }]);
    const [subBreadcrumbItems, setSubBreadcrumbItems] = useState([{ name: 'Main Group', icon: 'ri-home-8-fill' }]);
    const [groups, setGroups] = useState([]);
    const [dGTableSchema, setDGTableSchema] = useState([]);
    const [dGMoveTableSchema, setDGMoveTableSchema] = useState([]);
    const [deviceGroupsBackup, setDeviceGroupsBackup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    // const [selectedGroupBeforeRadio, setSelectedGroupBeforeRadio] = useState('');
    const [mode, setMode] = useState('add');
    const [showAddModal, setShowAddModal] = useState(false);
    const [moveGroup, setMoveGroup] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [moveGroups, setMoveGroups] = useState([]);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [moveConfirmationModal, setMoveConfirmationModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [moveGroupsBackup, setMoveGroupsBackup] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchVal, setSearchVal] = useState('');

    useEffect(() => {
        setLoading(true);
        let createGroup = JSON.parse(localStorage.getItem('createGroup'));
        if (createGroup) {
            localStorage.removeItem('createGroup');
            setMode('add');
            setShowAddModal(true);
        }
        getGroupsWithParentID('');
        let userString = AuthUser();
        let user = JSON.parse(userString);
        let findPriv = !user?.data?.privileges?.includes(priviliges.DEVICEGROUP_EDITOR);
        let schema = [...deviceGroupsSchema];
        let schema1 = [...moveGroupsSchema];
        if (findPriv) schema = deviceGroupsSchema.filter((_group, index) => index < deviceGroupsSchema.length - 1);
        setDGTableSchema([...schema]);
        setDGMoveTableSchema([...schema1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {},
        validationSchema: Yup.object({
            name: Yup.string()
                .min(3, 'Name must have minimum 3 characters')
                .matches(/^[a-zA-Z0-9-_ ]+$/g, 'Group name can only include letters, numbers, hyphens, and underscore.')
                .required('Please enter your device group name')
        }),
        onSubmit: (values) => {
            if (values.name && values.name?.length < 3) {
                toast.error(toastMessages.nameLength);
                return;
            }
            setLoading(true);
            let params = { name: values.name?.trim(' '), status: 'Active', type: 'DEFAULT' };
            setSearchVal('');
            if (selectedGroup) params.parent = selectedGroup?.code;
            createNdUpdateData(params);
        }
    });

    const createNdUpdateData = (params) => {
        let apiService = '';
        setLoading(true);
        let updateParams = {};
        if (mode === 'edit') {
            updateParams.name = selectedRow.name?.trim(' ') === params.name?.trim(' ') ? undefined : params.name?.trim(' ');
        }
        if (mode === 'edit')
            apiService = api.patch(url.DEVICE_GROUPS_UNSTRUCTURED + '/' + selectedRow?._id, updateParams, false, domains.IDM);
        else apiService = api.create(url.DEVICE_GROUPS_UNSTRUCTURED, params, false, domains.IDM);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(mode === 'edit' ? toastMessages.deviceGroupUpdated : toastMessages.deviceGroupCreated);
                    validation.resetForm();
                    setMode('add');
                    setShowAddModal(false);
                    getGroupsWithParentID('', selectedGroup);
                } else {
                    setLoading(false);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.code);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setChildCounts = (groupObj) => {
        groupObj.children?.forEach((group) => {
            // group.childCount = group.children?.length > 0 ? group.children?.length : 0;
            group.avatarColor = groupObj.avatarColor;
            group.label = group.name + ' (' + group.childCount + ')';
            if (group.children?.length > 0) group.children = setChildCounts(group);
        });
        return groupObj.children;
    };

    const setChildGroups = (groupArr, groupObj, avatarColor, parent) => {
        let data = [];
        groupArr = groupArr.filter((group) => group.code !== groupObj.code);
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.avatarColor = avatarColor;
            group.parent = parent;
            if (group.children) {
                let obj = setChildGroups(group.children, groupObj, avatarColor, group.code);
                group.children = obj.children;
                data.children = obj.data;
            }
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return { data: data, children: groupArr };
    };

    const getMoveGroups = (params, groupObj) => {
        api.get(url.DEVICE_GROUPS, params, domains.IDM)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let data = [];
                    resp.data = resp.data?.filter((dataObj) => dataObj.code !== groupObj.code);
                    resp.data.forEach((group, index) => {
                        group.label = group.name;
                        group.key = group.code;
                        group.value = group.code;
                        group.nameCode = group.name + ' - ' + group.code;
                        group.avatarColor = GROUP_COLORS[index % 10];
                        if (group.children) {
                            let obj = setChildGroups(group.children, groupObj, group.avatarColor, group.code);
                            group.children = obj.children;
                            data.children = obj.data;
                        }
                        group.childsCount = group.children.length ? group.children.length : 0;
                        if (group.code !== 'DEFAULT') data.push(group);
                    });
                    setMoveGroups(data);
                    setMoveGroupsBackup(data);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const setChildrenGroups = (dataArr) => {
        dataArr.children.forEach((dataObj) => {
            dataObj.parent = dataArr.code;
            if (dataObj.children.length > 0) dataObj.children = setChildrenGroups(dataObj);
        });
        return dataArr;
    };

    const getGroupsWithParentID = (params, selGroup, result) => {
        let apiService;
        if (selGroup?._id) apiService = api.get(url.DEVICE_GROUPS_UNSTRUCTURED + '/' + selGroup._id, params, domains.IDM);
        else apiService = api.get(url.DEVICE_GROUPS, params, domains.IDM);
        apiService
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    let dataArr = selGroup?._id ? resp.data?.children : resp.data;
                    dataArr.forEach((data, index) => {
                        data.label = data.name;
                        data.key = data.code;
                        data.parent = selGroup?.code;
                        data.lastModifiedDate = getDate(data.modifieddate);
                        data.avatarColor = selGroup?.avatarColor ? selGroup.avatarColor : GROUP_COLORS[index % 10];
                        data.childsCount = data.children?.length > 0 ? data.children?.length : 0;
                        if (data.children.length > 0) {
                            data.children = setChildrenGroups(data);
                        }
                        if (data.code === 'DEFAULT') {
                            data.hideRadio = true;
                            data.hideView = true;
                            data.hideActions = true;
                        }
                    });

                    if (result) {
                        result = dataArr.find((data) => data._id === result._id);
                        dataArr = [{ ...result }];
                    }
                    dataArr = noCaseSensitiveSortBy(dataArr, 'label');
                    setDeviceGroupsBackup(dataArr);
                    setGroups(dataArr);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleBreadcrumb = (crumb, ind) => {
        setSearchVal('');
        if (ind !== breadcrumbItems.length - 1) {
            let crumbs = [...breadcrumbItems];
            crumbs.splice(ind + 1);
            setBreadcrumbItems(crumbs);
            setSelectedGroup(crumb);
            setLoading(true);
            getGroupsWithParentID('', crumb);
        }
    };

    const disableMove = () => {
        return groups.filter((groupObj) => groupObj.checked)?.length === 0;
    };

    const setParentArr = (parent, obj) => {
        if (parent.parent) {
            obj.path.push(parent.parent.name);
            obj.breadcrumbItems.push(parent.parent);
            if (parent.parent.parent) {
                let parentObj = setParentArr(parent.parent, obj);
                obj.path = parentObj.path;
                obj.breadcrumbItems = parentObj.breadcrumbItems;
            }
        }
        let returnObj = { path: obj.path, breadcrumbItems: obj.breadcrumbItems };
        return returnObj;
    };

    const handleGlobalSearch = (val) => {
        setLoading(true);
        setSearchVal(val);
        if (val)
            api.get(url.DEVICE_GROUPS_UNSTRUCTURED + '/flat', { search: val }, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        let results = [];
                        resp.data.forEach((data) => {
                            let obj = JSON.parse(JSON.stringify(data));
                            obj.path = [];
                            obj.breadcrumbItems = [];
                            if (data.path) {
                                data.path = data.path.filter((dataObj) => dataObj.code !== data.code);
                                obj.breadcrumbItems = [...data.path];
                                obj.path = data.path?.map((path) => path.name);
                            }
                            obj.breadcrumbItems.unshift({ name: 'Main Group', icon: 'ri-home-8-fill' });
                            obj.path.unshift('Main Group');
                            results.push(obj);
                        });
                        setSearchResults(results);
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        else getGroupsWithParentID('', selectedGroup);
    };

    const handleRadioChange = (_e, ind) => {
        let rows = [...groups];
        rows.forEach((rowObj, index) => {
            if (index === ind) {
                if (!rowObj.checked) {
                    setSelectedGroup(rowObj);
                    // setSelectedGroupBeforeRadio(selectedGroupBeforeRadio ? selectedGroupBeforeRadio : selectedGroup);
                } else {
                    setSelectedGroup({});
                    // setSelectedGroupBeforeRadio('');
                }
                rowObj.checked = !rowObj.checked;
            } else rowObj.checked = false;
        });
        setGroups(rows);
    };

    const handleFilter = () => {
        setLoading(true);
        setMoveGroup({});
        setSelectedRow({});
        setLoading(true);
        getGroupsWithParentID('', selectedGroup);
    };

    const handleAddNew = () => {
        setShowAddModal(true);
        setMode('add');
    };

    const handleView = (_cell, row) => {
        setBreadcrumbItems([...breadcrumbItems, row.original]);
        setSelectedGroup(row.original?._id ? row.original : '');
        // setSelectedGroupBeforeRadio('');
        setLoading(true);
        setSearchVal('');
        getGroupsWithParentID('', row.original);
    };

    const handleMove = (row) => {
        setSelectedRow(row.original);
        setLoading(true);
        setShowMoveModal(true);
        getMoveGroups('', row.original);
    };

    const handleRename = (row) => {
        setSelectedRow(row.original);
        validation.setValues({ ...validation.values, name: row.original.name, code: row.original.code });
        setShowAddModal(true);
        setMode('edit');
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row.original);
    };

    const toggleAdd = useCallback(async () => {
        validation.resetForm();
        if (showAddModal) setShowAddModal(false);
        else setShowAddModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAddModal]);

    const toggle = useCallback(async () => {
        setMoveGroup({});
        setSubBreadcrumbItems([{ name: 'Main Group', icon: 'ri-home-8-fill' }]);
        if (showMoveModal) setShowMoveModal(false);
        else setShowMoveModal(true);
    }, [showMoveModal]);

    const handleMoveConfirm = () => {
        setShowMoveModal(false);
        setMoveGroups([]);
        setLoading(true);
        setSearchResults([]);
        setSearchVal('');
        setMoveConfirmationModal(false);
        api.patch(
            url.DEVICE_GROUPS_UNSTRUCTURED + '/' + selectedRow._id,
            { parent: moveGroup.code ? moveGroup.code : '' },
            false,
            domains.IDM
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.deviceGroupMoved);
                    setMoveGroup({});
                    setLoading(true);
                    setSubBreadcrumbItems([{ name: 'Main Group', icon: 'ri-home-8-fill' }]);
                    getGroupsWithParentID('', selectedGroup);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleMoveView = (_cell, row) => {
        setMoveGroup(row.original);
        setSubBreadcrumbItems([...subBreadcrumbItems, row.original]);
        setMoveGroups(row.original.children ? row.original.children : []);
    };

    const handleSubBreadcrumb = (group, ind) => {
        if (ind !== subBreadcrumbItems.length - 1) {
            let crumbs = [...subBreadcrumbItems];
            crumbs.splice(ind + 1);
            setSubBreadcrumbItems(crumbs);
            if (!group.key) {
                setMoveGroup({});
                let groupsArr = JSON.parse(JSON.stringify(moveGroupsBackup));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setMoveGroups(groupsArr);
            } else {
                setMoveGroup(group);
                let groupsArr = JSON.parse(JSON.stringify(group.children));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setMoveGroups(groupsArr);
            }
        }
    };

    const handleMoveDevices = (row) => {
        setShowMoveModal(false);
        setMoveGroup([]);
        setLoading(true);
        api.update(url.DEVICES + '/' + selectedRow.code + '/to/' + row.original?.code)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.devicesMoved);
                    setMoveGroup({});
                    setMoveConfirmationModal(false);
                    getGroupsWithParentID('', selectedGroup);
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleSubRadioChange = (_e, ind) => {
        let rows = [...moveGroups];
        rows.forEach((rowObj, index) => {
            if (index === ind) {
                if (!rowObj.checked) setMoveGroup(rowObj);
                else setMoveGroup({});
                rowObj.checked = !rowObj.checked;
            } else rowObj.checked = false;
        });
        setMoveGroups(rows);
    };

    const handleOffcanvasBody = () => {
        return (
            <React.Fragment>
                <div>
                    <span className="text-muted">Active Group : </span>
                    <Label className="text-success">{selectedRow.name}</Label>
                </div>
                <div className="w-100 shadow-card border m-0 page-title-box d-sm-flex align-items-center justify-content-start">
                    <div className={'d-flex align-items-center' + (!moveGroup?._id ? ' justify-content-between' : '')}>
                        {subBreadcrumbItems?.map((crumbs, ind) => (
                            <React.Fragment key={ind}>
                                {ind !== subBreadcrumbItems?.length - 1 ? (
                                    <span
                                        className={`fw-medium font-size-12 text-muted ${
                                            ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                        }`}
                                        onClick={() => handleSubBreadcrumb(crumbs, ind)}
                                    >
                                        {crumbs.icon ? <i className={crumbs.icon + ' text-muted font-size-15 ms-2'} /> : crumbs.name}
                                    </span>
                                ) : (
                                    <span
                                        className={`fw-medium font-size-12 ${
                                            ind !== subBreadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                        }`}
                                        style={{ color: crumbs.avatarColor }}
                                    >
                                        {crumbs.icon ? <i className={crumbs.icon + ' text-muted font-size-15 ms-2'} /> : crumbs.name}
                                    </span>
                                )}
                                {ind !== subBreadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <TableContainer
                    loading={loading}
                    data={moveGroups}
                    columns={dGMoveTableSchema}
                    handleView={handleMoveView}
                    handleMove={handleMoveDevices}
                    customPageSize={10}
                    totalRecords={moveGroups.length ? moveGroups.length : 0}
                    handleRadioChange={handleSubRadioChange}
                    editor={priviliges.DEVICEGROUP_EDITOR}
                    reader={priviliges.DEVICEGROUP_READER}
                    className="custom-header-css"
                    tableHeader="All Device Groups"
                    divClass="table-responsive table-card"
                    tableClass="table table-nowrap table-border table-centered align-middle"
                    theadClass="bg-light text-muted"
                />
            </React.Fragment>
        );
    };

    const handleOffcanvasBodyAdd = () => {
        return (
            <React.Fragment>
                <div>
                    <Row className="d-flex align-items-center">
                        <Col xs={12} sm={12} md={12} xl={5} lg={5}>
                            <Label className="form-label mb-0 d-flex align-items-center fw-medium">
                                Device Group Name
                                {mode !== 'view' ? <span className="red-color ps-1"> *</span> : <span className="ps-1"> :</span>}
                            </Label>
                        </Col>
                        <Col>
                            <div className="input-group">
                                {mode !== 'view' ? (
                                    <Input
                                        name={'name'}
                                        id={'Device Group Name'}
                                        className="form-control"
                                        placeholder={'Enter Device Group Name'}
                                        type={'text'}
                                        maxLength={100}
                                        validate={{ required: { value: true } }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation?.values.name || ''}
                                        invalid={validation?.touched.name && validation?.errors.name ? true : false}
                                    />
                                ) : validation?.values.name ? (
                                    validation?.values.name
                                ) : (
                                    '–'
                                )}
                                {validation?.touched.name && validation?.errors.name ? (
                                    <FormFeedback type="invalid">{validation?.errors.name}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);
        api.delete(url.DEVICE_GROUPS_UNSTRUCTURED + '/' + selectedRow._id, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.deviceGroupDeleted);
                    setSearchResults([]);
                    setSearchVal('');
                    setMoveGroup({});
                    setSelectedRow({});
                    setLoading(true);
                    getGroupsWithParentID('', selectedGroup);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleMoveHere = () => {
        if ((!selectedRow.parent && !moveGroup.code) || selectedRow.parent === moveGroup.code) {
            setShowErrorModal(true);
            return;
        }
        setMoveConfirmationModal(true);
    };

    const renderErrorMessage = () => {
        return (
            <div className="d-flex align-items-center text-danger fw-medium">
                It is prohibited to relocate the group to its previous location.
            </div>
        );
    };

    const renderMessage = () => {
        return moveGroup.name ? (
            <div className="text-muted">
                Are you sure you want to move{' '}
                <div className="mt-1">
                    <span className="text-info fw-medium">{selectedRow.name}</span> to{' '}
                    <span className="text-info fw-medium">{moveGroup.name}</span>
                </div>
            </div>
        ) : (
            <div className="text-muted">
                Are you sure you want to move <span className="text-info fw-medium">{selectedRow.name}</span> as root group
            </div>
        );
    };

    const saveDisabled = () => {
        if (!validation.values.name || validation.values.name === '' || validation.errors.name) return true;
        else return false;
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete Device Group?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={delGroup} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Group Name: <span className="fw-semibold">{selectedRow?.name}</span>
                </div>
            </div>
        );
    };

    const handleTreeView = (row) => {
        history.push('/devicegroups/treeview/' + row.original._id);
    };

    const handleSearchSelect = (selGroup, breadCrumbs, result) => {
        setBreadcrumbItems(breadCrumbs);
        setSelectedGroup(selGroup);
        // setSelectedGroupBeforeRadio('');
        setLoading(true);
        getGroupsWithParentID('', selGroup, result);
    };

    const handleResultSelect = (result) => {
        handleSearchSelect(result.breadcrumbItems?.[result.breadcrumbItems?.length - 1], result.breadcrumbItems, result);
    };

    const handleAuditLog = (row) => {
        history.push('/devicegroups/auditlog/' + row?.original?._id);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-start">
                    {breadcrumbItems?.map((crumbs, ind) => (
                        <React.Fragment key={ind}>
                            {ind !== breadcrumbItems?.length - 1 ? (
                                <span
                                    className={`fw-medium font-size-14 text-muted ${
                                        ind !== breadcrumbItems?.length - 1 ? ' cursor-pointer' : ''
                                    }`}
                                    onClick={() => handleBreadcrumb(crumbs, ind)}
                                >
                                    {crumbs?.icon ? <i className={crumbs.icon + ' text-muted font-size-15 ms-2'} /> : crumbs.name}
                                </span>
                            ) : (
                                <span
                                    className={`fw-medium font-size-14 ${ind !== breadcrumbItems?.length - 1 ? ' cursor-pointer' : ''}`}
                                    style={{ color: crumbs.avatarColor }}
                                >
                                    {crumbs?.icon ? <i className={crumbs.icon + ' text-muted font-size-15 ms-2'} /> : crumbs.name}
                                </span>
                            )}
                            {ind !== breadcrumbItems?.length - 1 && <i className="ri-arrow-right-s-line ms-2" />}
                        </React.Fragment>
                    ))}
                </div>
                <Container fluid>
                    {/* <BreadCrumb pageTitle="Device Groups" history={history} homeLink="Dashboard" /> */}
                    <Col xl={12}></Col>
                    <Col xl={12}>
                        <TableContainer
                            data={groups}
                            columns={dGTableSchema}
                            isGlobalFilter={true}
                            addButton={true}
                            loading={loading}
                            searchFilterWithPanel={true}
                            hideRadio={true}
                            refresh2={true}
                            searchResults={searchResults}
                            disableMove={disableMove()}
                            handleGlobalSearch={handleGlobalSearch}
                            searchVal={searchVal}
                            handleRadioChange={handleRadioChange}
                            handleFilter={handleFilter}
                            handleAddNew={handleAddNew}
                            handleView={handleView}
                            handleMove={handleMove}
                            totalRecords={groups.length ? groups.length : 0}
                            handleRename={handleRename}
                            handleTreeView={handleTreeView}
                            customPageSize={10}
                            handleDelete={handleDelete}
                            handleResultSelect={handleResultSelect}
                            handleAuditLog={handleAuditLog}
                            tableHeader="All Device Groups"
                            searchPlaceHolder={'Group Name'}
                            editor={priviliges.DEVICEGROUP_EDITOR}
                            reader={priviliges.DEVICEGROUP_READER}
                            className="custom-header-css "
                            divClass="table-responsive table-card"
                            tableClass="table table-nowrap table-border table-centered align-middle"
                            theadClass="bg-light text-muted"
                        />
                    </Col>
                    <CommonModal
                        size={'md'}
                        cancelText={'Cancel'}
                        saveText={mode === 'add' ? 'Save' : 'Update'}
                        modalheader={mode === 'add' ? 'Add New Group' : 'Edit Group'}
                        show={showAddModal}
                        disabled={saveDisabled()}
                        onCloseClick={() => toggleAdd()}
                        handleClick={() => validation.handleSubmit()}
                        handleModalBody={() => handleOffcanvasBodyAdd()}
                    />
                    {/* <OffcanvasModal
                        direction="end"
                        toggle={toggleAdd}
                        open={showAddModal}
                        handleCloseClick={toggleAdd}
                        OffcanvasModalID="newgroup"
                        handleOffcanvasBody={handleOffcanvasBodyAdd}
                        handleSaveClick={validation.handleSubmit}
                        saveDisabled={saveDisabled()}
                        modalClassName={'w-40'}
                        saveText={mode === 'add' ? 'Save' : 'Update'}
                        offcanvasHeader={mode === 'add' ? 'Add New Group' : 'Edit Group'}
                    /> */}
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        loading={loading}
                        open={showMoveModal}
                        handleCloseClick={toggle}
                        OffcanvasModalID="movegroup"
                        handleOffcanvasBody={handleOffcanvasBody}
                        handleSaveClick={handleMoveHere}
                        modalClassName={'w-40'}
                        saveText="Move Here"
                        offcanvasHeader="Move group"
                    />
                    <DeleteModal
                        show={moveConfirmationModal}
                        onCloseClick={() => setMoveConfirmationModal(false)}
                        hideIcon={true}
                        imgWidth={300}
                        image={deleteGroup}
                        deleteMessage={
                            moveGroup.name
                                ? `Are you sure you want to move ${selectedRow.name} to ${moveGroup.name}`
                                : `Are you sure you want to move ${selectedRow.name} as root group`
                        }
                        onDeleteClick={handleMoveConfirm}
                        confirmText="Yes, Move it!"
                        cancelText="No"
                        hideDeleteIcon={true}
                        hideDeleteMessage={true}
                        message={renderMessage()}
                    />
                    <DeleteModal
                        show={showErrorModal}
                        onCloseClick={() => setShowErrorModal(false)}
                        hideIcon={true}
                        showAlert={true}
                        hideDelete={true}
                        hideAreYouSure={true}
                        cancelText="Ok"
                        hideDeleteMessage={true}
                        message={renderErrorMessage()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DeviceGroupsTable;
