/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import AnimatedTreeComponent from '../../../Components/Common/AnimatedTreeComponent';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { commonActionsService, tvDeviceActions } from '../../../Components/Common/DeviceUtils';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    AuthUser,
    Enterprise,
    TenantConfig,
    convertDate,
    convertTextCase,
    convertUTCtoIST,
    diffTwoDatesHrsMintsSeconds,
    noCaseSensitiveSortBy,
    uniqBy
} from '../../../Components/Common/Util';
import Widgets from '../../../Components/Common/Widgets';
import { togglePasswordChangeVal } from '../../../Components/Hooks/UserHooks';
import toastMessages from '../../../common/messages/toastMessages';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { androidTVSchema } from '../TableSchema';
import { handleDeleteBody, handleModalBody, handleNotesModalBody, handlePGChangeBody, messageFunc } from './AndroidTVDevicesFunc';
// import Filters from './AndroidTVDevicesFilters';
import { useHistory } from 'react-router-dom';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { priviliges } from '../../../Components/constants/constants';
import ActiveDevices from '../../../assets/images/tvlogos/activeTvs.svg';
import ProvisioningDevices from '../../../assets/images/tvlogos/provisioningTvs.svg';
import TotalDevices from '../../../assets/images/tvlogos/totalTvs.svg';
import { useEnv } from '../../../envContext';
import * as domains from '../../../helpers/domain_helper';
import Filters from './AndroidTVDevicesFilters';

const AndroidTVDevices = () => {
    let history = useHistory();
    document.title = 'Devices';
    const urlconf = useEnv();
    let eObj = Enterprise();

    const deviceStatusArray = [
        { label: 'Enrolled', code: 'ENROLLED', count: 0, checked: false },
        { label: 'Not Enrolled', code: 'NEW', count: 0, checked: false }
    ];

    let emptySearchObj = {
        selectedFiltersKeys: [],
        deviceStatusArr: deviceStatusArray,
        policiesArr: [],
        applicationsArr: []
    };

    const api = new APIClient();
    const tenantConfig = TenantConfig();
    const enterpriseObj = eObj ? JSON.parse(eObj) : {};

    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({
        select: {
            page: 1,
            size: 10
        }
    });
    const [elasticSearchObj, setElasticSearchObj] = useState(emptySearchObj);
    const [WidgetCount, setWidgetCount] = useState({});
    const [resetLoader, setResetLoader] = useState(false);
    const [checkedData, setCheckedData] = useState([]);
    const [expandedData, setExpandedData] = useState([]);
    const [devicesTableSchema, setDevicesTableSchema] = useState([]);
    const [notes, setNotes] = useState('');
    const [wipeExternalStorage, setWipeExternalStorage] = useState(false);
    const [wipeReasonMessage, setWipeReasonMessage] = useState(false);
    const [wipeReasonMessageValue, setWipeReasonMessageValue] = useState('');
    const [refreshTime, setRefreshTime] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [bulkActionModal, setBulkActionModal] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsBackup, setGroupsBackup] = useState([]);
    const [filterCriteriaOptions, setFilterCriterialOptions] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [type, setType] = useState('');
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [exportAllLabel, setExportAllLabel] = useState('Export All');
    const [volume, setVolume] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [confirmedElasticSearchObj, setConfirmedElasticSearchObj] = useState(emptySearchObj);
    const [confirmedSearchParams, setConfirmedSearchParams] = useState({
        select: {
            page: 1,
            size: 10
        }
    });

    const toastMessage = {
        WIPE: toastMessages.factoryReset,
        LOCK: toastMessages.lockDevice,
        REBOOT: toastMessages.rebootDevice,
        CAMERA_ENABLE: toastMessages.cameraEnable,
        CAMERA_DISABLE: toastMessages.cameraDisable
    };

    const [appStatus, setAppStatus] = useState([
        { label: 'Latest', class: 'success', value: '3', checked: false },
        { label: 'Not Latest', class: 'warning', value: '2', checked: false },
        { label: 'Not Installed', class: 'danger', value: '1', checked: false }
    ]);

    const [actionTypes, setActionTypes] = useState([
        { label: 'Add/Update Notes on Device', value: 'NOTES' },
        { label: 'Device Factory Reset', value: 'RESET' },
        { label: 'Lock Device', value: 'LOCK' },
        { label: 'Reboot Device', value: 'REBOOT' },
        { label: 'Reset Device Password', value: 'RESET_PASSWORD' },
        { label: 'Update Device Group on Device', value: 'NEWGROUP' },
        { label: 'Update Policy on Device', value: 'NEWPOLICY' }
    ]);
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';

    useEffect(() => {
        let schema = [...androidTVSchema];
        let findPriv = !user?.data?.privileges?.includes(priviliges.TVDEVICE_EDITOR);
        if (findPriv) schema = androidTVSchema.filter((_device, index) => index < androidTVSchema.length - 2);
        setDevicesTableSchema(schema);
        handlePromise({ ...searchParams });
    }, []);

    const handlePromise = (params) => {
        setLoading(true);
        let devicePromise = new Promise((resolve, reject) => {
            api.create(url.TV_DEVICES, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp);
                })
                .catch((err) => reject('Device failed.'));
        });
        let policyPromise = new Promise((resolve, reject) => {
            if (tenantConfig.DEVICE_POLICY)
                api.get(url.TV_POLICIES, { page: 1, size: 100 })
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        resolve(resp.data);
                    })
                    .catch((err) => reject('Policy failed.'));
            else reject('Policy not enabled');
        });
        let groupPromise = new Promise((resolve, reject) => {
            if (tenantConfig.DEVICE_GROUPS)
                api.get(url.DEVICE_GROUPS, { page: 1, size: 100 }, domains.IDM)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        resolve(resp.data);
                    })
                    .catch((err) => reject('Group failed.'));
            else reject('Groups not enabled');
        });
        let tvMetrics = new Promise((resolve, reject) => {
            api.get(url.TV_DASHBOARD)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp.data);
                })
                .catch((err) => reject('Metrics failed'));
        });
        Promise.allSettled([devicePromise, policyPromise, groupPromise, tvMetrics])
            .then(async (result) => {
                let elastic = { ...elasticSearchObj };
                if (result[0].status === 'fulfilled') {
                    await handleDevices(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    let arr = [];
                    result[1].value.forEach((val) => arr.push({ label: val.name, value: val.code, code: val.code, checked: false }));
                    elastic.policiesArr = arr;
                }
                if (result[2].status === 'fulfilled') {
                    elastic.tagListArr = JSON.parse(JSON.stringify(result[2].value));
                    let groupsArr = JSON.parse(JSON.stringify(result[2].value));
                    setGroupsBackup(groupsArr);
                    await prepareGroups(result[2].value);
                }
                if (result[3].status === 'fulfilled') {
                    let widgetCounts = WidgetCount;
                    widgetCounts.totalDevicesCount = result[3].value?.totalCount;
                    widgetCounts.deviceActiveCount = result[3].value?.enrolledDevices;
                    widgetCounts.deviceProvisioningCount = result[3]?.value?.notEnrolledDevices;
                    setWidgetCount(widgetCounts);
                }
                setElasticSearchObj(elastic);
                setConfirmedElasticSearchObj(elastic);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const setChildGroups = (groupArr, groupsData) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        return { children: groupArr, groupsData: groupsData };
    };

    const prepareGroups = (tagArr) => {
        let groupsData = [];
        tagArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        setFlatGroups(groupsData);
        tagArr = noCaseSensitiveSortBy(tagArr, 'name');
        return tagArr;
    };

    const handleDevices = async (deviceData) => {
        if (deviceData.data?.length > 0) {
            deviceData.data?.forEach((data) => {
                data.phone = data.phoneNumber ? data.phoneNumber : '—';
                data.schoolName = data.schoolName ? data.schoolName : '—';
                data.schoolCode = data.schoolCode ? data.schoolCode : '—';
                data.location = data.location ? data.location : '—';
                data.group = data.groupName ? data.groupName : '—';
                data.user = data.userName ? data.userName : '—';
                data.notes = data.notes ? data.notes : '';
                data.showActions = data.deviceStatus === 'ENROLLED';
                data.source = data.source ? data.source : 'UNRECOGNIZED';
                data.onlineStatusClass =
                    data.onlineStatus === 'ONLINE'
                        ? 'success'
                        : data.onlineStatus === 'OFFLINE'
                        ? 'danger'
                        : data.onlineStatus === 'AWAY'
                        ? 'warning'
                        : 'dark-gray';
                let date = data?.metrics?.last_modified_date;
                let { hours, minutes, days, weeks } = diffTwoDatesHrsMintsSeconds(date);
                var hDisplay = Number(hours) > 0 ? hours + (Number(hours) === 1 ? ' hour' : ' hours') : '';
                var mDisplay =
                    Number(hours) === 0 && Number(minutes) === 0
                        ? 'Now'
                        : (Number(hours) === 0 ? '' : ', ') + minutes + (Number(minutes) === 1 ? ' minute ago' : ' minutes ago');
                // var sDisplay = seconds + (seconds === 1 ? ' second ago' : ' seconds ago');
                var dDisplay = days + (Number(days) === 1 ? ' day ago' : ' days ago');
                let wDisplay = weeks + (Number(weeks) === 1 ? ' week ago' : ' weeks ago');
                data.onlineStatusTooltip =
                    'Last reported: ' +
                    (date && data.onlineStatus
                        ? convertTextCase(data.onlineStatus, true) +
                          ': ' +
                          (weeks > 0 ? wDisplay : days > 0 ? dDisplay : hDisplay + mDisplay)
                        : 'Not Seen Yet');
                data.deviceStatus = data.deviceStatus && data.deviceStatus?.toUpperCase() !== 'NEW' ? data.deviceStatus : 'Not Enrolled';
                data.badgeClass = data.deviceStatus && data.deviceStatus === 'ENROLLED' ? 'success' : 'danger';
                data.modifiedBy = data.lastModifiedBy ? data.lastModifiedBy : '—';
                data.modifiedDate = data.lastModifiedTime ? convertUTCtoIST(data.lastModifiedTime) : '—';
                data.group = data.groupName ? data.groupName : '—';
                data.policy = data.policyName ? data.policyName : '—';
                data.hideMapView = data.deviceStatus !== 'ENROLLED';
                data.rowView = data.deviceStatus !== 'Not Enrolled';
                data.brand = data.metrics?.brand ? convertTextCase(data.metrics?.brand, true) : '—';
                data.model = data.metrics?.model ? data.metrics?.model : '—';
                data.android_version = data?.metrics?.android_version ? data?.metrics?.android_version : '—';
                data.lastSecurityPatch = data?.metrics?.security_patch ? data?.metrics?.security_patch : '—';
            });
            let unSelectedDevices = deviceData.data.filter((device) => !device.checked);
            if (unSelectedDevices?.length > 0) setCheckAll(false);
            else setCheckAll(true);
            setDevices(deviceData.data);
            setTotalRecords(deviceData.totalRecords);
        } else {
            setDevices([]);
            setTotalRecords(0);
            setCheckAll(false);
            setSelectedDevices([]);
        }
    };

    const getDevices = (paramsObj) => {
        setLoading(true);
        let params = {};
        if (paramsObj) params = { ...params, ...paramsObj };
        api.create(url.TV_DEVICES, params)
            .then(async (resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                await handleDevices(resp);
                setLoading(false);
                setDeleteModal(false);
            })
            .catch((_err) => {
                setLoading(false);
                setDeleteModal(false);
            });
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setLoading(true);
        let params = { commandType: 'REMOTE_POWERWASH', payload: null };
        api.create(url.CHROME_DEVICES + '/' + selectedDevice.original.deviceId, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.factoryReset);
                    getDevices({ ...searchParams });
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleDeviceReboot = (row) => {
        setSelectedDevice(row);
        setType('reboot');
        setActionText('Yes, Reboot it!');
        setConfirmationModal(true);
    };

    const handleDeviceLock = (row) => {
        setSelectedDevice(row);
        setType('lock');
        setActionText('Yes, Lock it!');
        setConfirmationModal(true);
    };

    const handleResetPassword = (row) => {
        setShowModal(true);
        setSelectedRow(row);
    };

    const handleFactoryReset = (row) => {
        setSelectedDevice(row);
        setType('wipe');
        setActionText('Yes, Reset it!');
        setConfirmationModal(true);
    };

    const handleNotes = (row) => {
        validation1.setValues({ ...validation1.values, notes: row.original.note });
        setSelectedRow(row);
        setShowNotesModal(true);
    };

    const handleGlobalSearch = (val) => {
        if (val?.trim()?.length < 3) return;
        let params = {};
        if (val) {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: { ...searchParams.filter, serial: val?.trim() }
            };
            setLoading(true);
            getDevices(params);
        } else {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: { ...searchParams.filter, serial: undefined }
            };
            setLoading(true);
            getDevices(params);
        }
        if (
            params.filter?.deviceStatus?.length > 0 ||
            params.filter?.group?.length > 0 ||
            params.filter?.onlineStatus?.length > 0 ||
            params.filter?.policy?.length > 0 ||
            params.filter.serial
        )
            setExportAllLabel('Export');
        else setExportAllLabel('Export All');
        setPageNumber(1);
        setSearchParams({ ...params });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: {
                    ...searchParams.filter,
                    dateFrom: convertDate(val?.[0], '-'),
                    dateTo: convertDate(val?.[1], '-')
                }
            };
            setSearchParams({ ...params });
            setLoading(true);
            getDevices(params);
        } else {
            params = {
                ...searchParams,
                select: { page: 1, size: pageSize },
                filter: { ...searchParams.filter, dateFrom: null, dateTo: null }
            };
            setLoading(true);
            getDevices(params);
        }
        if (
            params.filter?.appStatus?.length > 0 ||
            params.filter?.apps?.length > 0 ||
            params.filter?.deviceStatus?.length > 0 ||
            params.filter?.group?.length > 0 ||
            params.filter?.onlineStatus?.length > 0 ||
            params.filter?.policy?.length > 0 ||
            params.filter?.search?.length > 0 ||
            params.filter?.dateFrom?.length > 0 ||
            params.filter?.dateTo?.length > 0
        )
            setExportAllLabel('Export');
        else setExportAllLabel('Export All');
        setPageNumber(1);
        setSearchParams({ ...params });
    };

    const handleUpload = (file) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('id', enterpriseObj.id);
        setLoading(true);
        api.create(url.DEVICES_UPLOAD, formData, true)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.deviceUploaded);
                    getDevices();
                    setLoading(false);
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const onPageChange = (pgNumber, pgSize) => {
        let params = { ...searchParams, select: { page: pgNumber, size: pgSize } };
        setPageNumber(pgNumber);
        setPageSize(pgSize);
        setSearchParams({ ...params });
        window.scrollTo(0, 0);
        getDevices(params);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { newPassword: '', default_password: 'true' },
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Please Enter Your New Password')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (obj) => {
        commonActionsService({
            deviceID: selectedRow?.original?.serial,
            type: 'resetpassword',
            values: obj,
            setLoading: setResetLoader,
            setShowModal: setShowModal,
            urlconf: urlconf
        });
        validation.resetForm();
    };

    const validation1 = useFormik({
        enableReinitialize: true,
        initialValues: { notes: notes && notes !== '' ? notes : '' },
        validationSchema: Yup.object({ notes: Yup.string().required('Please Enter Your Notes') }),
        onSubmit: (values) => {
            setLoading(true);
            setShowNotesModal(false);
            let params = { updateType: 'NOTES', notes: values.notes?.trim() };
            api.update(url.TV_DEVICES + '/update/' + selectedRow.original.serial, { note: validation1.values?.notes })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(notes && notes !== '' ? toastMessages.notesUpdated : toastMessages.notesAdded);
                        setNotes(resp.data.notes);
                        setLoading(false);
                        validation1.resetForm();
                        getDevices({ ...searchParams });
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    });

    const bulkAction = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {
            setLoading(true);
            let dataObj = {};
            if (selectedDevices?.length > 0) {
                let selectedSerials = selectedDevices.map((device) => device.serial);
                dataObj.serial = selectedSerials?.join(',');
                dataObj.action = values?.actionType?.value;
                dataObj.purpose = values?.purpose;
                if (values?.actionType?.value === 'RESET_PASSWORD') {
                    if (validation.values['default_password'] !== 'true' && !validation.values.newPassword) {
                        toast.error(toastMessages.providePassword);
                        return;
                    }
                    dataObj.params = JSON.stringify({
                        newPassword:
                            validation.values['default_password'] === 'true'
                                ? tenantConfig?.DEVICE_DEFAULT_PASSWORD
                                : validation.values.newPassword
                    });
                } else if (values?.actionType?.value === 'RESET') {
                    dataObj.changeType = { wipeExternalStorage: wipeExternalStorage };
                    if (wipeReasonMessage) dataObj.changeType.wipeReasonMessage = wipeReasonMessageValue;
                } else if (values?.actionType?.value === 'NOTES') {
                    if (!validation1.values.notes || validation1.values.notes?.trim() === '') {
                        toast.error(toastMessages.provideNotes);
                        return;
                    }
                    dataObj.changeType = validation1.values?.notes?.trim();
                } else if (values?.actionType?.value === 'NEWPOLICY') {
                    if (!values.policy?.value) {
                        toast.error(toastMessages.providePolicy);
                        return;
                    }
                    dataObj.changeType = values.policy?.value;
                } else if (values?.actionType?.value === 'NEWGROUP') {
                    if (!values.group.value) {
                        toast.error(toastMessages.provideGroup);
                        return;
                    }
                    dataObj.changeType = values.group?.value;
                } else {
                    delete dataObj.params;
                    delete dataObj.changeType;
                }
            }
            let formData = new FormData();
            formData.append('serials', dataObj.serial);
            formData.append('action', dataObj.action);
            formData.append('purpose', dataObj.purpose);
            formData.append('type', 'TV');
            if (dataObj.params) formData.append('params', dataObj.params);
            if (dataObj.changeType) formData.append('changeType', dataObj.changeType);
            api.create(url.BULK_ACTION, formData, true)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        setBulkActionModal(false);
                        bulkAction.resetForm();
                        let message = '';
                        switch (dataObj.action) {
                            case 'NOTES':
                                message = 'update notes on';
                                break;
                            case 'REBOOT':
                                message = 'reboot';
                                break;
                            case 'LOCK':
                                message = 'lock';
                                break;
                            case 'RESET_PASSWORD':
                                message = 'reset password on';
                                break;
                            case 'SCREAM':
                                message = 'scream on';
                                break;
                            case 'RESET':
                                message = 'factory reset';
                                break;
                            case 'NEWPOLICY':
                                message = 'change policy on';
                                break;
                            case 'NEWGROUP':
                                message = 'change group on';
                                break;
                            default:
                                break;
                        }
                        toast.success('Request raised to ' + message + ' selected devices.');
                        let rows = [...devices];
                        rows.forEach((row) => delete row.checked);
                        setSelectedDevices([]);
                        setCheckAll(false);
                        setDevices(rows);
                        setLoading(false);
                        setWipeReasonMessageValue('');
                        setWipeExternalStorage(false);
                        setWipeReasonMessage(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    });

    const handleIsExport = () => {
        if (Number(totalRecords) > 10000) {
            toast.error(toastMessages.filterDevicesMaxCount);
            setLoading(false);
        } else {
            setLoading(true);
            api.create(url.DEVICES_EXPORT, searchParams)
                .then((resp) => {
                    let blob = new Blob([resp], { type: 'application/octet-stream' });
                    var fileURL = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.download = 'Devices.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setLoading(false);
                })
                .catch((_err) => {
                    setLoading(false);
                });
        }
    };

    const handlePolicyFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'policy', 'policiesArr', 'Policies');
    };

    const handleDeviceStatusFilter = (e, item) => {
        dynamicCheckFunction(e, item, 'deviceStatus', 'deviceStatusArr', 'Device Status');
    };

    const dynamicCheckFunction = (e, item, key, field, filterType) => {
        let elastic = JSON.parse(JSON.stringify(elasticSearchObj));
        elastic[field].forEach((elas) => {
            if (elas.code === item.code) elas.checked = e.target.checked;
        });
        if (elastic[field].find((fieldObj) => fieldObj.checked))
            elastic.selectedFiltersKeys = uniqBy([...elastic.selectedFiltersKeys, { label: filterType, key: key, field: field }], 'label');
        else elastic.selectedFiltersKeys = elastic.selectedFiltersKeys.filter((keys) => keys.label !== filterType);
        let searchVals = elastic[field]?.filter((elasticField) => elasticField.checked)?.map((val) => val.code);
        let search = {
            ...searchParams.filter,
            [key]: searchVals?.length > 0 ? searchVals : undefined
        };
        setSearchParams({ ...searchParams, filter: Object.entries(JSON.parse(JSON.stringify(search)))?.length > 0 ? search : undefined });
        setElasticSearchObj(elastic);
    };

    const handleFilterSubmit = () => {
        setConfirmedElasticSearchObj(elasticSearchObj);
        setConfirmedSearchParams(searchParams);
        setShowMoreFilters(false);
        if (
            searchParams.filter?.deviceStatus?.length > 0 ||
            searchParams.filter?.group?.length > 0 ||
            searchParams.filter?.policy?.length > 0
        )
            setExportAllLabel('Export');
        else setExportAllLabel('Export All');
        getDevices({
            filter: Object.entries(JSON.parse(JSON.stringify(searchParams.filter || {})))?.length > 0 ? searchParams.filter : undefined,
            select: searchParams.select
        });
        setPageNumber(1);
    };

    const handleFilter = () => {
        setSelectedDevices([]);
        let timeDiff = new Date() - refreshTime;
        if (refreshTime && timeDiff < 5000) {
            let timeAfterOneMinute = refreshTime.getTime() + 5000;
            var date = new Date(timeAfterOneMinute);
            var hours = date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            var formattedTime = hours + ':' + minutes + ':' + seconds;
            toast.error(toastMessages.refreshTime);
            return;
        } else setRefreshTime(new Date());
        getDevices({ ...searchParams });
    };

    const handleRemoveFilter = (item) => {
        let elastic = JSON.parse(JSON.stringify(elasticSearchObj));
        let search = JSON.parse(JSON.stringify(searchParams));
        delete search.filter?.[item.key];
        elastic.selectedFiltersKeys = elastic.selectedFiltersKeys.filter((key) => key.key !== item.key);
        elastic[item.field].forEach((ele) => (ele.checked = false));
        if (item.key === 'group') {
            let groupsArr = JSON.parse(JSON.stringify(flatGroups));
            groupsArr.forEach((groupObj) => (groupObj.checked = false));
            let groupArr = handleGroups(groupsBackup);
            elastic.tagListArr = JSON.parse(JSON.stringify(groupArr));
            setFlatGroups(groupsArr);
            setCheckedData([]);
        }
        setElasticSearchObj(elastic);
        search.filter = Object.entries(search)?.length > 0 ? search.filter : undefined;
        setSearchParams(JSON.parse(JSON.stringify(search)));
    };

    const handleChildGroups = (groupsArr) => {
        groupsArr.forEach((group) => {
            group.checked = false;
            if (group.children?.length > 0) group.children = handleChildGroups(group.children);
        });
        return groupsArr;
    };

    const handleGroups = (groupsArr) => {
        groupsArr.forEach((group) => {
            group.checked = false;
            if (group.children?.length > 0) group.children = handleChildGroups(group.children);
        });
        return groupsArr;
    };

    const clearAllFilters = () => {
        let params = {
            select: searchParams.select,
            filter: searchParams.filter?.serial ? { serial: searchParams.filter?.serial } : undefined
        };
        setCheckedData([]);
        let groupsArr = JSON.parse(JSON.stringify(flatGroups));
        groupsArr.forEach((groupObj) => (groupObj.checked = false));
        setFlatGroups(groupsArr);
        let elastic = JSON.parse(JSON.stringify(elasticSearchObj));
        elastic.selectedFiltersKeys = [];
        elastic.policiesArr.forEach((pol) => (pol.checked = false));
        elastic.deviceStatusArr.forEach((status) => (status.checked = false));
        let groupArr = handleGroups(groupsBackup);
        elastic.tagListArr = JSON.parse(JSON.stringify(groupArr));
        setElasticSearchObj(elastic);
        setSearchParams(params);
    };

    const handleExpanded = (_expanded, targetNode) => {
        if (expandedData[0] === targetNode.value) setExpandedData([]);
        else setExpandedData([targetNode.value]);
    };

    const handleChecked = (groupsArr, checked) => {
        setCheckedData(checked);
        let flatGroupArr = JSON.parse(JSON.stringify(flatGroups));
        flatGroupArr.forEach((group) => (group.checked = checked?.includes(group.code)));
        setFlatGroups(flatGroupArr);
        setFilterCriterialOptions(uniqBy([...filterCriteriaOptions, { label: 'Groups', value: 'groupsArr' }], 'label'));
        let selectedFilterKeys = JSON.parse(JSON.stringify(elasticSearchObj?.selectedFiltersKeys));
        if (checked?.length === 0) {
            selectedFilterKeys = selectedFilterKeys?.filter((filterKey) => filterKey?.label !== 'Device Groups');
        } else {
            selectedFilterKeys = uniqBy(
                [...selectedFilterKeys, ...[{ label: 'Device Groups', key: 'group', field: 'tagListArr' }]],
                'label'
            );
        }
        setElasticSearchObj({
            ...elasticSearchObj,
            ...elasticSearchObj.tagListArr,
            tagListArr: groupsArr,
            selectedFiltersKeys: selectedFilterKeys
        });
        let groupFilter = { ...searchParams.filter, group: checked?.length > 0 ? checked : undefined };
        let params = {
            ...searchParams,
            select: searchParams.select,
            filter: Object.entries(groupFilter)?.length > 0 ? groupFilter : undefined
        };
        setSearchParams({ ...params });
    };

    const handleCheckboxChange = (e, ind, row, _cell) => {
        if (selectedDevices?.length === 100) {
            toast.error(toastMessages.maxDeviceCount);
            return;
        }
        let rows = [...devices];
        rows[ind].checked = e.target.checked;
        if (e.target.checked) setSelectedDevices([...selectedDevices, row.original]);
        else {
            let selDevices = [...selectedDevices];
            selDevices = selDevices.filter((device) => device.serial !== row.original.serial);
            setSelectedDevices(selDevices);
        }
        setDevices(rows);
        let unselectedRows = rows.filter((rowObj) => !rowObj.checked);
        if (unselectedRows?.length > 0) setCheckAll(false);
        else setCheckAll(true);
    };

    const handleCheckAll = (e) => {
        let fhDevices = devices?.slice(0, 100);
        let rows = [...fhDevices];
        rows.forEach((row) => (row.checked = e.target.checked));
        if (e.target.checked) {
            let selDevices = [...selectedDevices, ...rows];
            selDevices = uniqBy(selDevices, 'serial');
            setSelectedDevices(selDevices);
        } else setSelectedDevices([]);
        setDevices(devices);
        if (rows?.length === devices.length) setCheckAll(e.target.checked);
        else setCheckAll(false);
    };

    const handleVolume = (row) => {
        setType('volume');
        setActionText('Yes, Set it!');
        setSelectedDevice(row);
        setConfirmationModal(true);
    };

    const handleBulkAction = () => {
        setBulkActionModal(true);
    };

    const handleActionChange = (option, typeVal) => {
        if (typeVal === 'filterCriteria') {
            bulkAction.setValues({ ...bulkAction.values, filterCriteria: option });
            return;
        }
        validation.resetForm();
        validation1.resetForm();
        bulkAction.setValues({ ...bulkAction.values, actionType: option, policy: '', group: '' });
    };

    const setCheckedGroup = (groupsArr, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            else delete groupObj.checked;
            if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
        });
        return groupsArr;
    };

    const changeGroups = (groupsArr, _checked, group) => {
        groupsArr.forEach((groupObj) => {
            if (groupObj.code === group.code) groupObj.checked = !!groupObj.checked;
            else delete groupObj.checked;
            if (groupObj.children) groupObj.children = setCheckedGroup(groupObj.children, group);
        });
        bulkAction.setValues({ ...bulkAction.values, group: { ...group, value: group.code } });
        setGroups(groupsArr);
    };

    const handleGroupsBody = () => {
        return (
            <div className="overflow-auto mt-2">
                <div className="max-height-200 height-200">
                    <AnimatedTreeComponent groups={groups} flatGroups={flatGroups} changeGroups={changeGroups} />
                </div>
            </div>
        );
    };

    let handleModalObj = {
        resetLoader: resetLoader,
        tenantConfig: tenantConfig,
        selectedRow: selectedRow,
        validation: validation,
        handleSubmit: handleSubmit
    };
    const handleBulkModalBody = () => {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Purpose <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <Input
                            name={'purpose'}
                            type={'text'}
                            maxLength={100}
                            placeholder="Enter Purpose"
                            onChange={bulkAction.handleChange}
                            onBlur={bulkAction.handleBlur}
                            value={bulkAction.values['purpose'] || ''}
                            invalid={bulkAction.touched['purpose'] && bulkAction.errors['purpose'] ? true : false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={3} lg={3} className="mb-2 d-flex align-items-center">
                        <Label className="form-label d-flex align-items-center fw-medium">
                            Select Action <span className="red-color ps-1">*</span>
                        </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={4} lg={4} className="mb-2 w-75">
                        <Select
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            id="label"
                            name="value"
                            classNamePrefix={'custom-select w-100'}
                            options={actionTypes}
                            placeholder="Select Action"
                            onBlur={() => bulkAction.handleBlur({ target: { name: 'actionType' } })}
                            onChange={(selectedOption) => handleActionChange(selectedOption)}
                            value={bulkAction.values?.actionType || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    </Col>
                </Row>
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'RESET_PASSWORD' &&
                    handleModalBody(true, handleModalObj)}
                {bulkAction.values?.actionType && bulkAction.values?.actionType?.value === 'RESET' && handleDeleteBody(selectedRow)}
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'NEWPOLICY' &&
                    handlePGChangeBody(policies, 'Policy', 'nameCode', bulkAction)}
                {bulkAction.values?.actionType &&
                    bulkAction.values?.actionType?.value === 'NEWGROUP' &&
                    handleGroupsBody(groups, 'Group', 'nameCode')}
                {bulkAction.values?.actionType && bulkAction?.values?.actionType?.value === 'NOTES' && handleNotesModalBody(validation1)}
            </>
        );
    };

    const disableBulkAction = () => {
        return (
            !bulkAction.values.purpose ||
            bulkAction.values.purpose === '' ||
            !bulkAction.values?.actionType?.value ||
            (bulkAction.values?.actionType?.value === 'RESET_PASSWORD'
                ? validation.values.default_password === 'true'
                    ? tenantConfig?.DEVICE_DEFAULT_PASSWORD === ''
                    : validation.errors.newPassword || !togglePasswordChangeVal(validation.values.newPassword)
                : false) ||
            (bulkAction.values?.actionType?.value === 'NEWPOLICY' && !bulkAction?.values?.policy?.value) ||
            (bulkAction?.values?.actionType?.value === 'NEWGROUP' && !bulkAction?.values?.group?.code)
        );
    };

    const toggle = useCallback(() => {
        setElasticSearchObj(confirmedElasticSearchObj);
        setShowMoreFilters(!showMoreFilters);
    }, [showMoreFilters]);

    const handleOffcanvasBody = () => {
        return (
            <Filters
                showFilterMenu={showFilterMenu}
                devices={devices}
                flatGroups={flatGroups}
                elasticSearchObj={{ ...elasticSearchObj }}
                handlePolicyFilter={handlePolicyFilter}
                handleDeviceStatusFilter={handleDeviceStatusFilter}
                handleRemoveFilter={handleRemoveFilter}
                clearAllFilters={clearAllFilters}
                handleChecked={handleChecked}
                handleExpanded={handleExpanded}
                handleFilterMenuClick={handleFilterMenuClick}
                appStatus={appStatus}
                spinner={spinner}
                setSpinner={setSpinner}
                checked={checkedData}
                expanded={expandedData}
                tenantConfig={tenantConfig}
            />
        );
    };

    const handleFilterMenuClick = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const chromeActions = (endPoint, params, toastMsg) => {
        setLoading(true);
        setVolume(0);
        api.create(endPoint, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMsg);
                    getDevices({ ...searchParams });
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleActionConfirmation = () => {
        setConfirmationModal(false);
        if (type === 'volume') {
            chromeActions(
                url.DEVICE_ACTIONS + '/' + selectedDevice.original.serial + '/tv',
                { commandType: 'SET_VOLUME', payload: volume.toString() },
                toastMessages.requestToSetVolume
            );
        } else if (type === 'deprovision') {
            chromeActions(
                url.DEVICE_ACTIONS + '/' + selectedDevice?.original?.serial + '/action',
                { action: 'deprovision', deprovisionReason: 'retiring_device' },
                toastMessages.requestToDeprovision
            );
        } else if (type === 'wipeUser') {
            chromeActions(
                url.DEVICE_ACTIONS + '/' + selectedDevice?.original?.serial + '/tv',
                { commandType: 'WIPE_USERS', payload: null },
                toastMessages.wipeUser
            );
        } else if (type === 'screenshot') {
            chromeActions(
                url.DEVICE_ACTIONS + '/' + selectedDevice?.original?.serial + '/tv',
                {
                    commandType: 'TAKE_A_SCREENSHOT',
                    payload: null
                },
                toastMessages.screenshot
            );
        } else {
            tvDeviceActions({
                id: selectedDevice?.original?.serial,
                type: type?.toUpperCase(),
                toastMsg: toastMessage?.[type?.toUpperCase()],
                setLoading: setLoading,
                setShowModal: setShowModal,
                urlconf: urlconf
            });
        }
        setType('');
        setSelectedDevice('');
        setActionText('');
    };

    const handleIsExportAll = () => {
        setLoading(true);
        api.create(url.TV_EXPORT, { ...searchParams, select: { page: 1, size: totalRecords } })
            .then((resp) => {
                let blob = new Blob([resp], { type: 'application/octet-stream' });
                var fileURL = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = fileURL;
                a.download = 'TV Devices.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const setParentArr = (parent, obj) => {
        if (parent.parent) {
            obj.path.push(parent.parent.name);
            obj.breadcrumbItems.push(parent.parent);
            if (parent.parent.parent) {
                let parentObj = setParentArr(parent.parent, obj);
                obj.path = parentObj.path;
                obj.breadcrumbItems = parentObj.breadcrumbItems;
            }
        }
        let returnObj = { path: obj.path, breadcrumbItems: obj.breadcrumbItems };
        return returnObj;
    };

    const getAllWidgetsData = (widgetCount) => {
        return [
            {
                id: 1,
                label: 'Total',
                counter: widgetCount.totalDevicesCount,
                icon: TotalDevices,
                color: '155BB3',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 2,
                label: 'Enrolled',
                counter: widgetCount.deviceActiveCount,
                icon: ActiveDevices,
                color: '77C974',
                decimals: 0,
                suffix: '',
                prefix: ''
            },
            {
                id: 3,
                label: 'Not Enrolled',
                counter: widgetCount.deviceProvisioningCount,
                icon: ProvisioningDevices,
                color: 'E77B24',
                decimals: 0,
                suffix: '',
                prefix: ''
            }
        ];
    };

    const handleMap = (row) => {
        history.push(`/atvdevices/viewmap/${row.original.serial}`);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Android TV Devices'} history={history} />
                    <div className="mt-4">
                        <Row>
                            {/* <div className={`${!showFilterMenu ? 'widthPer-5' : 'widthPer-20'}`}>
                        <Filters
                            showFilterMenu={showFilterMenu}
                            devices={devices}
                            flatGroups={flatGroups}
                            elasticSearchObj={{ ...elasticSearchObj }}
                            handlePolicyFilter={handlePolicyFilter}
                            handleDeviceStatusFilter={handleDeviceStatusFilter}
                            handleRemoveFilter={handleRemoveFilter}
                            clearAllFilters={clearAllFilters}
                            handleChecked={handleChecked}
                            handleExpanded={handleExpanded}
                            handleFilterMenuClick={handleFilterMenuClick}
                            appStatus={appStatus}
                            spinner={spinner}
                            setSpinner={setSpinner}
                            checked={checkedData}
                            expanded={expandedData}
                            tenantConfig={tenantConfig}
                        />
                    </div> */}

                            {/* <div className={`${!showFilterMenu ? 'widthPer-95' : 'widthPer-80'}`}> */}
                            <Row>
                                <Widgets allWidgetsData={getAllWidgetsData(WidgetCount)} />
                            </Row>
                            <Row className="m-0">
                                <TableContainer
                                    loading={loading}
                                    // refresh={true}
                                    refreshTime={refreshTime}
                                    searchFilter2={true}
                                    changeID={true}
                                    tabName="androidTV"
                                    exportFile5l={true}
                                    doNotJustify={true}
                                    exportAllLabel={exportAllLabel}
                                    moreFilters={true}
                                    handleMoreFilters={() => setShowMoreFilters(true)}
                                    handleIsExportAll={handleIsExportAll}
                                    filter1CharLimit={200}
                                    handleMap={handleMap}
                                    dateClass="width-175"
                                    showTooltip={true}
                                    datePlaceHolder={'Enrollment Date'}
                                    checkAll={checkAll}
                                    disableBulkAction={selectedDevices?.length === 0}
                                    handleBulkAction={handleBulkAction}
                                    /*
                                     * dateRangeFilter={true}
                                     * bulkAction={true}
                                     * pageOptionsFilter={true}
                                     * tableHeader={`Devices (${totalRecords})`}
                                     */
                                    data={devices}
                                    isGlobalFilter={true}
                                    handleFilter={handleFilter}
                                    handleVolume={handleVolume}
                                    columns={devicesTableSchema}
                                    totalRecords={totalRecords}
                                    pageNum={pageNumber}
                                    uploadTooltip="Upload Devices"
                                    toastMessage="Enter minimum 3 characters to search."
                                    reader={priviliges.TVDEVICE_READER}
                                    editor={priviliges.TVDEVICE_EDITOR}
                                    customPageSize={10}
                                    handleUpload={handleUpload}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleCheckAll={handleCheckAll}
                                    onPageChange={onPageChange}
                                    handleDeviceLock={handleDeviceLock}
                                    handleNotes={handleNotes}
                                    handleDeviceReboot={handleDeviceReboot}
                                    handleResetPassword={handleResetPassword}
                                    handleFactoryReset={handleFactoryReset}
                                    handleGlobalSearch={handleGlobalSearch}
                                    handleDateSearch={handleDateSearch}
                                    acceptFiles=".csv"
                                    searchPlaceHolder="Device ID"
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="table table-nowrap table-border table-centered align-middle"
                                    theadClass="bg-light text-muted"
                                />
                            </Row>
                            {/* </div> */}
                        </Row>
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={handleDeleteConfirmation}
                            onCloseClick={() => {
                                setDeleteModal(false);
                                setSelectedDevice('');
                            }}
                            deleteMessage={`Are you sure you want to factory reset this device ${selectedRow?.original?.serialNumber} ?`}
                            confirmText={'Yes, Reset it!'}
                            customBody={true}
                            disabled={wipeReasonMessage && (!wipeReasonMessageValue || wipeReasonMessageValue === '')}
                            deleteBody={handleDeleteBody(selectedRow)}
                        />
                        <DeleteModal
                            hideIcon={true}
                            show={confirmationModal}
                            hideDeleteIcon={true}
                            hideDeleteMessage={true}
                            message={messageFunc(type, selectedDevice, volume, setVolume)}
                            showHeader={type === 'volume'}
                            modalheader={'Set Volume'}
                            confirmation={type === 'volume' || type === 'screenshot'}
                            confirmText={actionText}
                            onDeleteClick={handleActionConfirmation}
                            onCloseClick={() => setConfirmationModal(false)}
                        />
                        <CommonModal
                            size={'md'}
                            show={showModal}
                            disabled={false}
                            hideSaveButton={true}
                            onCloseClick={() => {
                                validation.resetForm();
                                setShowModal(false);
                            }}
                            handleClick={() => setShowModal(false)}
                            handleModalBody={() => handleModalBody(false, handleModalObj)}
                        />
                        <OffcanvasModal
                            direction="end"
                            toggle={toggle}
                            open={showMoreFilters}
                            handleCloseClick={toggle}
                            OffcanvasModalID="filterModal"
                            handleOffcanvasBody={handleOffcanvasBody}
                            modalClassName="w-25"
                            saveText="Apply Filter"
                            offcanvasHeader="Filters"
                            handleSaveClick={handleFilterSubmit}
                        />
                        <CommonModal
                            size={'md'}
                            cancelText={'Cancel'}
                            modalheader={`Notes: ${selectedRow?.original?.serial}`}
                            show={showNotesModal}
                            onCloseClick={() => {
                                validation1.resetForm();
                                setShowNotesModal(false);
                            }}
                            handleClick={() => {
                                validation1.handleSubmit();
                            }}
                            handleModalBody={() => handleNotesModalBody(validation1)}
                            disabled={!validation1.values.notes}
                            saveText={notes && notes !== '' ? 'Update' : 'Save'}
                        />
                        <CommonModal
                            size={'md'}
                            cancelText={'Cancel'}
                            modalheader={`Devices (${selectedDevices?.length})`}
                            show={bulkActionModal}
                            disabled={disableBulkAction()}
                            onCloseClick={() => {
                                bulkAction.resetForm();
                                validation.resetForm();
                                setWipeExternalStorage(false);
                                setWipeReasonMessage(false);
                                setWipeReasonMessageValue('');
                                setBulkActionModal(false);
                            }}
                            handleClick={() => bulkAction.handleSubmit()}
                            handleModalBody={() => handleBulkModalBody()}
                        />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AndroidTVDevices;
