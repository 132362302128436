import toastMessages from '../../../common/messages/toastMessages';

/* eslint-disable max-depth */
export const devicesSchema = [
    {
        Header: 'checkbox',
        customHeader: true,
        headerType: 'checkbox',
        customCell: true,
        fieldType: 'checkbox',
        accessor: 'checked',
        thClass: 'width-50 max-width-50 min-width-50 d-flex align-items-center',
        className: 'width-50 max-width-50',
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    {
        Header: 'Last Contact',
        accessor: 'customField',
        customCell: true,
        fieldType: 'statusDots',
        sortable: false,
        accessor1: 'ONLINE_STATUS',
        accessor2: 'REGISTRATION_STATUS',
        thClass: ' width-100 max-width-100',
        className: 'width-100 max-width-100',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        onlineStatusTooltip: 'onlineStatusTooltip',
        enrollmentStatusTooltip: 'enrollmentStatusTooltip'
    },
    {
        Header: 'Serial',
        accessor: 'serial',
        sortable: true,
        customCell: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        fieldType: 'fieldClickable',
        viewRoute: '/aedevices/view',
        fieldKey: 'serial',
        view: true,
        copy: true,
        copyToastMsg: toastMessages.serialCopied
    },
    {
        Header: 'Sequence',
        accessor: 'sequence',
        sortable: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
    },
    {
        Header: 'IMEI',
        accessor: 'IMEI',
        sortable: true,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'IMEI',
        copyToastMsg: toastMessages.imeiCopied
    },
    {
        Header: 'Android Version',
        accessor: 'androidVersion',
        sortable: true
    },
    {
        Header: 'Manufacturer',
        accessor: 'manufacturer',
        sortable: true
    },
    {
        Header: 'Policy View',
        accessor: 'policyAppliedName',
        customCell: true,
        iconField: 'noAction',
        startField: 'policyAppliedName',
        endField: 'policyConfiguredName',
        fieldType: 'conditionalClickable',
        sortable: true
    },
    {
        Header: 'Policy Version',
        accessor: 'policyVersionApplied',
        customCell: true,
        iconField: 'noActionOnVersion',
        hideLink: true,
        startField: 'policyVersionApplied',
        endField: 'policyVersionConfigured',
        fieldType: 'conditionalClickable',
        sortable: true
    },
    {
        Header: 'Group',
        accessor: 'group',
        sortable: true
    },
    {
        Header: 'Device Status',
        accessor: 'deviceStatusApplied',
        sortable: true,
        customCell: true,
        fieldType: 'badge'
    },
    {
        Header: 'Battery',
        accessor: 'batteryPercentage',
        sortable: true,
        customCell: true,
        fieldType: 'iconLabel',
        tooltip: true,
        tooltipKey: 'batteryReportedTooltip',
        dataKey: 'batteryPercentage'
    },
    /*
     * {
     *     Header: 'Compliance Status',
     *     accessor: 'complianceStatus',
     *     sortable: false
     * },
     * {
     *     Header: 'Security Status',
     *     accessor: 'securityStatus',
     *     sortable: false
     * },
     * {
     *     Header: 'SIM Status',
     *     accessor: 'simStatus',
     *     sortable: false
     * },
     *
     * {
     *   Header: 'Enrollment Date',
     *   accessor: 'enrollmentDate',
     *   sortable: false
     * },
     *  {
     *    Header: 'Last Seen Date',
     *    accessor: 'lastSeenDate',
     *    sortable: false
     *  },
     */
    {
        Header: 'Username',
        accessor: 'username',
        sortable: true
    },
    {
        Header: 'Custom Field',
        accessor: 'custom1',
        sortable: true
    },
    {
        Header: 'Violations',
        accessor: 'violations',
        customCell: true,
        fieldType: 'violations'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'disableFilters',
        sortable: false,
        lock: true,
        scream: true,
        lostMode: true,
        map: true,
        resetpassword: true,
        reboot: true,
        factoryreset: true,
        notes: true,
        retiree: true
    }
];

export const moveGroupsSchema = [
    /*
     * {
     *     Header: 'radio',
     *     customHeader: true,
     *     headerType: 'radio',
     *     customCell: true,
     *     fieldType: 'radio',
     *     accessor: 'checked',
     *     thClass: 'width-50 max-width-50 min-width-50',
     *     className: 'width-50 max-width-50',
     *     width: 50,
     *     maxWidth: 50,
     *     minWidth: 50
     * },
     */
    { Header: 'Group Name', accessor: 'name', value: 'name', sortable: true, customCell: true, fieldType: 'avatarText', view: true },
    { Header: 'Sub Groups', accessor: 'childsCount', sortable: true }
];

export const nonComplianceSchema = [
    {
        Header: 'Setting Name',
        accessor: 'settingName',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Non Compliance Reason',
        accessor: 'reason',
        sortable: true,
        width: 100,
        maxWidth: 100,
        minWidth: 100
    },
    {
        Header: 'Package Name',
        accessor: 'packageName',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'packageName',
        copyToastMsg: 'Package name copied successfully.'
    },
    {
        Header: 'Current Value',
        accessor: 'currentValue',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Installation Failure Reason',
        accessor: 'installationFailureReason',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    },
    {
        Header: 'Specific Non Compliance Reason',
        accessor: 'specificNonComplianceReason',
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        sortable: true
    }
];

export const threatsSchema = [
    { Header: 'S.No.', accessor: 'Sl-No', sortable: false, customCell: true, fieldType: 'Sl-No', width: 50, maxWidth: 50, minWidth: 50 },
    { Header: 'Threat Name', accessor: 'name', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    {
        Header: 'Severity',
        accessor: 'severity',
        sortable: false,
        window: 100,
        maxWidth: 100,
        minWidth: 100,
        customCell: true,
        fieldType: 'coloredText'
    }
];

export const applicationSchema = [
    {
        Header: 'Name',
        accessor: 'displayName',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    },
    {
        Header: 'Package',
        accessor: 'packageName',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true,
        customCell: true,
        fieldType: 'copy',
        fieldKey: 'packageName',
        copyToastMsg: 'Package name copied successfully.'
    },
    {
        Header: 'Version',
        accessor: 'versionName',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    },
    {
        Header: 'Source',
        accessor: 'applicationSource',
        width: 250,
        maxWidth: 250,
        minWidth: 250,
        sortable: true
    },
    {
        Header: 'Status',
        accessor: 'state',
        customCell: true,
        fieldType: 'badge',
        sortable: true
    }
    // { Header: 'Actions', accessor: 'html', customCell: true, fieldType: 'actions', clearCache: true, clearData: true }
];

export const antivirusHistorySchema = [
    { Header: 'Files', accessor: 'filesScanned', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Time', accessor: 'scanDuration', sortable: false, width: 150, maxWidth: 150, minWidth: 150 },
    { Header: 'Threats', accessor: 'numberOfThreats', sortable: true, width: 50, minWidth: 50, maxWidth: 50 },
    { Header: 'PerformedBy', accessor: 'scannedBy', sortable: true, width: 200, maxWidth: 200, minWidth: 200 },
    { Header: 'Status', accessor: 'status', sortable: true, customCell: true, fieldType: 'badge', tooltipAccessor: 'infoTooltip' },
    { Header: 'Scanned Date', accessor: 'scannedDate', sortable: false, width: 200, minWidth: 200, maxWidth: 200 }
];

export const deviceGroupsSchema = [
    {
        Header: 'Group Name',
        accessor: 'name',
        value: 'name',
        sortable: true,
        customCell: true,
        fieldType: 'avatarText',
        view: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200
    },
    { Header: 'Group Code', accessor: 'code', sortable: true, width: 100, maxWidth: 100, minWidth: 100 },
    { Header: 'Sub Groups', accessor: 'childsCount', sortable: true, maxWidth: 50, minWidth: 50, width: 50 },
    { Header: 'Modified By', accessor: 'modifiedby', width: 200, maxWidth: 200, minWidth: 200, sortable: true },
    { Header: 'Modified Date', accessor: 'lastModifiedDate', sortable: false, width: 200, maxWidth: 200, minWidth: 200 },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        hideActions: true,
        actionsHideKey: 'hideActions',
        sortable: false,
        rename: true,
        treeView: true,
        // clone: true,
        downloadGroup: true,
        // deleteOnCount: true,
        move: true,
        auditlog: true,
        moveTooltip: 'Move'
    }
];
