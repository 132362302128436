import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, convertTextCase, getDate } from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import acer from '../../../assets/images/acerodm.png';
import lava from '../../../assets/images/lavaodm.png';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { tableSchema } from './Schema';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const ODMFeatures = () => {
    document.title = 'ODM Features';
    const api = new APIClient();
    const config = useEnv();

    const [loading, setLoading] = useState(false);
    const [policyModal, setPolicyModal] = useState(false);
    const [featureId, setFeatureId] = useState('');
    const [searchParams, setSearchParams] = useState({ page: 1, size: 100, configType: 'ODMFEATURES' });
    const [allODMFeatures, setAllODMFeatures] = useState([]);
    const [selectedRow, setSelectedRow] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const history = useHistory();
    const [schema, setSchema] = useState([...tableSchema]);

    const featuresArray = [
        {
            img: lava,
            id: 'lava',
            desc: 'Configure ODM features for Lava’s selective devices'
        },
        {
            img: acer,
            id: 'acer',
            desc: 'Configure ODM features for Acer’s selective devices'
        }
    ];

    useEffect(() => {
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.CONFIGURATION_EDITOR);
        let schemaArr = [...tableSchema];
        if (findPriv) schemaArr = schemaArr.filter((obj) => obj.Header !== 'Actions');
        setSchema(schemaArr);
        getConfigs(searchParams);
    }, []);

    const getConfigs = (params) => {
        // params = { ...params, type: featureId };
        setLoading(true);
        api.get(url.CONFIG, params)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                resp.data.forEach((obj) => {
                    obj.doNotDelete = obj.policies?.length > 0;
                    obj.policies = obj.policies?.length > 0 ? obj.policies?.join(',') : '—';
                    obj.type = convertTextCase(obj.config?.type || '', true);
                    obj.modifiedBy = obj.lastmodifiedBy ? obj.lastmodifiedBy : 'Tectoro';
                    obj.modifiedDate = obj.lastmodifiedTime ? getDate(obj.lastmodifiedTime) : '—';
                });
                setAllODMFeatures(resp.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleModalBody = () => {
        return (
            <div>
                <Row className="d-flex align-items-center justify-content-evenly">
                    {featuresArray.map((ele, index) => {
                        return (
                            <Card
                                key={index}
                                className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-220 height-220 cursor-pointer
									${ele.id === featureId ? 'border-2 border-primary' : ''}`}
                                onClick={() => setFeatureId(ele.id)}
                            >
                                <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                    {ele.id === featureId && (
                                        <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                    )}
                                    <div>
                                        <img src={ele.img} alt="" height={60} width={100} />
                                    </div>
                                    <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                </CardBody>
                            </Card>
                        );
                    })}
                </Row>
            </div>
        );
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to delete ODM Feature?</div>
                <div>
                    ODM Feature Name : <span className="fw-medium">{selectedRow?.original?.name}</span>
                </div>
            </div>
        );
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setLoading(true);
        api.delete(url.CONFIG + '/' + selectedRow?.original?.id)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setSelectedRow('');
                    toast.success(toastMessages.ODMFeaturesDeleted);
                    getConfigs({ ...searchParams });
                }
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'ODM Features'} history={history} homeLink="Dashboard" />
                    <TableContainer
                        columns={schema}
                        data={allODMFeatures}
                        addButton={true}
                        addButtonText="Add New"
                        isGlobalFilter={true}
                        tableHeader={'ODM Features'}
                        searchFilter2={true}
                        handleAddNew={() => setPolicyModal(true)}
                        handleEdit={(row) => {
                            history.push(`/odmfeatures/${row?.original?.type?.toLowerCase()}/edit/${row?.original?.id}`);
                        }}
                        handleClickView={(row) =>
                            history.push(`/odmfeatures/${row?.original?.type?.toLowerCase()}/view/${row?.original?.id}`)
                        }
                        handleDelete={(row) => {
                            setSelectedRow(row);
                            setDeleteModal(true);
                        }}
                        editor={priviliges.CONFIGURATION_EDITOR}
                        reader={priviliges.CONFIGURATION_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <CommonModal
                        size={'md'}
                        show={policyModal}
                        modalheader={'ODM Platform'}
                        onCloseClick={() => {
                            setFeatureId('');
                            setPolicyModal(false);
                        }}
                        saveText={'Proceed'}
                        cancelText={'Cancel'}
                        disabled={!featureId}
                        handleClick={() => {
                            history.push(`/odmfeatures/${featureId}/add`);
                            setPolicyModal(false);
                        }}
                        handleModalBody={handleModalBody}
                    />
                    <DeleteModal
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => setDeleteModal(false)}
                        // deleteMessage={`Are you sure you want to remove this device group code -${selectedRow?.name} ?`}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ODMFeatures;
