/* eslint-disable max-lines */
/* eslint-disable quotes */
/* eslint-disable multiline-comment-style */
import passcode from '../../../assets/images/windowsCardImages/passcode.svg';
import restrictions from '../../../assets/images/windowsCardImages/restrictions.svg';
import application from '../../../assets/images/iosCardImages/application.svg';
import delivery from '../../../assets/images/windowsCardImages/delivery.svg';
import defender from '../../../assets/images/windowsCardImages/defender.png';
import applicationguard from '../../../assets/images/windowsCardImages/applicationguard.svg';
import deviceguard from '../../../assets/images/windowsCardImages/deviceguard.svg';
import healthMonitoring from '../../../assets/images/windowsCardImages/deviceHealth.svg';
import wifi from '../../../assets/images/windowsCardImages/wifi.svg';
import email from '../../../assets/images/windowsCardImages/email.svg';
import vpn from '../../../assets/images/windowsCardImages/vpn.svg';
import personalization from '../../../assets/images/windowsCardImages/personalization.svg';
import power from '../../../assets/images/windowsCardImages/power.svg';

export const windowsPolicySchema = [
    {
        label: 'Passcode',
        helpText: 'Use this section to manage passcode complexity to mitigate the risk of unauthorized access',
        image: passcode,
        fields: [
            {
                label: 'Device Lock',
                value: 'DevicePasswordEnabled',
                helpText: 'Specifies if device lock is enabled',
                inputType: 'checkbox',
                parentId: 'DeviceLock',
                myId: 'DevicePasswordEnabled',
                defaultValue: 1,
                checkedValue: 0,
                options: [
                    { label: 'Enabled', value: 0 },
                    { label: 'Disabled.', value: 1 }
                ]
            },
            {
                label: 'Minimum Passcode Length',
                value: 'MinDevicePasswordLength',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the minimum characters required in a PIN or password',
                parentId: 'DeviceLock',
                myId: 'MinDevicePasswordLength',
                defaultValue: 4,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                options: [
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                    { label: '11', value: 11 },
                    { label: '12', value: 12 },
                    { label: '13', value: 13 },
                    { label: '14', value: 14 },
                    { label: '15', value: 15 },
                    { label: '16', value: 16 }
                ]
            },
            {
                label: 'Minimum Passcode Age',
                value: 'MinimumPasswordAge',
                helpText:
                    'Specifies the minimum number of days a password must be used before it can be changed, ranging from 0 to 998 days',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 998,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MinimumPasswordAge',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-998 day(s)'
            },
            {
                label: 'Maximum Passcode Age',
                value: 'MaximumPasswordAge',
                helpText:
                    'Specifies the maximum number of days a password can be used before it must be changed, ranging from 0 to 999 days',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MaximumPasswordAge',
                defaultValue: 42,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '1-999 day(s)'
            },
            // {
            //     label: 'Maximum idle time allowed before Auto Lock',
            //     value: 'MaxInactivityTimeDeviceLock',
            //     helpText: 'Specifies the maximum idle time (in minutes) before the device locks and requires a PIN or password',
            //     inputType: 'policyInput',
            //     type: 'number',
            //     minValue: 0,
            //     maxValue: 999,
            //     maxLength: 3,
            //     parentId: 'DeviceLock',
            //     myId: 'MaxInactivityTimeDeviceLock',
            //     defaultValue: 0,
            //     enableOn: 'DevicePasswordEnabled',
            //     enableOnVal: [0],
            //     placeholder: '0-999 minute(s)'
            // },
            {
                label: 'Number of passcodes to be maintained in history',
                value: 'DevicePasswordHistory',
                helpText: 'Specifies the number of previous passwords that cannot be reused',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 50,
                maxLength: 2,
                parentId: 'DeviceLock',
                myId: 'DevicePasswordHistory',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-50'
            },
            {
                label: 'Wipe device after specified number of failed attempts',
                value: 'MaxDevicePasswordFailedAttempts',
                helpText: 'Specifies the number of failed authentication attempts before the device is wiped',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 999,
                maxLength: 3,
                parentId: 'DeviceLock',
                myId: 'MaxDevicePasswordFailedAttempts',
                defaultValue: 0,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                placeholder: '0-999'
            },
            {
                label: 'Simple Device Password',
                value: 'AllowSimpleDevicePassword',
                helpText: 'Specifies whether simple PINs (like 1111 or 1234) & picture passwords are allowed',
                inputType: 'checkbox',
                parentId: 'DeviceLock',
                myId: 'AllowSimpleDevicePassword',
                defaultValue: 1,
                checkedValue: 1,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Alpha Numeric PIN',
                value: 'AlphanumericDevicePasswordRequired',
                helpText: 'Specifies the type of PIN or password required',
                inputType: 'dropdown',
                parentId: 'DeviceLock',
                myId: 'AlphanumericDevicePasswordRequired',
                defaultValue: 2,
                enableOn: 'DevicePasswordEnabled',
                enableOnVal: [0],
                row: true,
                options: [
                    { label: 'Password or Alphanumeric PIN required', value: 0 },
                    { label: 'Password or Numeric PIN required', value: 1 },
                    { label: 'Password, Numeric PIN, or Alphanumeric PIN required', value: 2 }
                ]
            }
        ]
    },
    {
        label: 'Restrictions',
        helpText: 'Use this section to impose restrictions on managed devices to prevent unauthorised access and misuse',
        image: restrictions,
        fields: [
            {
                label: 'Restrictions',
                inputType: 'restrictions',
                fields: [
                    {
                        label: 'General',
                        fields: [
                            {
                                label: 'Capture Screen',
                                inputType: 'checkbox',
                                value: 'AllowScreenCapture',
                                helpText: 'Specifies whether screen capture is allowed or not',
                                parentId: 'Experience',
                                myId: 'AllowScreenCapture',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Manual Unenrollment',
                                inputType: 'checkbox',
                                value: 'AllowManualMDMUnenrollment',
                                helpText:
                                    'Specifies whether user is allowed or restricted from deleting the workplace account via the workplace control panel',
                                parentId: 'Experience',
                                myId: 'AllowManualMDMUnenrollment',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Device Discovery',
                                inputType: 'checkbox',
                                value: 'AllowDeviceDiscovery',
                                helpText: 'Specifies if device discovery is enabled or disabled',
                                parentId: 'Experience',
                                myId: 'AllowDeviceDiscovery',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disabled.', value: 0 },
                                    { label: 'Enabled.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Find My Device',
                                inputType: 'checkbox',
                                value: 'AllowFindMyDevice',
                                helpText:
                                    'Specifies whether find my device is enabled, allowing the device & its location to be registered in the cloud for tracking',
                                parentId: 'Experience',
                                myId: 'AllowFindMyDevice',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Clipboard History',
                                inputType: 'checkbox',
                                value: 'AllowClipboardHistory',
                                helpText: 'Specifies whether the history of clipboard items can be stored in memory',
                                parentId: 'Experience',
                                myId: 'AllowClipboardHistory',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Direct Memory Access',
                                inputType: 'checkbox',
                                value: 'AllowDirectMemoryAccess',
                                helpText:
                                    'Specifies whether DMA is blocked for hot pluggable PCI downstream ports until a user, logs into Windows, enforced only when BitLocker device encryption is enabled',
                                parentId: 'DataProtection',
                                myId: 'AllowDirectMemoryAccess',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow End Task',
                                inputType: 'checkbox',
                                value: 'AllowEndTask',
                                helpText: 'Specifies whether non-administrators can use task manager to end tasks',
                                parentId: 'TaskManager',
                                myId: 'AllowEndTask',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'Disabled. EndTask functionality is blocked in TaskManager.', value: 0 },
                                    { label: 'Enabled. Users can perform EndTask in TaskManager', value: 1 }
                                ]
                            },
                            {
                                label: 'Camera',
                                inputType: 'checkbox',
                                value: 'AllowCamera',
                                helpText: 'Allows or disallows camera',
                                parentId: 'Camera',
                                myId: 'AllowCamera',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'USB Connection',
                                inputType: 'checkbox',
                                value: 'AllowStorageCard',
                                helpText: 'Specifies the level of diagnostic & usage telemetry data sent by the device',
                                parentId: 'System',
                                myId: 'AllowStorageCard',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'SD card use is not allowed and USB drives are disabled.', value: 0 },
                                    { label: 'Allow a Storage Card.', value: 1 }
                                ]
                            },
                            {
                                label: 'Modify device date & time',
                                inputType: 'checkbox',
                                value: 'AllowDateTime',
                                helpText: 'Specifies the user’s ability to change the date and time of the device',
                                parentId: 'Settings',
                                myId: 'AllowDateTime',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Network',
                        fields: [
                            {
                                label: 'VPN',
                                inputType: 'checkbox',
                                value: 'AllowVPN',
                                helpText: 'Specifies if user can change VPN settings',
                                parentId: 'Settings',
                                myId: 'AllowVPN',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'VPN usage while using Cellular Data',
                                inputType: 'checkbox',
                                value: 'AllowVPNOverCellular',
                                helpText: 'Specifies what type of underlying connections VPN can use',
                                parentId: 'Connectivity',
                                myId: 'AllowVPNOverCellular',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'VPN is not allowed over cellular.', value: 0 },
                                    { label: 'VPN can use any connection, including cellular.', value: 1 }
                                ]
                            },
                            {
                                label: 'VPN roaming while using Cellular Data',
                                inputType: 'checkbox',
                                value: 'AllowVPNRoamingOverCellular',
                                helpText:
                                    'Specifies whether to prevent the device from connecting to VPN when roaming over cellular networks',
                                parentId: 'Connectivity',
                                myId: 'AllowVPNRoamingOverCellular',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Internet Sharing',
                                inputType: 'checkbox',
                                value: 'AllowInternetSharing',
                                helpText: 'Allow or disallow internet sharing',
                                parentId: 'Wifi',
                                myId: 'AllowInternetSharing',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Wi-Fi',
                                inputType: 'checkbox',
                                value: 'AllowWiFi',
                                helpText: 'Allows or disallows wifi connection',
                                parentId: 'Wifi',
                                myId: 'AllowWiFi',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Wi-Fi Configuration',
                                inputType: 'checkbox',
                                value: 'AllowManualWiFiConfiguration',
                                helpText: 'Controls user’s access to add other Wi-Fi networks',
                                parentId: 'Wifi',
                                myId: 'AllowManualWiFiConfiguration',
                                defaultValue: 1,
                                checkedValue: 1,
                                row: true,
                                options: [
                                    { label: 'No Wi-Fi connection outside of MDM provisioned network is allowed.', value: 0 },
                                    { label: 'Adding new network SSIDs beyond the already MDM provisioned ones is allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Auto Connect To Wi-Fi Hotspots',
                                inputType: 'checkbox',
                                value: 'AllowAutoConnectToWiFiSenseHotspots',
                                helpText: 'Allows or disallows device to automatically connect to Wi-fi hotspots',
                                parentId: 'Wifi',
                                myId: 'AllowAutoConnectToWiFiSenseHotspots',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Allow Connected Devices',
                                inputType: 'checkbox',
                                value: 'AllowConnectedDevices',
                                helpText:
                                    'Note This policy requires reboot to take effect.  Allows IT Admins the ability to disable the Connected Devices Platform (CDP) component. CDP enables discovery and connection to other devices (either proximally with BT/LAN or through the cloud) to support remote app launching, remote messaging, remote app sessions, and other cross-device experiences.',
                                parentId: 'Connectivity',
                                myId: 'AllowConnectedDevices',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disable (CDP service not available).', value: 0 },
                                    { label: 'Allow (CDP service available)', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Bluetooth',
                        fields: [
                            {
                                label: 'Bluetooth',
                                inputType: 'checkbox',
                                value: 'AllowBluetooth',
                                helpText: 'Allows or restricts Bluetooth Access',
                                parentId: 'Connectivity',
                                myId: 'AllowBluetooth',
                                defaultValue: 2,
                                checkedValue: 2,
                                options: [
                                    { label: 'Disallow.', value: 0 },
                                    { label: 'Allow.', value: 2 }
                                ]
                            },
                            {
                                label: 'Bluetooth Discovery',
                                inputType: 'checkbox',
                                value: 'AllowDiscoverableMode',
                                helpText: 'Allows or disallows device to be discovered by other devices',
                                parentId: 'Bluetooth',
                                myId: 'AllowDiscoverableMode',
                                defaultValue: 1,
                                checkedValue: 1,
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Bluetooth Services Advertising',
                                inputType: 'checkbox',
                                value: 'AllowAdvertising',
                                helpText: 'Allows device to send bluetooth low energy advertisements',
                                parentId: 'Bluetooth',
                                myId: 'AllowAdvertising',
                                defaultValue: 1,
                                checkedValue: 1,
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Bluetooth pre-pairing',
                                inputType: 'checkbox',
                                value: 'AllowPrepairing',
                                helpText:
                                    'Specifies whether certain Bluetooth devices that come with the host device can automatically pair with it',
                                parentId: 'Bluetooth',
                                myId: 'AllowPrepairing',
                                enableOn: 'AllowBluetooth',
                                enableOnVal: [2],
                                notEnabledValue: 0,
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Security',
                        fields: [
                            {
                                label: 'Location',
                                inputType: 'dropdown',
                                value: 'AllowLocation',
                                helpText: 'Specifies whether to allow app access to the Location',
                                parentId: 'System',
                                myId: 'AllowLocation',
                                defaultValue: 1,
                                row: true,
                                options: [
                                    {
                                        label: 'Force Location Off. All Location Privacy settings are toggled off and grayed out.',
                                        value: 0
                                    },
                                    {
                                        label: 'Location service is allowed. The user has control and can change Location Privacy settings on or off.',
                                        value: 1
                                    },
                                    { label: 'Force Location On. All Location Privacy settings are toggled on and grayed out.', value: 2 }
                                ]
                            },
                            {
                                label: 'Sync My Settings',
                                inputType: 'checkbox',
                                value: 'AllowSyncMySettings',
                                helpText: 'Specifies whether to allow or disallow all windows sync settings on the device',
                                parentId: 'Experience',
                                myId: 'AllowSyncMySettings',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Sync settings are not allowed.', value: 0 },
                                    { label: 'Sync settings allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Developer Unlock',
                                inputType: 'dropdown',
                                value: 'AllowDeveloperUnlock',
                                helpText: 'Specifies whether developer unlock is allowed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowDeveloperUnlock',
                                defaultValue: 65535,
                                row: true,
                                options: [
                                    { label: 'Explicit deny.', value: 0 },
                                    { label: 'Explicit allow unlock.', value: 1 },
                                    { label: 'Not configured.', value: 65535 }
                                ]
                            },
                            {
                                label: 'Microsoft Account Connection',
                                inputType: 'checkbox',
                                value: 'AllowMicrosoftAccountConnection',
                                helpText:
                                    'Specifies whether the user is allowed to use MSA account for non-email related connection authentication and services',
                                parentId: 'Accounts',
                                myId: 'AllowMicrosoftAccountConnection',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'User To Reset Device',
                                inputType: 'checkbox',
                                value: 'AllowUserToResetPhone',
                                helpText: 'Specifies whether user can reset the device using hard key and the control panel combination',
                                parentId: 'System',
                                myId: 'AllowUserToResetPhone',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed to reset to factory default settings.', value: 1 }
                                ]
                            },
                            {
                                label: 'Add Provisioning Package',
                                inputType: 'checkbox',
                                value: 'AllowAddProvisioningPackage',
                                helpText: 'Specifies whether to allow the runtime configuration agent to install provisioning packages.',
                                parentId: 'Security',
                                myId: 'AllowAddProvisioningPackage',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Fips Algorithm Policy',
                                inputType: 'checkbox',
                                value: 'AllowFipsAlgorithmPolicy',
                                helpText: 'Specifies whether to allow the runtime configuration agent to install provisioning packages.',
                                parentId: 'Security',
                                myId: 'AllowFipsAlgorithmPolicy',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Adding Non Microsoft Accounts Manually',
                                inputType: 'checkbox',
                                value: 'AllowAddingNonMicrosoftAccountsManually',
                                helpText: 'Specifies whether user is allowed to use non MSA email accounts',
                                parentId: 'Accounts',
                                myId: 'AllowAddingNonMicrosoftAccountsManually',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Remove Provisioning Package',
                                inputType: 'checkbox',
                                value: 'AllowRemoveProvisioningPackage',
                                helpText: 'Specifies whether to allow the runtime configuration agent to remove provisioning packages.',
                                parentId: 'Security',
                                myId: 'AllowRemoveProvisioningPackage',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Applications',
                        fields: [
                            {
                                label: 'Allow all Trusted Apps to Install',
                                value: 'AllowAllTrustedApps',
                                inputType: 'dropdown',
                                placeholder: 'Select',
                                helpText: 'Specifies whether Non-Microsoft Apps are allowed to be installed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowAllTrustedApps',
                                defaultValue: 65535,
                                options: [
                                    { label: 'Explicit Deny.', value: 0 },
                                    { label: 'Explicit Allow Unlock.', value: 1 },
                                    { label: 'Not Configured.', value: 65535 }
                                ]
                            },
                            {
                                label: 'Prevent users app data from being stored on non-system volumes',
                                inputType: 'checkbox',
                                value: 'RestrictAppDataToSystemVolume',
                                helpText: 'Specifies whether application data is restricted to the system drive',
                                parentId: 'ApplicationManagement',
                                myId: 'RestrictAppDataToSystemVolume',
                                defaultValue: 0,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Restricted.', value: 0 },
                                    { label: 'Restricted.', value: 1 }
                                ]
                            },
                            {
                                label: 'Disable installing Windows Apps on Non-System Volumes',
                                inputType: 'checkbox',
                                value: 'RestrictAppToSystemVolume',
                                helpText: 'Specifies whether the installation of applications is restricted to the system drive',
                                parentId: 'ApplicationManagement',
                                myId: 'RestrictAppToSystemVolume',
                                defaultValue: 0,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not Restricted.', value: 0 },
                                    { label: 'Restricted.', value: 1 }
                                ]
                            },
                            {
                                label: 'Turn off Automatic Download and Install of apps updates',
                                value: 'AllowAppStoreAutoUpdate',
                                inputType: 'dropdown',
                                placeholder: 'Select',
                                helpText: 'Specifies whether automatic update of apps from Microsoft store are allowed',
                                parentId: 'ApplicationManagement',
                                myId: 'AllowAppStoreAutoUpdate',
                                defaultValue: 2,
                                options: [
                                    { label: 'Not Allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 },
                                    { label: 'Not Configured.', value: 2 }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'Projections',
                        fields: [
                            {
                                label: 'User input from Wireless Display',
                                inputType: 'checkbox',
                                value: 'AllowUserInputFromWirelessDisplayReceiver',
                                helpText:
                                    'Specifies whether the wireless display can transmit input back to the source device if the display supports such features',
                                parentId: 'WirelessDisplay',
                                myId: 'AllowUserInputFromWirelessDisplayReceiver',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Disabled.', value: 0 },
                                    { label: 'Enabled.', value: 1 }
                                ]
                            },
                            {
                                label: 'Projection to this PC',
                                inputType: 'checkbox',
                                value: 'AllowProjectionToPC',
                                helpText:
                                    'Specifies whether the user can control the projection settings to make the PC either discoverable and capable of receiving projections or hidden and not available for projections',
                                parentId: 'WirelessDisplay',
                                myId: 'AllowProjectionToPC',
                                defaultValue: 1,
                                checkedValue: 1,
                                options: [
                                    { label: 'Not allowed.', value: 0 },
                                    { label: 'Allowed.', value: 1 }
                                ]
                            },
                            {
                                label: 'Require PIN for pairing',
                                inputType: 'dropdown',
                                value: 'RequirePinForPairing',
                                helpText: 'Specifies if a PIN is required before pairing with other devices',
                                parentId: 'WirelessDisplay',
                                myId: 'RequirePinForPairing',
                                defaultValue: 0,
                                row: true,
                                options: [
                                    { label: 'PIN is not required.', value: 0 },
                                    { label: 'Pairing ceremony for new devices will always require a PIN', value: 1 },
                                    { label: 'All pairings will require PIN', value: 1 }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Power Settings',
        helpText:
            'Power settings on your Windows 10 or later devices can assist in reducing energy consumption and extend battery life.Note: Requires a restart to take effect.',
        image: power,
        fields: [
            {
                label: 'Battery'
            },
            {
                label: 'Energy Saver Battery Threshold',
                value: 'EnergySaverBatteryThresholdOnBattery',
                helpText: 'Specifies the battery charge level at which energy saver turns on',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100,
                parentId: 'Power',
                myId: 'EnergySaverBatteryThresholdOnBattery',
                defaultValue: 0,
                placeholder: '0-100'
            },
            {
                label: 'Power Button Action',
                value: 'SelectPowerButtonActionOnBattery',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the power button',
                parentId: 'Power',
                myId: 'SelectPowerButtonActionOnBattery',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Sleep Button Action',
                value: 'SelectSleepButtonActionOnBattery',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the sleep button',
                parentId: 'Power',
                myId: 'SelectSleepButtonActionOnBattery',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Hybrid Sleep',
                inputType: 'checkbox',
                value: 'TurnOffHybridSleepOnBattery',
                helpText: 'Specifies if the user can turn on hybrid sleep mode',
                parentId: 'Power',
                myId: 'TurnOffHybridSleepOnBattery',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'Hybrid Sleep.', value: 1 }
                ]
            },
            {
                label: 'PluggedIn'
            },
            {
                label: 'Energy Saver Battery Threshold',
                value: 'EnergySaverBatteryThresholdPluggedIn',
                helpText: 'Specifies the battery charge level at which energy saver turns on',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100,
                parentId: 'Power',
                myId: 'EnergySaverBatteryThresholdPluggedIn',
                defaultValue: 0,
                placeholder: '0-100'
            },
            {
                label: 'Power Button Action',
                value: 'SelectPowerButtonActionPluggedIn',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the power button',
                parentId: 'Power',
                myId: 'SelectPowerButtonActionPluggedIn',
                defaultValue: 1,
                options: [
                    { label: 'Take no action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System hibernate sleep state', value: 2 },
                    { label: 'System shutdown', value: 3 }
                ]
            },
            {
                label: 'Sleep Button Action',
                value: 'SelectSleepButtonActionPluggedIn',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText: 'Specifies the action that Windows takes when a user presses the sleep button',
                parentId: 'Power',
                myId: 'SelectSleepButtonActionPluggedIn',
                defaultValue: 1,
                options: [
                    { label: 'Take no Action', value: 0 },
                    { label: 'Sleep', value: 1 },
                    { label: 'System Hibernate Sleep State', value: 2 },
                    { label: 'System Shutdown', value: 3 }
                ]
            },
            {
                label: 'Hybrid Sleep',
                inputType: 'checkbox',
                value: 'TurnOffHybridSleepPluggedIn',
                helpText: 'Specifies if the user can turn on hybrid sleep mode',
                parentId: 'Power',
                myId: 'TurnOffHybridSleepPluggedIn',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'Hybrid Sleep.', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Delivery Optimization',
        helpText:
            'Delivery Optimization settings on your Windows 10 or later devices can assist reduce bandwidth use when downloading applications and updates.',
        image: delivery,
        fields: [
            {
                label: 'Download mode',
                value: 'DODownloadMode',
                inputType: 'dropdown',
                placeholder: 'Select',
                helpText:
                    'Specifies the download method that Delivery Optimization uses for downloading Windows Updates, apps and app updates',
                parentId: 'DeliveryOptimization',
                myId: 'DODownloadMode',
                defaultValue: 0,
                options: [
                    { label: 'HTTP only, no Peering.', value: 0 },
                    { label: 'HTTP blended with peering behind the same NAT.', value: 1 },
                    { label: 'Peering will cross NATs', value: 2 },
                    { label: 'HTTP blended with Internet Peering.', value: 3 },
                    {
                        label: 'Simple download mode with no peering. Delivery Optimization downloads using HTTP only and does not attempt to contact the Delivery Optimization cloud services. Added in Windows 10, version 1607.',
                        value: 99
                    },
                    {
                        label: 'Bypass mode. Windows 10: Do not use Delivery Optimization and use BITS instead. Windows 11: Deprecated, use Simple mode instead.',
                        value: 100
                    }
                ]
            },
            {
                label: 'Delay Background HTTP download',
                value: 'DODelayBackgroundDownloadFromHttp',
                helpText:
                    'Specifies how long to delay using an HTTP source for background downloads that can use peer-to-peer. After this delay, the download resumes using HTTP to complete. Note that if waiting for peer sources, the download may appear stuck. The recommended delay is one minute.',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 4294967295,
                parentId: 'DeliveryOptimization',
                myId: 'DODelayBackgroundDownloadFromHttp',
                defaultValue: 0,
                placeholder: '0-4294967295 Second(s)'
            },
            {
                label: 'Delay Foreground HTTP download',
                value: 'DODelayForegroundDownloadFromHttp',
                helpText:
                    'Allows you to delay using an HTTP source for foreground downloads that can also use peer-to-peer. After the maximum delay, the download switches to HTTP to complete. Note that if waiting for peer sources, the download may appear stuck. The recommended delay is one minute.',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 4294967295,
                parentId: 'DeliveryOptimization',
                myId: 'DODelayForegroundDownloadFromHttp',
                defaultValue: 0,
                placeholder: '0-4294967295 Second(s)'
            },
            {
                label: 'Minimum RAM required for peer caching',
                value: 'DOMinRAMAllowedToPeer',
                helpText: 'Specifies the minimum RAM size in .GB required to use peer caching.',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100000,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinRAMAllowedToPeer',
                defaultValue: 0,
                placeholder: '1-100000 GB(s)'
            },
            {
                label: 'Minimum disk size required for peer caching',
                value: 'DOMinDiskSizeAllowedToPeer',
                helpText:
                    'Specifies the minimum disk size (capacity in GB) required for the device to use peer caching. Recommended value: 64 - 256 GB ',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100000,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinDiskSizeAllowedToPeer',
                defaultValue: 0,
                placeholder: '1-100000 GB(s)'
            },
            {
                label: 'Minimum content file size required for Peer Caching',
                value: 'DOMinFileSizeToCache',
                helpText: 'Specifies the minimum content file size in MB enabled to use peer caching. Recommended value: 1 - 100,000 MB',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100000,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinFileSizeToCache',
                defaultValue: 0,
                placeholder: '1-100000 MB(s)'
            },
            {
                label: 'Minimum Battery Percentage required to upload',
                value: 'DOMinBatteryPercentageAllowedToUpload',
                helpText:
                    'Specifies a battery percentage between 1 - 100 to allow the device to upload data to LAN and group peers while running on battery power. Uploads would automatically pause if battery level falls below the minimum specified battery level.',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 100,
                parentId: 'DeliveryOptimization',
                myId: 'DOMinBatteryPercentageAllowedToUpload',
                defaultValue: 0,
                placeholder: '1-100%'
            },
            {
                label: 'Modify Cache Drive',
                value: 'DOModifyCacheDrive',
                helpText: 'Specifies the drive that delivery optimization should use for its cache',
                inputType: 'policyInput',
                parentId: 'DeliveryOptimization',
                myId: 'DOModifyCacheDrive',
                defaultValue: '%SystemDrive%',
                placeholder: 'Drive location path'
            },
            {
                label: 'Maximum Cache Age',
                value: 'DOMaxCacheAge',
                helpText:
                    'Specifies the maximum time in seconds that each file is held in delivery optimization cache after downloading successfully',
                inputType: 'policyInput',
                type: 'number',
                minValue: 0,
                maxValue: 4294967295,
                parentId: 'DeliveryOptimization',
                myId: 'DOMaxCacheAge',
                defaultValue: 0,
                placeholder: '0-4294967295 second(s)'
            },
            {
                label: 'VPN Peer Caching',
                inputType: 'checkbox',
                value: 'DOAllowVPNPeerCaching',
                helpText: 'Specifies whether the device can participate in peer caching while connected via VPN to the domain network',
                parentId: 'DeliveryOptimization',
                myId: 'DOAllowVPNPeerCaching',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Defender',
        helpText:
            'Defender Profile incorporates parameters for safeguarding your system by controlling system scans, protecting against network-level attacks, and enabling real-time monitoring.',
        image: defender,
        fields: [
            {
                label: 'Scan Archives',
                inputType: 'checkbox',
                value: 'AllowArchiveScanning',
                helpText: 'Allows or disallows scanning of archives',
                parentId: 'Defender',
                myId: 'AllowArchiveScanning',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Behaviour Monitoring',
                inputType: 'checkbox',
                value: 'AllowBehaviorMonitoring',
                helpText: 'Allows or disallows Windows Defender Behavior Monitoring functionality.',
                parentId: 'Defender',
                myId: 'AllowBehaviorMonitoring',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Cloud Protection',
                inputType: 'checkbox',
                value: 'AllowCloudProtection',
                helpText:
                    'Specifies that Windows Defender sends information to Microsoft about detected issues to enhance PC protection and provide improved solutions for users',
                parentId: 'Defender',
                myId: 'AllowCloudProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Email',
                inputType: 'checkbox',
                value: 'AllowEmailScanning',
                helpText: 'Allows or disallows scanning of email',
                parentId: 'Defender',
                myId: 'AllowEmailScanning',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Mapped Network Drives',
                inputType: 'checkbox',
                value: 'AllowFullScanOnMappedNetworkDrives',
                helpText: 'Allows or disallows complete scanning of mapped network drives',
                parentId: 'Defender',
                myId: 'AllowFullScanOnMappedNetworkDrives',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Removable Drives',
                inputType: 'checkbox',
                value: 'AllowFullScanRemovableDriveScanning',
                helpText: 'Allows or disallows scan of removable drives',
                parentId: 'Defender',
                myId: 'AllowFullScanRemovableDriveScanning',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Intrusion Prevention System',
                inputType: 'checkbox',
                value: 'AllowIntrusionPreventionSystem',
                helpText: 'Allows or disallows Windows defender intrusion prevention functionality',
                parentId: 'Defender',
                myId: 'AllowIntrusionPreventionSystem',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Input/Output File Protection',
                inputType: 'checkbox',
                value: 'AllowIOAVProtection',
                helpText: 'Allows or disallows Windows defender intrusion IOAVP protection functionality',
                parentId: 'Defender',
                myId: 'AllowIOAVProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Cloud Protection Level',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'CloudBlockLevel',
                helpText: 'Specifies how aggressive windows defender antivirus will be in blocking & scanning suspicious files',
                parentId: 'Defender',
                myId: 'CloudBlockLevel',
                defaultValue: 0,
                options: [
                    { label: 'Not Configured.', value: 0 },
                    { label: 'High.', value: 2 },
                    { label: 'HighPlus', value: 4 },
                    { label: 'ZeroTolerance', value: 6 }
                ]
            },
            {
                label: 'Allow On Access Protection',
                inputType: 'checkbox',
                value: 'AllowOnAccessProtection',
                helpText: 'Allows or disallows Windows Defender On Access Protection functionality.',
                parentId: 'Defender',
                myId: 'AllowOnAccessProtection',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Scan Network Files',
                inputType: 'checkbox',
                value: 'AllowScanningNetworkFiles',
                helpText: 'Allows or disallows a scanning of network files.',
                parentId: 'Defender',
                myId: 'AllowScanningNetworkFiles',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'Real-Time Monitoring',
                inputType: 'checkbox',
                value: 'AllowRealtimeMonitoring',
                helpText: 'Allows or disallows Windows Defender Realtime Monitoring functionality.',
                parentId: 'Defender',
                myId: 'AllowRealtimeMonitoring',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed.', value: 0 },
                    { label: 'Allowed.', value: 1 }
                ]
            },
            {
                label: 'PUA Protection',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'PUAProtection',
                helpText:
                    'Specifies the level of detection for potentially unwanted applications (PUAs). Windows Defender alerts you when potentially unwanted software is being downloaded or attempts to install itself on your computer.',
                parentId: 'Defender',
                myId: 'PUAProtection',
                defaultValue: 0,
                options: [
                    { label: 'PUA Protection off.', value: 0 },
                    { label: 'PUA Protection on. Detected items are blocked.', value: 1 },
                    { label: 'Audit mode. Windows Defender will detect potentially unwanted applications, but take no action.', value: 2 }
                ]
            },
            {
                label: 'Monitor & Scan',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'RealTimeScanDirection',
                helpText:
                    'Controls which sets of files should be monitored. Note If AllowOnAccessProtection is not allowed, then this configuration can be used to monitor specific files.',
                parentId: 'Defender',
                myId: 'RealTimeScanDirection',
                defaultValue: 0,
                options: [
                    { label: 'Monitor all files (bi-directional).', value: 0 },
                    { label: 'Monitor incoming files.', value: 1 },
                    { label: 'Monitor outgoing files.', value: 2 }
                ]
            },
            {
                label: 'Scheduled Scan Type',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'ScanParameter',
                helpText: 'Selects whether to perform a quick scan or full scan.',
                parentId: 'Defender',
                myId: 'ScanParameter',
                defaultValue: 1,
                options: [
                    { label: 'Quick scan', value: 1 },
                    { label: 'Full scan', value: 2 }
                ]
            },
            {
                label: 'Network Protection',
                inputType: 'dropdown',
                placeholder: 'Select',
                value: 'EnableNetworkProtection',
                helpText:
                    "This policy allows you to turn network protection on (block/audit) or off. Network protection protects employees using any app from accessing phishing scams, exploit-hosting sites, and malicious content on the Internet. This includes preventing third-party browsers from connecting to dangerous sites. Value type is integer. If you enable this setting, network protection is turned on and employees can't turn it off. Its behavior can be controlled by the following options: Block and Audit. If you enable this policy with the Block option, users/apps will be blocked from connecting to dangerous domains. You will be able to see this activity in Windows Defender Security Center. If you enable this policy with the Audit option, users/apps will not be blocked from connecting to dangerous domains. However, you will still see this activity in Windows Defender Security Center. If you disable this policy, users/apps will not be blocked from connecting to dangerous domains. You will not see any network activity in Windows Defender Security Center. If you do not configure this policy, network blocking will be disabled by default.",
                parentId: 'Defender',
                myId: 'EnableNetworkProtection',
                defaultValue: 0,
                options: [
                    { label: 'Disabled', value: 0 },
                    { label: 'Enabled (block mode)', value: 1 },
                    { label: 'Enabled (audit mode)', value: 2 }
                ]
            },
            {
                label: 'User Interface Lockdown',
                inputType: 'checkbox',
                value: 'AllowUserUIAccess',
                helpText:
                    'Allows or disallows user access to the Windows Defender UI. If disallowed, all Windows Defender notifications will also be suppressed.',
                parentId: 'Defender',
                myId: 'AllowUserUIAccess',
                defaultValue: 1,
                checkedValue: 1,
                options: [
                    { label: 'Not Allowed. Prevents users from accessing UI.', value: 0 },
                    { label: 'Allowed. Lets users access UI.', value: 1 }
                ]
            },
            {
                label: 'CPU Throttle on Scheduled Scan',
                inputType: 'dropdown',
                value: 'ThrottleForScheduledScanOnly',
                helpText:
                    'A CPU usage limit can be applied to scheduled scans only, or to scheduled and custom scans. The default value applies a CPU usage limit to scheduled scans only.',
                parentId: 'Defender',
                myId: 'ThrottleForScheduledScanOnly',
                defaultValue: 1,
                options: [
                    { label: 'Only to scheduled scans.', value: 0 },
                    { label: 'Scheduled custom scans.', value: 1 }
                ]
            },
            {
                label: 'File Hash Computation',
                inputType: 'checkbox',
                value: 'EnableFileHashComputation',
                helpText:
                    'Enables or disables file hash computation feature. When this feature is enabled Windows defender will compute hashes for files it scans.',
                parentId: 'Defender',
                myId: 'EnableFileHashComputation',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'Hide Exclusions from Local Admins',
                inputType: 'checkbox',
                value: 'HideExclusionsFromLocalAdmins',
                helpText:
                    'This policy setting controls whether or not exclusions are visible to local admins. To control local users exlcusions visibility use HideExclusionsFromLocalUsers. If HideExclusionsFromLocalAdmins is set then HideExclusionsFromLocalUsers will be implicitly set.',
                parentId: 'Defender',
                myId: 'HideExclusionsFromLocalAdmins',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'Hide Exclusions from Local Users',
                inputType: 'checkbox',
                value: 'HideExclusionsFromLocalUsers',
                helpText:
                    'This policy setting controls whether or not exclusions are visible to local users. If HideExclusionsFromLocalAdmins is set then this policy will be implicitly set.',
                parentId: 'Defender',
                myId: 'HideExclusionsFromLocalUsers',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            }
        ]
    },
    {
        label: 'Device Health Monitoring',
        helpText:
            'Microsoft can gather event data and provide recommendations to improve the performance of your Windows devices. Endpoint Analytics analyses this data to recommend software, improve startup performance, and resolve typical support concerns.',
        image: healthMonitoring,
        fields: [
            {
                label: 'Device Health Monitoring',
                value: 'AllowDeviceHealthMonitoring',
                helpText: 'Allows user to monitor the connections between device and Microsoft',
                inputType: 'checkbox',
                parentId: 'DeviceHealthMonitoring',
                myId: 'AllowDeviceHealthMonitoring',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Enabled', value: 1 },
                    { label: 'Disabled.', value: 0 }
                ]
            }
        ]
    },
    {
        label: 'Application Guard',
        helpText:
            'Application Guard serves as a protective barrier between an untrusted session and the host system. If a user uses Application Guard to access an untrusted site, the session is separated. All operations within this session are contained, therefore the host system is unaffected.',
        image: applicationguard,
        fields: [
            {
                label: 'Microsoft Defender Application Guard',
                inputType: 'dropdown',
                value: 'AllowWindowsDefenderApplicationGuard',
                helpText: 'Turn on Microsoft Defender Application Guard in Enterprise Mode.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowWindowsDefenderApplicationGuard',
                defaultValue: '',
                options: [
                    {
                        label: 'Disable',
                        value: 0
                    },
                    {
                        label: 'Enable for Microsoft Edge',
                        value: 1
                    },
                    {
                        label: 'Enable for Isolated Windows environment',
                        value: 2
                    },
                    {
                        label: 'Enable for Microsoft Edge and isolated Windows environment',
                        value: 3
                    }
                ]
            },
            {
                label: 'Clipboard Behaviour',
                inputType: 'dropdown',
                value: 'ClipboardSettings',
                helpText: 'This policy setting allows you to decide how the clipboard behaves while in Application Guard.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'ClipboardSettings',
                defaultValue: 0,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Completely turns Off the clipboard functionality for the Application Guard.',
                        value: 0
                    },
                    {
                        label: 'Turns On clipboard operation from an isolated session to the host.',
                        value: 1
                    },
                    {
                        label: 'Turns On clipboard operation from the host to an isolated session.',
                        value: 2
                    },
                    {
                        label: 'Turns On clipboard operation in both the directions.',
                        value: 3
                    }
                ]
            },
            {
                label: 'Clipboard Filetype',
                inputType: 'dropdown',
                value: 'ClipboardFileType',
                helpText:
                    'Determines the type of content that can be copied from the host to Application Guard environment and vice versa.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'ClipboardFileType',
                defaultValue: '',
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Allow Text copying.',
                        value: 1
                    },
                    {
                        label: 'Allow Image copying.',
                        value: 2
                    },
                    {
                        label: 'Allow Text and Image copying.',
                        value: 3
                    }
                ]
            },
            {
                label: 'Printing Settings',
                inputType: 'dropdown',
                value: 'PrintingSettings',
                helpText: 'This policy setting allows you to decide how the print functionality behaves while in Application Guard.',
                placeholder: 'Select',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'PrintingSettings',
                defaultValue: 0,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Disables all print functionality.',
                        value: 0
                    },
                    {
                        label: 'Enables only XPS Printing.',
                        value: 1
                    },
                    {
                        label: 'Enables only PDF Printing.',
                        value: 2
                    },
                    {
                        label: 'Enables both PDF and XPS Printing.',
                        value: 3
                    },
                    {
                        label: 'Enables only Local Printing.',
                        value: 4
                    },
                    {
                        label: 'Enables both Local and XPS Printing.',
                        value: 5
                    },
                    {
                        label: 'Enables both Local and PDF Printing.',
                        value: 6
                    },
                    {
                        label: 'Enables local, PDF, and XPS Printing.',
                        value: 7
                    },
                    {
                        label: 'Enables only Network printing.',
                        value: 8
                    },
                    {
                        label: 'Enables both Network and XPS Printing.',
                        value: 9
                    },
                    {
                        label: 'Enables both Network and PDF Printing.',
                        value: 10
                    },
                    {
                        label: 'Enables network, PDF, and XPS Printing.',
                        value: 11
                    },
                    {
                        label: 'Enables both Network and local Printing.',
                        value: 12
                    },
                    {
                        label: 'Enables Network, Local, and XPS Printing.',
                        value: 13
                    },
                    {
                        label: 'Enables Network, Local, and PDF Printing.',
                        value: 14
                    },
                    {
                        label: 'Enables all Printing.',
                        value: 15
                    }
                ]
            },
            {
                label: 'Collect Logs',
                value: 'AuditApplicationGuard',
                helpText: 'DeviceHealthMonitoring is an opt-in health monitoring connection between the device and Microsoft.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Audit',
                myId: 'AuditApplicationGuard',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    { label: "Audit event logs aren't collected for Application Guard.", value: 0 },
                    {
                        label: 'Application Guard inherits its auditing policies from system and starts to audit security events for Application Guard container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Graphics Accelertation',
                value: 'AllowVirtualGPU',
                helpText:
                    'This policy setting allows you to determine whether Application Guard can use the virtual Graphics Processing Unit (GPU) to process graphics. If you enable this setting, Microsoft Defender Application Guard uses Hyper-V to access supported, high-security rendering graphics hardware (GPUs). These GPUs improve rendering performance and battery life while using Microsoft Defender Application Guard, particularly for video playback and other graphics-intensive use cases. If you enable this setting without connecting any high-security rendering graphics hardware, Microsoft Defender Application Guard will automatically revert to software-based (CPU) rendering.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowVirtualGPU',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Cannot access the vGPU and uses the CPU to support rendering graphics. When the policy is not configured, it is the same as disabled (0).',
                        value: 0
                    },
                    {
                        label: 'Turns on the functionality to access the vGPU offloading graphics rendering from the CPU. This can create a faster experience when working with graphics intense websites or watching video within the container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Download Files to Host File System',
                value: 'SaveFilesToHost',
                helpText:
                    'This policy setting allows you to determine whether users can elect to download files from Edge in the container and persist files them from container to the host operating system.',
                inputType: 'checkbox',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'SaveFilesToHost',
                defaultValue: 0,
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Cannot access the vGPU and uses the CPU to support rendering graphics. When the policy is not configured, it is the same as disabled (0).',
                        value: 0
                    },
                    {
                        label: 'Turns on the functionality to access the vGPU offloading graphics rendering from the CPU. This can create a faster experience when working with graphics intense websites or watching video within the container.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Retain User Generated Browser Data',
                value: 'AllowPersistence',
                helpText:
                    'This policy setting allows you to decide whether data should persist across different sessions in Application Guard.',
                placeholder: 'Select',
                inputType: 'dropdown',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'AllowPersistence',
                defaultValue: '',
                checkedValue: 1,
                enableOn: 'AllowWindowsDefenderApplicationGuard',
                enableOnVal: [1, 3],
                options: [
                    {
                        label: 'Application Guard discards user-downloaded files and other items (such as, cookies, Favorites, and so on) during machine restart or user log-off.',
                        value: 0
                    },
                    {
                        label: 'Application Guard saves user-downloaded files and other items (such as, cookies, Favorites, and so on) for use in future Application Guard sessions.',
                        value: 1
                    }
                ]
            },
            {
                label: 'Certificate Thumbprint',
                value: 'CertificateThumbprints',
                helpText:
                    "This policy setting allows certain device level Root Certificates to be shared with the Microsoft Defender Application Guard container. If you enable this setting, certificates with a thumbprint matching the ones specified will be transferred into the container. Multiple certificates can be specified by using a comma to separate the thumbprints for each certificate you want to transfer. Here's an example: b4e72779a8a362c860c36a6461f31e3aa7e58c14,1b1d49f06d2a697a544a1059bd59a7b058cda924. If you disable or don’t configure this setting, certificates are not shared with the Microsoft Defender Application Guard container.",
                inputType: 'policyInput',
                parentId: 'WindowsDefenderApplicationGuard/Settings',
                myId: 'CertificateThumbprints',
                defaultValue: '',
                placeholder: 'Specify the certificate thumbprint'
            }
        ]
    },
    {
        label: 'Device Guard',
        helpText:
            'Device Guard is a security feature for Windows devices that protects users from credential theft and exploits during system startup, as well as disabling the operating system through registry key changes that could compromise the machine.',
        image: deviceguard,
        fields: [
            {
                label: 'Virtualization Based Security',
                value: 'EnableVirtualizationBasedSecurity',
                helpText: 'Specifies whether Virtualization Based Security(VBS) is enabled',
                inputType: 'checkbox',
                parentId: 'DeviceGuard',
                myId: 'EnableVirtualizationBasedSecurity',
                defaultValue: 0,
                checkedValue: 1,
                options: [
                    { label: 'Disable', value: 0 },
                    { label: 'Enable', value: 1 }
                ]
            },
            {
                label: 'System Guard',
                value: 'ConfigureSystemGuardLaunch',
                helpText: 'Specifies Secure Launch Configuration.',
                inputType: 'dropdown',
                parentId: 'DeviceGuard',
                myId: 'ConfigureSystemGuardLaunch',
                defaultValue: 0,
                options: [
                    { label: 'Unmanaged Configurable by Administrative user', value: 0 },
                    { label: 'Unmanaged Enables Secure Launch if supported by hardware', value: 1 },
                    { label: 'Unmanaged Disables Secure Launch', value: 2 }
                ]
            },
            {
                label: 'Credential Guard',
                value: 'LsaCfgFlags',
                helpText: 'Specifies Credential Guard Configuration.',
                inputType: 'dropdown',
                parentId: 'DeviceGuard',
                myId: 'LsaCfgFlags',
                defaultValue: 0,
                options: [
                    { label: '(Disabled) Turns off Credential Guard remotely if configured previously without UEFI Lock.', value: 0 },
                    { label: '(Enabled with UEFI lock) Turns on Credential Guard with UEFI Lock.', value: 1 },
                    { label: '(Enabled without lock) Turns on Credential Guard without UEFI Lock.', value: 2 }
                ]
            }
        ]
    },
    {
        label: 'Wi-Fi',
        helpText: 'Use this section to configure Wi-Fi Settings',
        image: wifi,
        fields: [
            {
                label: 'Wi-Fi Configuration',
                value: 'WlanXml',
                inputType: 'array',
                fields: [
                    {
                        label: 'Wi-Fi Name/SSID',
                        value: 'ssidName',
                        helpText: 'Service Set Identifier(SSID) of the wireless network to connect to',
                        inputType: 'policyInput',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true,
                        showInCard: true
                    },
                    {
                        label: 'Auto Connect',
                        value: 'autoConnect',
                        helpText: 'Specifies whether automatic connection to this wireless network is enabled',
                        inputType: 'checkbox',
                        defaultValue: 'auto',
                        checkedValue: 'auto',
                        options: [
                            { label: 'Enabled', value: 'auto' },
                            { label: 'Disabled', value: 'manual' }
                        ]
                    },
                    {
                        label: 'Hidden Network',
                        value: 'hiddenNetwork',
                        helpText: 'Enable if the target network is not open or actively broadcasting',
                        inputType: 'checkbox',
                        defaultValue: false,
                        checkedValue: true,
                        options: [
                            { label: 'Enabled', value: true },
                            { label: 'Disabled', value: false }
                        ]
                    },
                    {
                        label: 'Security Type',
                        value: 'securityType',
                        inputType: 'dropdown',
                        placeholder: 'Select',
                        helpText: 'Wireless network encryption to use when connecting',
                        defaultValue: 'NONE',
                        options: [
                            { label: 'None', value: 'NONE' },
                            { label: 'WPA2 PSK', value: 'WPA2PSK' }
                        ],
                        showInCard: true
                    },
                    {
                        label: 'Password',
                        value: 'password',
                        helpText: 'Password for the wireless network',
                        enableOn: 'securityType',
                        enableOnVal: ['WPA2PSK'],
                        inputType: 'policyInput',
                        type: 'password',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true
                    },
                    {
                        label: 'Proxy Settings',
                        value: 'proxySettings',
                        inputType: 'dropdown',
                        placeholder: 'Select',
                        helpText:
                            'If you choose the manual proxy, you need to provide proxy server address, including its port and optionally a user name and password into the proxy server.',
                        defaultValue: 'automatic',
                        options: [
                            { label: 'Automatic', value: 'automatic' },
                            { label: 'Manual', value: 'manual' }
                        ],
                        showInCard: true
                    },
                    {
                        label: 'Proxy Server and Port',
                        value: 'proxyurl',
                        helpText: 'Proxy Server and Port number',
                        enableOn: 'proxySettings',
                        enableOnVal: ['manual'],
                        inputType: 'policyInput',
                        defaultValue: '',
                        placeholder: 'Enter',
                        maxLength: 20,
                        mandatory: true
                    }
                ]
            }
        ]
    },
    {
        label: 'Email',
        helpText: 'Use this section to configure the Incoming and Outgoing mail Server Settings',
        image: email,
        fields: [
            {
                label: 'Account Type',
                value: 'ACCOUNTTYPE',
                helpText: 'Specifies the type of account.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'ACCOUNTTYPE',
                defaultValue: 'Email',
                mandatory: true,
                options: [
                    { label: 'Email', value: 'Email' },
                    { label: 'Visual Voicemail (VVM)', value: 'VVM' }
                ]
            },
            {
                label: 'User Name',
                value: 'AUTHNAME',
                helpText:
                    "Specifies the name used to authorize the user to a specific email account (also known as the user's logon name).",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'AUTHNAME',
                defaultValue: '',
                placeholder: '%username%',
                mandatory: true,
                maxLength: 24
            },
            {
                label: 'Password',
                value: 'AUTHSECRET',
                helpText: "Specifies the user's password. The same password is used for SMTP authentication.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'AUTHSECRET',
                defaultValue: '',
                placeholder: 'Enter',
                type: 'password'
            },
            {
                label: 'Domain Name',
                value: 'DOMAIN',
                helpText: 'Specifies the incoming server credentials domain.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'DOMAIN',
                defaultValue: '',
                placeholder: '%domainname%',
                maxLength: 24
            },
            {
                label: 'Download e-mails received in the last',
                value: 'DWNDAY',
                helpText: 'Specifies how many days worth of email should be downloaded from the server.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'DWNDAY',
                defaultValue: '7',
                options: [
                    { label: '7 Days', value: '7' },
                    { label: '14 days', value: '14' },
                    { label: '30 days', value: '30' }
                ]
            },
            {
                label: 'Frequency to Sync Mails',
                value: 'LINGER',
                helpText: 'Specifies the length of time between email send/receive updates.',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'LINGER',
                defaultValue: '15',
                options: [
                    { label: 'Manual', value: '7' },
                    { label: '15 Minute(s)', value: '15' },
                    { label: '30 Minute(s)', value: '30' },
                    { label: '1 Hour(s)', value: '60' },
                    { label: '2 Hour(s)', value: '120' }
                ]
            },
            {
                label: 'Display Name',
                value: 'NAME',
                helpText: 'Specifies the name of the sender displayed on a sent email.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'NAME',
                defaultValue: '',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'E-mail address to receive e-mail',
                value: 'REPLYADDR',
                helpText: 'Specifies the reply email address of the user. Sending email will fail without it.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'REPLYADDR',
                defaultValue: '',
                placeholder: '%email%'
            },
            {
                label: 'Account Display Name',
                value: 'SERVICENAME',
                helpText: 'Specifies the name of the email service to create or edit.',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SERVICENAME',
                defaultValue: '',
                placeholder: 'Enter',
                mandatory: true,
                maxLength: 32
            },
            {
                label: 'Service Type',
                value: 'SERVICETYPE',
                helpText: 'Specifies the type of email service to create or edit',
                inputType: 'dropdown',
                parentId: 'EMAIL2',
                myId: 'SERVICETYPE',
                defaultValue: 'POP3',
                mandatory: true,
                options: [
                    { label: 'IMAP4', value: 'IMAP4' },
                    { label: 'POP3', value: 'POP3' }
                ]
            },
            {
                label: 'SMTP Alternative',
                value: 'SMTPALTENABLED',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'SMTPALTENABLED',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Enable', value: '1' },
                    { label: 'Disable', value: '0' }
                ]
            },
            {
                label: 'SMTP Alternative Authentication Name',
                value: 'SMTPALTAUTHNAME',
                helpText: "Specifies the display name associated with the user's alternative SMTP email account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTAUTHNAME',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'SMTP Alternative Domain Name',
                value: 'SMTPALTDOMAIN',
                helpText: "Specifies the domain name for the user's alternative SMTP account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTDOMAIN',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                maxLength: 24
            },
            {
                label: 'SMTP Alternative Password',
                value: 'SMTPALTPASSWORD',
                helpText: "Specifies the password for the user's alternate SMTP account.",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'SMTPALTPASSWORD',
                defaultValue: '',
                enableOn: 'SMTPALTENABLED',
                enableOnVal: '1',
                placeholder: 'Enter',
                type: 'password',
                maxLength: 24
            },
            {
                label: 'Incoming Mail'
            },
            {
                label: 'Server Name and Port',
                value: 'INSERVER',
                helpText: 'Specifies how many days worth of email should be downloaded from the server. server name:port number',
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'INSERVER',
                defaultValue: '',
                mandatory: true,
                placeholder: 'Enter'
            },
            {
                label: 'Use SSL for mail communication',
                value: 'TAGPROPS/8128000B',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'TAGPROPS/8128000B',
                tooltipId: '8128000B',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            },
            {
                label: 'Outgoing Mail'
            },
            {
                label: 'Server Name and Port',
                value: 'OUTSERVER',
                helpText:
                    "Specifies the name of the messaging service's outgoing email server. The value format is server name:port number",
                inputType: 'policyInput',
                parentId: 'EMAIL2',
                myId: 'OUTSERVER',
                defaultValue: '',
                mandatory: true,
                placeholder: 'Enter'
            },
            {
                label: 'Use SSL for mail communication',
                value: 'TAGPROPS/812C000B',
                helpText: "Specifies if the user's alternate SMTP account is enabled",
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'TAGPROPS/812C000B',
                tooltipId: '812C000B',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            },
            {
                label: 'Authentication to send mails',
                value: 'AUTHREQUIRED',
                helpText: 'Specifies whether the outgoing server requires authentication',
                inputType: 'checkbox',
                parentId: 'EMAIL2',
                myId: 'AUTHREQUIRED',
                defaultValue: '0',
                checkedValue: '1',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' }
                ]
            }
        ]
    },
    {
        label: 'VPN',
        helpText: 'Use this section to configure the VPN settings',
        image: vpn,
        fields: [
            {
                label: 'VPN Type',
                value: 'vpnType',
                helpText: 'Specifies the type of VPN to be used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                myId: 'vpnType',
                defaultValue: 'L2TP',
                options: [
                    { label: 'L2TP', value: 'L2TP' },
                    { label: 'PPTP', value: 'PPTP' },
                    { label: 'SONICWALL', value: 'SONICWALL' }
                ],
                mandatory: true
            },
            {
                label: 'Connection Name',
                value: 'connectionName',
                helpText: 'Specifies the name of the VPN connection.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'connectionName',
                defaultValue: '',
                placeholder: 'Enter',
                shouldTrim: true
            },
            {
                label: 'VPN Server(IP address)',
                value: 'ServerUrl',
                helpText: 'Specifies the VPN server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'ServerUrl',
                defaultValue: '',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                placeholder: 'Enter',
                mandatory: true
            },
            {
                label: 'VPN Server(IP address)',
                value: 'Servers',
                helpText: 'Specifies the VPN server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'Servers',
                defaultValue: '',
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                placeholder: 'Enter',
                mandatory: true
            },
            {
                label: 'Remember VPN Credentials',
                value: 'RememberCredentials',
                helpText:
                    'Specifies whether the user should be prompted to enter their credentials again when the VPN connection is re-established.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                myId: 'RememberCredentials',
                defaultValue: true,
                checkedValue: true,
                options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                ]
            },
            {
                label: 'Allow packet capture',
                value: 'PacketCapture',
                helpText: 'Specifies whether the user should be allowed to capture packets.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                isCustomConfig: true,
                subParentId: 'customConfig',
                myId: 'PacketCapture',
                defaultValue: 'false',
                checkedValue: 'true',
                options: [
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' }
                ]
            },
            {
                label: 'Use Windows Native Interface for Authentication',
                value: 'WindowsAuthUI',
                helpText: 'Specifies whether the user should be allowed to use the Windows Native Interface for Authentication.',
                inputType: 'checkbox',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['SONICWALL'],
                isCustomConfig: true,
                subParentId: 'customConfig',
                myId: 'WindowsAuthUI',
                defaultValue: 'true',
                checkedValue: 'true',
                options: [
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' }
                ]
            },
            {
                label: 'User authentication',
                value: 'Authentication/UserMethod',
                helpText: 'Specifies the type of User Authentication.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                myId: 'Authentication/UserMethod',
                tooltipId: 'UserMethod',
                defaultValue: 'MSCHAPV2',
                options: [{ label: 'Password ', value: 'MSCHAPV2' }]
            },
            {
                label: 'Native Protocol Type',
                value: 'vpnType',
                helpText: 'Specifies the type Tunneling Protocol used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                enableOn: 'vpnType',
                enableOnVal: ['L2TP', 'PPTP'],
                isCustomConfig: true,
                subParentId: 'NativeProfile',
                myId: 'NativeProtocolType',
                defaultValue: 'L2TP',
                options: [
                    { label: 'L2TP', value: 'L2TP' },
                    { label: 'PPTP', value: 'PPTP' },
                    { label: 'SONICWALL', value: 'SONICWALL' }
                ]
            },
            {
                label: 'Apps to use this VPN',
                value: 'AppTriggerList',
                helpText: 'Specifies the apps to use this VPN.',
                inputType: 'dropdown',
                isMulti: true,
                parentId: 'VPNv2',
                myId: 'AppTriggerList',
                defaultValue: [],
                placeholder: 'Select',
                options: [
                    { label: 'Alarms and clock', value: 'Microsoft.WindowsAlarms_8wekyb3d8bbwe' },
                    { label: 'Calculator', value: 'Microsoft.WindowsCalculator_8wekyb3d8bbwe' },
                    { label: 'Camera', value: 'Microsoft.WindowsCamera_8wekyb3d8bbwe' },
                    { label: 'Contact Support', value: 'Windows.ContactSupport_cw5n1h2txyewy' },
                    { label: 'Cortana', value: 'Microsoft.Windows.Cortana_cw5n1h2txyewy' },
                    { label: 'Excel', value: 'Microsoft.Office.Excel_8wekyb3d8bbwe' },
                    { label: 'Facebook', value: 'Facebook.Facebook_8xx8rvfyw5nnt' },
                    { label: 'File Explorer', value: 'c5e2524a-ea46-4f67-841f-6a9465d9d515_cw5n1h2txyewy' },
                    { label: 'Get Started', value: 'Microsoft.Getstarted_8wekyb3d8bbwe' },
                    { label: 'Groove Music', value: 'Microsoft.ZuneMusic_8wekyb3d8bbwe' },
                    { label: 'Maps', value: 'Microsoft.WindowsMaps_8wekyb3d8bbwe' },
                    { label: 'Messaging', value: 'Microsoft.Messaging_8wekyb3d8bbwe' },
                    { label: 'Money', value: 'Microsoft.BingFinance_8wekyb3d8bbwe' },
                    { label: 'Movies and TV', value: 'Microsoft.ZuneVideo_8wekyb3d8bbwe' },
                    { label: 'Microsoft Edge', value: 'Microsoft.MicrosoftEdge_8wekyb3d8bbwe' },
                    { label: 'Outlook Calendar', value: 'Microsoft.WindowsCommunicationsApps_8wekyb3d8bbwe' },
                    { label: 'Outlook Mail', value: 'Microsoft.WindowsCommunicationsApps_8wekyb3d8bbwe' },
                    { label: 'Word', value: 'Microsoft.Office.Word_8wekyb3d8bbwe' },
                    { label: 'Xbox', value: 'Microsoft.XboxApp_8wekyb3d8bbwe' }
                ]
            },
            {
                label: 'Proxy Settings',
                value: 'proxyType',
                helpText: 'Specifies the proxy settings to be used.',
                inputType: 'dropdown',
                parentId: 'VPNv2',
                myId: 'proxyType',
                defaultValue: '',
                options: [
                    { label: 'None', value: '' },
                    { label: 'Automatic', value: 'automatic' },
                    { label: 'Manual', value: 'manual' }
                ]
            },
            {
                label: 'Proxy Server',
                value: 'proxyValue',
                helpText: 'Specifies the proxy server to be used.',
                inputType: 'policyInput',
                parentId: 'VPNv2',
                myId: 'proxyValue',
                defaultValue: '',
                enableOn: 'proxyType',
                enableOnVal: ['automatic', 'manual'],
                placeholder: 'Enter',
                mandatory: true
            }
        ]
    },
    {
        label: 'Personalization',
        helpText: 'Personalization involves customizing the appearance to reflect a company’s brand identity.',
        image: personalization,
        fields: [
            {
                label: 'Home Screen Wallpaper',
                value: 'DesktopImageUrl',
                helpText: 'Sets the wallpaper for the Home screen',
                inputType: 'upload',
                parentId: 'Personalization',
                myId: 'DesktopImageUrl',
                defaultValue: '',
                mandatory: true,
                maxFileSize: '4MB',
                maxFiles: 1,
                acceptedFileTypes: ['image/*'],
                accept: ['.png', '.jpg', '.jpeg']
            },
            {
                label: 'Lock Screen Wallpaper',
                value: 'LockScreenImageUrl',
                helpText: 'Sets the wallpaper for the Home screen',
                inputType: 'upload',
                parentId: 'Personalization',
                myId: 'LockScreenImageUrl',
                defaultValue: '',
                mandatory: true,
                maxFileSize: '4MB',
                maxFiles: 1,
                acceptedFileTypes: ['image/*'],
                accept: ['.png', '.jpg', '.jpeg']
            }
        ]
    },
    {
        label: 'Applications ',
        helpText: 'Select the apps to be installed',
        image: application,
        fields: [
            {
                label: 'Applications',
                inputType: 'applications'
            }
        ]
    }
];
