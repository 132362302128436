/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Card, CardBody, Col, Container, Form, Input, Label, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, TenantConfig, convertUTCtoIST, profileTypeLabel } from '../../../Components/Common/Util';
import { detailsObj, policiesArr } from '../../../Components/constants/commons';
import { AndroidPolicyQR, enrolmentProcess, priviliges, qrSecurityOptions } from '../../../Components/constants/constants';
import polClone from '../../../assets/images/clonePolicy.png';
import polDelete from '../../../assets/images/poldelete.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { policiesSchema } from './TableSchema';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';

const Policies = (props) => {
    document.title = 'Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const [policiesTableSchema, setPoliciesTableSchema] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10000);
    const [totalRecords, setTotalRecords] = useState(0);
    const [qrCodeString, setQRCodeString] = useState('');
    const [policyType, setPolicyType] = useState('');
    const [signInEnrollment, setSignInEnrollment] = useState('NOT REQUIRED');
    const [policyCards, setPolicyCards] = useState(JSON.parse(JSON.stringify(policiesArr)));
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [qrDetails, setQrDetails] = useState(false);
    const [searchVal, setSearchVal] = useState('');

    const [qrDetailsObj, setQrDetailObj] = useState({ ...detailsObj });

    useEffect(() => {
        localStorage.removeItem('policyNameForTokens');
        getPolicies();
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.POLICY_EDITOR);
        let schema = [...policiesSchema];
        if (findPriv) schema = policiesSchema.filter((policy, index) => index < policiesSchema.length - 2);
        setPoliciesTableSchema([...schema]);
    }, []);

    const getPolicies = (paramsObj) => {
        setLoading(true);
        props.setLoading(true);
        let pURL = url.POLICIES;
        let params = {};
        if (paramsObj) params = paramsObj;
        else params = { page: pageNumber, size: pageSize };
        api.get(pURL, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data?.length > 0) {
                    setTotalRecords(resp.totalRecords);
                    resp.data.forEach((data) => {
                        data.hideQR = data.status === 'InActive';
                        data.hideToken = data.status === 'InActive';
                        data.doNotDelete = data.deviceCount > 0 || (!!data.inTimeFence && data.inTimeFence !== null);
                        data.protectedPolicy = !!data.config?.protectedPolicy;
                        data.disabled = data.deviceCount > 0;
                        data.deleteTooltip = `You cannot delete this policy, as it is ${
                            data.deviceCount > 0 ? 'already applied to a device.' : ''
                        } ${data.deviceCount > 0 && !!data.inTimeFence && data.inTimeFence !== null ? 'and' : ''} ${
                            !!data.inTimeFence && data.inTimeFence !== null ? 'being used in time fence' : ''
                        }`;
                        data.icon = !!data.config?.protectedPolicy ? 'ri-lock-2-fill' : undefined;
                        data.iconTooltip = !!data.config?.protectedPolicy ? 'This policy is secured' : undefined;
                        data.iconClass = !!data.config?.protectedPolicy ? 'link-primary' : 'link-success';
                        data.icon2 =
                            !!data.inTimeFence && data.inTimeFence !== null && !!tenantConfig.DEVICE_AUTOMATION
                                ? 'ri-settings-5-line'
                                : undefined;
                        data.icon2Tooltip =
                            !!data.inTimeFence && data.inTimeFence !== null ? `This policy is in ${data.inTimeFence}` : undefined;
                        data.iconClass2 = !!data.inTimeFence && data.inTimeFence !== null ? 'link-primary' : 'link-success';
                        data.badgeClass = !!data.config?.protectedPolicy ? 'warning' : 'success';
                        data.policyVersion = data.version ? data.version : '—';
                        data.polType = profileTypeLabel(data.policyType?.toUpperCase());
                        data.modifiedBy = data.lastmodifiedBy ? data.lastmodifiedBy : 'Tectoro';
                        let formattedDate = data.lastmodifiedTime ? convertUTCtoIST(data.lastmodifiedTime) : '—';
                        data.modifiedDate = formattedDate;
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                }
                setLoading(false);
                props.setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleAddNew = () => {
        setPolicyType('');
        let PolicyArray = policiesArr;
        let configArr = [];
        PolicyArray.forEach((ele) => {
            if (tenantConfig?.COMPANY_POLICY_ACCESS?.join()?.includes(ele.id)) configArr.push(ele);
        });
        setPolicyCards(configArr);
        setShowPolicyModal(true);
    };

    const handleEdit = (row) => {
        if (row.original.protectedPolicy) {
            setSelectedRow(row);
            setShowPasswordModal(true);
        } else history.push('/aepolicies/edit/' + row.original.id + '/' + row.original.policyType);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const handleDeleteConfirmation = () => {
        setPassword('');
        if (selectedRow?.original?.protectedPolicy && password !== tenantConfig.DEVICE_DEFAULT_PASSWORD) {
            toast.error(toastMessages.invalidPassword);
            return;
        }
        setLoading(true);
        props.setLoading(true);
        setDeleteModal(false);
        api.delete(url.POLICIES + '/' + selectedRow.original.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    setSearchVal('');
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleQR = (row) => {
        setSelectedRow(row);
        setQrDetails(true);
    };

    const handleQrConfirmation = (row) => {
        setQrDetails(false);
        setLoading(true);
        props.setLoading(true);
        let qrObj = {
            id: row.original.id,
            multipleEnrollments: true,
            signIn: qrDetailsObj.securityOptions === 'withAuthentic' || qrDetailsObj?.securityOptions === 'withStatic' ? true : false,

            config: {
                systemApps: !!qrDetailsObj?.systemApps,
                educationScreens: !!qrDetailsObj?.educationScreens,
                allowOffline: !!qrDetailsObj?.allowOffline,
                screenOn: !!qrDetailsObj?.screenOn,
                pin: qrDetailsObj?.pin ? qrDetailsObj?.pin : undefined,
                securityType: qrDetailsObj?.wifiId ? qrDetailsObj?.securityType?.value : undefined,
                wifiId: qrDetailsObj?.wifiId ? qrDetailsObj?.wifiId : undefined,
                password: qrDetailsObj?.password ? qrDetailsObj?.password : undefined,
                noOfDays: 1
            }
        };
        api.create(url.POLICIES + '/signurl', qrObj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(tenantConfig.ENABLE_SIGN_IN ? toastMessages.qrGeneratedWithoutSignIn : toastMessages.qrGenerated);
                    setShowQRModal(true);
                    setQRCodeString(resp.data);
                    setLoading(false);
                    props.setLoading(false);
                }
                setQrDetailObj({ ...detailsObj });
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
        // eslint-disable-next-line multiline-comment-style
        // } else {
        //     setSelectedRow(selectedRow);
        //     setShowQRModal(true);
        // }
    };

    const handleClone = (row) => {
        validation.resetForm();
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const handleAuditLog = (row) => {
        history.push('/aepolicies/auditlog/' + row?.original?.id);
    };

    const onClickSwitch = (row) => {
        setLoading(true);
        props.setLoading(true);
        let rowData = JSON.parse(JSON.stringify(row.original));
        let obj = {
            id: rowData.id,
            status: rowData.status?.toLowerCase() === 'active' ? 'InActive' : 'Active',
            name: rowData.name,
            code: rowData.code,
            enterpriseId: rowData.enterpriseId,
            policy: rowData.policy
        };
        api.update(url.POLICIES + '/' + row?.original?.id, obj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyStatusUpdated);
                    getPolicies();
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const handleEnrollmentToken = (row) => {
        localStorage.setItem('policyNameForTokens', row.original?.name);
        history.push('/aepolicies/enrollmenttoken/' + row.original.id + '/' + row.original.policyType);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { code: '' },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            setLoading(true);
            setShowCloneModal(false);
            props.setLoading(true);
            api.create(url.CLONE + '/' + selectedRow?.original?.id + '/' + selectedRow.original.policyType)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(toastMessages.policyCloned);
                        validation.resetForm();
                        getPolicies();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setShowCloneModal(false);
                    props.setLoading(false);
                });
        }
    });

    const handlePolicyTypeClick = (item) => {
        setPolicyType(item);
        if (item.childPolicies?.length > 0) {
            let configArr = [];
            item.childPolicies.forEach((ele) => {
                if (tenantConfig?.EMM_DD_POLICY_TYPES?.join()?.includes(ele.id)) configArr.push(ele);
            });
            setPolicyCards(configArr);
        }
    };

    const handleCloneModalBody = () => {
        return (
            <Form
                className="h-100"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="mt-3">
                    <div className="mb-4">Are you sure you want to clone this Policy?</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={polClone} alt="clonepolicy" width={60} height={60} />
                    </div>
                    <div>
                        Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                    </div>
                </div>
            </Form>
        );
    };

    const handleQrScan = (type) => {
        setLoading(true);
        props.setLoading(true);
        api.create(url.POLICIES + url.QR_CODE, {
            ...qrDetailsObj,
            id: selectedRow?.original?.id,
            signIn: type === 'REQUIRED' ? true : false,
            securityType: qrDetailsObj?.securityType?.value
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(type !== 'REQUIRED' ? toastMessages.qrGeneratedWithoutSignIn : toastMessages.qrGeneratedWithSignIn);
                    setQRCodeString(resp.data);
                    setLoading(false);
                    props.setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                props.setLoading(false);
            });
    };

    const generatingQrCode = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={5} lg={5}>
                        <Label className="fw-medium my-2 fs-16">Sign In Enrollment </Label>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={7} lg={7}>
                        <div className="mb-4 d-flex align-items-center justify-content-center w-100 ">
                            <div className="p-1 d-flex align-items-center border border-1 fs-11 border-info rounded-3 w-90 justify-content-center">
                                <div
                                    className={`${
                                        signInEnrollment !== 'REQUIRED' ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-70 px-4 py-2 cursor-pointer text-center`}
                                    onClick={() => {
                                        setQRCodeString('');
                                        setSignInEnrollment('NOT REQUIRED');
                                        handleQrScan('NOT REQUIRED');
                                    }}
                                >
                                    NOT REQUIRED
                                </div>
                                <div
                                    className={`${
                                        signInEnrollment === 'REQUIRED' ? 'bg-info text-light' : 'bg-light text-dark'
                                    } w-50 px-4 py-2 cursor-pointer text-center`}
                                    onClick={() => {
                                        setQRCodeString('');
                                        setSignInEnrollment('REQUIRED');
                                        handleQrScan('REQUIRED');
                                    }}
                                >
                                    REQUIRED
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleQrDetails = (e, key) => {
        setQrDetailObj({ ...qrDetailsObj, [key]: e.target.checked });
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                        {field.label}
                        {field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex padding-left-30">
                    <div className="form-check form-switch form-switch-lg ">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={field.value}
                            id={field.value}
                            checked={qrDetailsObj[field.value] || false}
                            onChange={(e) => handleQrDetails(e, field.value)}
                        />
                    </div>
                </Col>
            </>
        );
    };

    const radioButton = (field, index) => {
        return (
            <Row key={index}>
                {field?.label && (
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Label className="mb-2 fw-semibold  fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break ${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios?.map((ele, ind) => {
                            return (
                                <Col className="form-check form-check-inline col-12 mb-3" key={ind}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.value}
                                        name={field.value}
                                        value={ele.val}
                                        onChange={(e) => {
                                            setQrDetailObj({
                                                ...qrDetailsObj,
                                                [field.value]: e.target.value,
                                                pin: ''
                                            });
                                        }}
                                        checked={qrDetailsObj?.[field.value] === ele.val}
                                    />
                                    <Label className="mb-0 d-flex">
                                        {ele.label}
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={`radioButton-${ind}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`radioButton-${ind}`}>
                                            {ele?.toolTip}
                                        </UncontrolledTooltip>
                                    </Label>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    };
    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field?.label && (
                    <Col
                        xs={field.smallLabel ? 2 : 4}
                        sm={field.smallLabel ? 2 : 4}
                        md={field.smallLabel ? 2 : 4}
                        xl={field.smallLabel ? 2 : 4}
                        lg={field.smallLabel ? 2 : 4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </Label>
                    </Col>
                )}

                <Col
                    xs={field.largeBox ? 6 : 4}
                    sm={field.largeBox ? 6 : 4}
                    md={field.largeBox ? 6 : 4}
                    xl={field.largeBox ? 6 : 4}
                    lg={field.largeBox ? 6 : 4}
                >
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                type={field.type}
                                maxLength={field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => setQrDetailObj({ ...qrDetailsObj, pin: e.target.value })}
                                value={qrDetailsObj?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };
    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field, index);
            case 'input':
                return inputComponent(field, index);
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showKey ? flag && qrDetailsObj[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleQRModalBody = () => {
        return qrDetails ? (
            <>
                <div className="w-100 pb-2 border-bottom">
                    <div className="fs-14 fw-semibold mb-2">Enrollment Steps :</div>
                    <Row>
                        {enrolmentProcess?.map((qrDetail, ind) => {
                            return (
                                <Row className="mb-2" key={ind}>
                                    {toggleButton(qrDetail, ind)}
                                </Row>
                            );
                        })}

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Wi-Fi SSID
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-SSID'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-SSID'}>
                                    {`
									1. configured Wi-Fi network should be used during device provisioning and connect automatically.
									2. Skipping Wi-Fi isn't allowed.
									`}
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'wifiId'}
                                    id={'wifiId'}
                                    className={'form-control'}
                                    placeholder={'Enter Wi-Fi SSID'}
                                    type={'text'}
                                    onChange={(e) => setQrDetailObj({ ...qrDetailsObj, wifiId: e.target.value })}
                                    value={qrDetailsObj.wifiId}
                                />
                            </div>
                        </Col>

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Security Type
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-Security'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-Security'}>
                                    Security
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={7}
                            xl={7}
                            className={`${
                                qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none'
                                    ? 'mb-2'
                                    : ''
                            }`}
                        >
                            <div className="form-check pl-0 ">
                                <Select
                                    getOptionValue={(option) => option.label}
                                    getOptionLabel={(option) => option.label}
                                    id="securityType"
                                    name="securityType"
                                    classNamePrefix={'custom-select w-100'}
                                    options={qrSecurityOptions}
                                    placeholder="Select Security"
                                    onChange={(selectedOption) => setQrDetailObj({ ...qrDetailsObj, securityType: selectedOption })}
                                    value={qrDetailsObj.securityType || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>

                        {qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none' && (
                            <>
                                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                        Password
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={'toggleButton-Password'}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={'toggleButton-Password'}>
                                            Password
                                        </UncontrolledTooltip>
                                    </Label>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={7} xl={7}>
                                    <div className="form-check">
                                        <Input
                                            type={'text'}
                                            id={'password'}
                                            name={'password'}
                                            className={'form-control'}
                                            placeholder={'Enter password'}
                                            onChange={(e) => setQrDetailObj({ ...qrDetailsObj, password: e.target.value })}
                                            value={qrDetailsObj.password}
                                        />
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
                <div className="mt-3">
                    {AndroidPolicyQR?.map(
                        (field, index) => (field?.showOn ? handleShowOn(field, index) : true) && dynamicComponent(field, index)
                    )}
                </div>
            </>
        ) : (
            <Row className="d-flex align-items-center justify-content-center">
                {/* {tenantConfig?.ENABLE_SIGN_IN && <div className="mb-2">{generatingQrCode()}</div>} */}
                <div className="text-center">
                    <h5 className="text-primary">QR Code</h5>
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        {qrCodeString ? 'Please scan for the device enrollment' : 'QR code is being generated...'}
                    </Alert>
                </div>

                <div className={`d-flex align-items-center justify-content-center flex-wrap ${qrCodeString ? '' : 'mt-3'}`}>
                    {qrCodeString ? (
                        <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                    ) : (
                        <Spinner color="primary" className="spinner-style" />
                    )}
                </div>
            </Row>
        );
    };

    const qrHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Policy :</Label>
                <p className="p-0 m-0 mt-3 ms-2 fw-normal">{selectedRow?.original?.name}</p>
            </div>
        );
    };

    const handleBackToMainMenu = () => {
        let PolicyArray = policiesArr;
        let configArr = [];
        PolicyArray.forEach((ele) => {
            tenantConfig?.COMPANY_POLICY_ACCESS?.forEach((item) => {
                if (ele.id?.toUpperCase() === item) {
                    configArr.push(ele);
                }
            });
        });
        setPolicyCards(configArr);
        setPolicyType('');
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                {(policyType.childPolicies?.length > 0 || policyType.id === 'SA' || policyType.id === 'KL' || policyType.id === 'TL') && (
                    <div className="d-flex align-items-center cursor-pointer link-primary mb-2" onClick={handleBackToMainMenu}>
                        <i className="ri-arrow-left-line me-2" />
                        Back
                    </div>
                )}
                <Row className="align-items-center justify-content-evenly">
                    {policyCards?.length > 0 &&
                        policyCards.map((ele, index) => {
                            return (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-250 height-250 cursor-pointer ${
                                        ele.id === policyType.id ? 'border-2 border-primary' : ''
                                    }`}
                                    onClick={() => handlePolicyTypeClick(ele)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        {ele.id === policyType.id && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={ele.img} alt="" height={60} width={60} />
                                        </div>
                                        <p className="text-uppercase fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                    </CardBody>
                                </Card>
                            );
                        })}
                </Row>
            </div>
        );
    };

    const handleClickView = (row) => {
        history.push('/aepolicies/view/' + row.original.id + '/' + row.original.policyType);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setSearchVal(val);
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const verifyPassword = () => {
        if (tenantConfig.DEVICE_DEFAULT_PASSWORD === password)
            history.push('/aepolicies/edit/' + selectedRow.original.id + '/' + selectedRow.original.policyType);
        else toast.error(toastMessages.invalidPassword);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to delete Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polDelete} alt="deletepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
                {selectedRow?.original?.protectedPolicy && (
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                        <Input
                            name={'passwordPolicy'}
                            type={'text'}
                            className="w-50"
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password || ''}
                        />
                    </div>
                )}
            </div>
        );
    };

    const handlePasswordBody = () => {
        return (
            <Row>
                <Col sm={4} className="d-flex align-items-center">
                    <Label className="form-label m-0 p-0 d-flex align-items-center">
                        Enter Password <span className="text-danger">*</span>
                    </Label>
                </Col>
                <Col>
                    <div className="input-group">
                        <div className="w-100">
                            <input
                                id="policyPassword"
                                name="policyPassword"
                                className="form-control"
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password || ''}
                                // invalid={validation1?.touched['notes'] && validation1?.errors['notes'] ? true : false}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };
    const toggle = useCallback(async () => {
        setShowQRModal(!showQRModal);
    }, [showQRModal]);

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Device Policies" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Policies'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchVal={searchVal}
                        searchPlaceHolder={'Policy Code or Name'}
                        columns={policiesTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleQR={handleQR}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleAddNew={handleAddNew}
                        handleClickViewDetails={handleClickView}
                        handleClone={handleClone}
                        onClickSwitch={onClickSwitch}
                        handleAuditLog={handleAuditLog}
                        totalRecords={totalRecords}
                        handleEnrollmentToken={handleEnrollmentToken}
                        data={policies}
                        editor={priviliges.POLICY_EDITOR}
                        reader={priviliges.POLICY_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        hideDeleteMessage={true}
                        message={message()}
                        disabled={selectedRow?.original?.protectedPolicy ? password === '' || !password : false}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => {
                            setDeleteModal(false);
                            setPassword('');
                        }}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showCloneModal}
                        hideDeleteMessage={true}
                        message={handleCloneModalBody()}
                        onDeleteClick={validation.handleSubmit}
                        confirmText="Yes, Clone it"
                        hideDeleteIcon={true}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            validation.resetForm();
                        }}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={() => {
                            setShowQRModal(false);
                            setQrDetails(false);
                            setSelectedRow('');
                        }}
                        open={showQRModal || qrDetails}
                        handleCloseClick={() => {
                            setShowQRModal(false);
                            setQrDetails(false);
                            setSelectedRow('');
                            setQrDetailObj({
                                educationScreens: true,
                                systemApps: false,
                                allowOffline: false,
                                screenOn: false,
                                wifiId: undefined,
                                securityType: undefined,
                                password: undefined,
                                pin: undefined,
                                securityOptions: 'withoutLogin'
                            });
                        }}
                        OffcanvasModalID="Qr_details"
                        hideSaveButton={qrDetails ? false : true}
                        closeButtonBorder={true}
                        saveText={'Generate QR'}
                        handleOffcanvasBody={() => handleQRModalBody()}
                        modalClassName={'w-40'}
                        saveDisabled={qrDetailsObj?.securityOptions === 'withStatic' ? qrDetailsObj?.pin?.length !== 4 : false}
                        offcanvasHeader={`Policy : ${selectedRow?.original?.name}`}
                        handleSaveClick={() => handleQrConfirmation(selectedRow)}
                    />
                    {/* <CommonModal
                        size={'md'}
                        show={showQRModal || qrDetails}
                        disabled={false}
                        hideSaveButton={qrDetails ? false : true}
                        modalheader={qrHeader()}
                        onCloseClick={() => {
                            setShowQRModal(false);
                            setQrDetails(false);
                            setSelectedRow('');
                            setQrDetailObj({
                                educationScreens: true,
                                systemApps: false,
                                allowOffline: false,
                                screenOn: false,
                                wifiId: undefined,
                                securityType: undefined,
                                password: undefined
                            });
                            setSignInEnrollment('NOT REQUIRED');
                        }}
                        handleClick={() => handleQrConfirmation(selectedRow)}
                        handleModalBody={() => handleQRModalBody()}
                    /> */}
                    <CommonModal
                        size={'lg'}
                        disabled={!policyType?.id || policyType?.id === '' || policyType?.id === 'DD'}
                        cancelText={'Cancel'}
                        saveText={'Proceed'}
                        show={showPolicyModal}
                        modalheader={'Create New Policy'}
                        onCloseClick={() => {
                            setShowPolicyModal(false);
                            setPolicyType('');
                        }}
                        handleClick={() => {
                            history.push('/aepolicies/add/' + policyType.id);
                            setShowPolicyModal(false);
                        }}
                        handleModalBody={() => handlePolicyModalBody()}
                    />
                    <CommonModal
                        size={'md'}
                        disabled={!password || password === ''}
                        cancelText="Cancel"
                        saveText="Verify"
                        show={showPasswordModal}
                        modalheader="Verify Policy Edit"
                        onCloseClick={() => {
                            setShowPasswordModal(false);
                            setSelectedRow('');
                            setPassword('');
                        }}
                        handleClick={verifyPassword}
                        handleModalBody={handlePasswordBody}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Policies;
