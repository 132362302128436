import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import Loader from '../../../Components/Common/Loader';
import { getFormTypeAndRecordId, noCaseSensitiveSortBy } from '../../../Components/Common/Util';
import { TIMEFENCE_CONFIGURATION } from '../../../Components/constants/constants';
import RESTHOURS from '../../../assets/images/RESTHOURS.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from '../Actions';
import Assigning from '../Assigning';
import Actions from './Actions';

const AddTimeFence = () => {
    const urlconf = useEnv();
    const api = new APIClient();
    const history = useHistory();
    const [timeDifference, setTimeDifference] = useState('24hr 0min');
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    document.title = formType === 'add' ? 'Add Time fence' : 'Edit Time Fence';

    useEffect(() => {
        handlePromise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.id);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setGroupsData = async (deviceGroups, dataObj, groupsData, deviceGroupCodes) => {
        await deviceGroups.forEach(async (data) => {
            data.label = data.name;
            data.key = data.code;
            data.checked = deviceGroupCodes.includes(data.code) || dataObj.checked;
            groupsData.push(data);
            if (data.children) {
                let obj = await setGroupsData(data.children, data, groupsData, deviceGroupCodes);
                data.children = obj.deviceGroups;
                groupsData = [...obj.groupsData];
            }
        });
        return { deviceGroups: deviceGroups, groupsData: groupsData };
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupsObj.checked) groupVal.checked = true;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const handlePromise = () => {
        setLoading(true);
        const groupsPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            api.get(url.DEVICE_GROUPS, params, domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject('Groups failed.'));
        });

        const policyPromise = new Promise((resolve, reject) => {
            api.get(url.POLICIES, { page: 1, size: 1000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data?.length > 0) resolve(resp.data);
                    else reject('Policies failed.');
                })
                .catch((err) => reject('Policies failed.'));
        });

        const fencePromise = new Promise((resolve, reject) => {
            if (recordID)
                api.get(url.DEVICE_AUTOMATION + '/' + recordID)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) resolve(resp.data);
                        else reject('Fence failed');
                    })
                    .catch((err) => reject('Fence failed'));
            else reject('Fence failed');
        });

        Promise.allSettled([policyPromise, groupsPromise, fencePromise])
            .then(async (result) => {
                let deviceGroups = [];
                if (result[0].status === 'fulfilled') {
                    setPolicies(result[0].value);
                }
                if (result[2].status === 'fulfilled') {
                    deviceGroups = result[2]?.value?.assignTo === 'GROUP' ? result[2]?.value?.assign?.split(',') || [] : [];
                    let slots = result[2].value?.config?.slots?.map((slot) => {
                        return {
                            ...slot,
                            action: handleAction(slot),
                            policy: handlePolicy(result[0].value || [], slot)
                        };
                    });
                    let values = {
                        name: result[2].value.name,
                        timeSlots: slots,
                        status: result[2].value.status,
                        remaining: {
                            action: handleAction(result[2]?.value?.config?.remainingHours),
                            policy: handlePolicy(result[0].value || [], result[2]?.value?.config?.remainingHours)
                        }
                    };
                    setFormValues({ ...values });
                }
                if (result[1]?.status === 'fulfilled') {
                    let superParents = [];
                    let flatGroupArr = [];
                    if (result[1]?.value) {
                        let groupsData = [];
                        await result[1]?.value.forEach(async (data) => {
                            data.label = data.name;
                            data.key = data.code;
                            data.checked = deviceGroups.includes(data.code);
                            groupsData.push(data);
                            if (data.children) {
                                let obj = await setGroupsData(data.children, data, groupsData, deviceGroups);
                                data.children = obj.deviceGroups;
                                groupsData = [...obj.groupsData];
                            }
                        });
                        flatGroupArr = groupsData;
                    }
                    setFlatGroups(flatGroupArr);
                    setSelectedGroups(flatGroupArr.filter((group) => group.checked)?.map((group) => group.code));
                    superParents = noCaseSensitiveSortBy(result[1]?.value, 'label');
                    setGroups(superParents);
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    const handleAction = (slot) => {
        return [
            { label: 'No Action', value: 'NO_ACTION' },
            { label: 'Choose Policy', value: 'PolicySwitch' },
            { label: 'Disable Device', value: 'DISABLED' }
        ]?.find((action) => action.value === slot.action);
    };

    const handlePolicy = (policiesArr, slot) => {
        return slot.policy ? policiesArr.find((policy) => policy.code === slot.policy) : undefined;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...formValues
        },
        onSubmit: (values) => {}
    });

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    useEffect(() => {
        handleTiemDifference(validation.values.timeSlots);
    }, [validation.values.timeSlots]);

    const handleTiemDifference = (timeSlots) => {
        const timeToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };
        let totalMinutes = timeSlots?.reduce((total, { startTime, endTime }) => {
            const startMinutes = timeToMinutes(startTime);
            const endMinutes = timeToMinutes(endTime);
            return total + (endMinutes - startMinutes);
        }, 0);
        totalMinutes = 24 * 60 - totalMinutes;
        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        if (totalHours || remainingMinutes) {
            setTimeDifference(`${totalHours}hrs ${remainingMinutes}min`);
        }
    };

    const addMinutes = (date, minute) => {
        let actual = new Date(date);
        let addMin = parseInt(actual.getMinutes()) + minute;
        actual.setMinutes(addMin);
        return actual;
    };

    const stepperData = [
        {
            title: 'Configuration',
            icon: 1,
            disabled: false,
            renderTab: (
                <Row>
                    {TIMEFENCE_CONFIGURATION?.map((field, index) => (
                        <DynamicComponents
                            field={field}
                            index={index}
                            formType={''}
                            validation={validation}
                            handleKeyDown={handleKeyDown}
                        />
                    ))}
                    <Col sm={6} className="d-flex mt-3">
                        <div className="d-flex justify-content-center align-items-center p-1">
                            <img src={RESTHOURS} alt="img" height={30} />
                        </div>
                        <div>
                            <div className="fw-semibold">Total Remaining Hours</div>
                            <div className="fs-10 text-muted">
                                Time Fence Rest Hours restrict device usage during non-work periods to enhance security and enforce
                                work-life balance.
                            </div>
                        </div>
                        <div className="fw-bold white-space-nowrap d-flex justify-content-center align-items-center px-1">
                            {timeDifference}
                        </div>
                    </Col>
                </Row>
            )
        },
        {
            title: 'Actions',
            icon: 2,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <Actions
                            policies={policies}
                            array={validation.values.timeSlots}
                            key="timeSlots"
                            formType={formType}
                            validation={validation}
                        />
                    </Col>
                </Row>
            )
        },
        {
            title: 'Assigning',
            icon: 3,
            disabled: false,
            renderTab: (
                <Row>
                    <Col md={6}>
                        <div className="fw-semibold mt-3">
                            Assign Group<span className="text-danger px-1">*</span>
                        </div>
                        <div className="text-muted mb-3 fs-11">Please specify the group to which the configurations should apply</div>
                        <Assigning
                            groups={groups}
                            flatGroups={flatGroups}
                            selectedGroups={selectedGroups}
                            setGroups={setGroups}
                            setSelectedGroups={setSelectedGroups}
                            setFlatGroups={setFlatGroups}
                            mode={formType}
                        />
                    </Col>
                </Row>
            )
        }
    ];

    const handleSave = () => {
        let slots = [];
        validation.values.timeSlots.forEach((slot) => {
            slots.push({ startTime: slot.startTime, endTime: slot.endTime, action: slot.action?.value, policy: slot?.policy?.code });
        });
        let dataObj = {
            name: validation.values?.name,
            type: 'TIMEFENCE',
            config: {
                slots: slots?.length > 0 ? slots : [],
                remainingHours: { action: validation.values?.remaining?.action?.value, policy: validation.values?.remaining?.policy?.code }
            },
            assign: selectedGroups?.join(','),
            assignTo: 'GROUP',
            status: formValues.status ? formValues.status : 'ACTIVE'
        };
        let apiService;
        if (recordID) apiService = api.update(url.TIME_FENCE + '/' + recordID, dataObj);
        else apiService = api.create(url.TIME_FENCE, dataObj);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success')
                    toast.success(recordID ? toastMessages.policyFenceUpdated : toastMessages.policyFenceCreated);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                history.push('/timefence');
            });
    };

    const handleDisableSave = () => {
        let flag = false;
        validation.values.timeSlots?.forEach((slot) => {
            if (flag) return;
            flag =
                !slot.startTime ||
                !slot.endTime ||
                !slot.action?.value ||
                (slot.action?.value === 'PolicySwitch' ? !slot.policy?.code : false);
        });
        return (
            !validation.values.name ||
            selectedGroups?.length === 0 ||
            !validation.values?.remaining?.action?.value ||
            (validation.values?.remaining?.action?.value === 'PolicySwitch' ? !validation.values?.remaining?.policy?.code : false) ||
            !validation.values?.timeSlots?.length ||
            flag
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${formType === 'add' ? 'Add Time fence' : 'Edit Time Fence'}`}
                        history={history}
                        showBack={true}
                        backLink={'timefence'}
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">Time-Fence</div>
                            <div className="fs-13">
                                Time fence enables admin to enforce specific rules or restrictions on devices based on predefined time
                                intervals.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name={'name'}
                                            id={'name'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['name'] || ''}
                                            invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['name'] ? (
                                    <span className="ps-1">{validation?.values['name']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['name'] && validation.errors['name'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomStepperComponent
                                cancelBtn={true}
                                stepperData={stepperData}
                                handleDisableSave={handleDisableSave()}
                                enableSaveBtn={true}
                                handleSave={handleSave}
                                routeText={'timefence'}
                                formType={formType}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddTimeFence;
