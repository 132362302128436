/* eslint-disable max-depth */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-nested-callbacks */
/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFormTypeAndRecordId, profileTypeLabel } from '../../../Components/Common/Util';
import Loader from '../../../Components/Common/Loader';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useFormik } from 'formik';
import { windowsPolicySchema } from './PolicySchema';
import {
    AGENT_APP_NAME,
    DynamicComponents,
    InputComponent,
    customConfig,
    enableOnCondition,
    getWindowsPolicyInitialValues
} from './WindowsPolicyComponents';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import toastMessages from '../../../common/messages/toastMessages';
import DeleteModal from '../../../Components/Common/DeleteModal';

const AddWindowsPolicy = () => {
    const api = new APIClient();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [policyDetails, setPolicyDetails] = useState([...windowsPolicySchema]);
    const [selected, setSelected] = useState('');
    const [formInitialValues, setFormInitialValues] = useState(() => getWindowsPolicyInitialValues());
    const [isConfigured, setIsConfigured] = useState({});
    const [isShowCardData, setIsShowCardData] = useState(false);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    const [code, setCode] = useState('');
    const [apps, setApps] = useState([]);
    const [confirmApps, setConfirmApps] = useState([]);
    const [deletingSchema, setDeletingSchema] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const urlconf = useEnv();

    let formType = formTypeAndId['formType'];
    let polType = formTypeAndId['queryType'];
    let recordID = formTypeAndId['recordID'];

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleBack = () => {
        validation.setValues({ ...confirmValidation.values });
        if (selected?.label === 'Applications ') {
            setApps(confirmApps);
        }
        setIsShowCardData(false);
        setSelected('');
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formInitialValues },
        validationSchema: Yup.object({
            policyName: Yup.string().required('Please enter your policy name'),
            MinimumPasswordAge: Yup.number()
                .min(0, 'Should be between 0-998')
                .max(998, 'Should be between 0-998')
                .test('is-less', 'Should be less than Maximum passcode age', function (value) {
                    return value < this.parent.MaximumPasswordAge;
                }),
            MaximumPasswordAge: Yup.number()
                .min(1, 'Should be between 1-999')
                .max(999, 'Should be between 1-999')
                .test('is-greater', 'Should be greater than Minimum passcode age', function (value) {
                    return this.parent.MinimumPasswordAge < value;
                }),
            MaxInactivityTimeDeviceLock: Yup.number().min(0, 'Should be between 0-999').max(999, 'Should be between 0-999'),
            DevicePasswordHistory: Yup.number().min(0, 'Should be between 0-50').max(50, 'Should be between 0-50'),
            MaxDevicePasswordFailedAttempts: Yup.number().min(0, 'Should be between 0-999').max(999, 'Should be between 0-999'),
            EnergySaverBatteryThresholdOnBattery: Yup.number().min(0, 'Should be between 0-100').max(100, 'Should be between 0-100'),
            EnergySaverBatteryThresholdPluggedIn: Yup.number().min(0, 'Should be between 0-100').max(100, 'Should be between 0-100'),
            DODelayBackgroundDownloadFromHttp: Yup.number()
                .min(0, 'Should be between 0-4294967295')
                .max(4294967295, 'Should be between 0-4294967295'),
            DODelayForegroundDownloadFromHttp: Yup.number()
                .min(0, 'Should be between 0-4294967295')
                .max(4294967295, 'Should be between 0-4294967295'),
            DOMinRAMAllowedToPeer: Yup.number().min(0, 'Should be between 0-100000').max(100000, 'Should be between 0-100000'),
            DOMinDiskSizeAllowedToPeer: Yup.number().min(0, 'Should be between 0-100000').max(100000, 'Should be between 0-100000'),
            DOMinFileSizeToCache: Yup.number().min(0, 'Should be between 0-100000').max(100000, 'Should be between 0-100000'),
            DOMinBatteryPercentageAllowedToUpload: Yup.number().min(0, 'Should be between 0-100').max(100, 'Should be between 0-100'),
            DOMaxCacheAge: Yup.number().min(0, 'Should be between 0-4294967295').max(4294967295, 'Should be between 0-4294967295')
        }),
        onSubmit: (values) => {
            let payloadObj = {
                name: values?.policyName,
                policyType: polType ? polType : 'FM',
                apps: apps
                    .filter((newObj) => newObj?.isChecked === true)
                    .map((obj) => {
                        return { productId: obj.productid };
                    }),
                policy: {}
            };

            Object.entries(values).forEach((arr) => {
                let key = arr[0];
                let val = arr[1];
                windowsPolicySchema.forEach((cardObj) => {
                    if (isConfigured[cardObj.label]) {
                        cardObj.fields.forEach((obj) => {
                            if (obj?.inputType === 'restrictions') {
                                obj.fields.forEach((restrictCardObj) => {
                                    restrictCardObj.fields.forEach((restrictObj) => {
                                        if (restrictObj?.myId === key) {
                                            if (val !== '' && enableOnCondition(restrictObj, validation)) {
                                                payloadObj.policy[restrictObj.parentId] = {
                                                    ...payloadObj.policy[restrictObj.parentId],
                                                    [key]: val
                                                };
                                            }
                                        }
                                    });
                                });
                            } else if (obj?.inputType === 'array' && obj?.value === key) {
                                payloadObj.policy[key] = val;
                            } else if (obj?.myId === key) {
                                if (obj?.parentId === 'EMAIL2' && enableOnCondition(obj, validation)) {
                                    payloadObj.policy[obj.parentId] = {
                                        ...payloadObj.policy[obj.parentId],
                                        [key]: val
                                    };
                                } else if (val !== '' && enableOnCondition(obj, validation)) {
                                    if (!customConfig(obj)) {
                                        payloadObj.policy[obj.parentId] = {
                                            ...payloadObj.policy[obj.parentId],
                                            [key]: val
                                        };
                                    } else {
                                        payloadObj.policy[obj.parentId][obj.subParentId] = {
                                            ...payloadObj.policy[obj.parentId][obj.subParentId],
                                            [key]: val
                                        };
                                    }
                                }
                            }
                        });
                    }
                });
            });
            createNdUpdate(payloadObj);
        }
    });

    const handlePolicyDetail = (profileObj, index) => {
        setSelected(profileObj);
        setIsShowCardData(true);
    };

    const confirmValidation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formInitialValues },
        onSubmit: () => {
            confirmValidation.setValues({ ...validation.values });
            const configured = JSON.parse(JSON.stringify(isConfigured));
            if (selected?.label === 'Applications ') {
                if (apps.filter((obj) => obj?.isChecked === true).length > 0) configured[selected?.label] = true;
                else configured[selected?.label] = false;
                setConfirmApps(apps);
            } else configured[selected?.label] = true;
            setSelected('');
            setIsConfigured(configured);
            setIsShowCardData(false);
        }
    });

    useEffect(() => {
        confirmValidation.setValues({ ...confirmValidation.values, policyName: validation.values.policyName });
    }, [validation.values.policyName]);

    const createNdUpdate = (obj) => {
        let apiService;

        if (formType === 'edit') {
            obj['code'] = code;
            apiService = api.patch(url.POLICIES + '/' + recordID, obj, false, domains.WINDOWS_V1);
        } else apiService = api.create(url.POLICIES, obj, false, domains.WINDOWS_V1);

        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.windowsPolicyUpdated : toastMessages.windowsPolicyCreated);
                    history.push('/windowspolicies');
                }
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const policyPromise = new Promise((resolve, reject) => {
            if (recordID) {
                api.get(url.POLICIES + '/' + recordID, '', domains.WINDOWS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.data) {
                            resolve(resp.data);
                        }
                        reject('Policy Failed.');
                    })
                    .catch((err) => reject('Policy Failed'));
            } else resolve({});
        });

        const applicationsPromise = new Promise((resolve, reject) => {
            api.get(url.WINDOWS_APPS, { page: 1, size: 1000 }, domains.WINDOWS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data?.length > 0) {
                        resolve(resp.data);
                    }
                    reject('Applications Failed.');
                })
                .catch((err) => {
                    reject('Applications Failed.');
                });
        });

        Promise.allSettled([applicationsPromise, policyPromise])
            .then(async (result) => {
                let initValues = {};
                if (result[0].status === 'fulfilled') {
                    let defaultApps = result[0].value?.map((obj) => {
                        if (obj.name === AGENT_APP_NAME) {
                            obj.isChecked = true;
                        }
                        return obj;
                    });
                    setApps(defaultApps);
                    setConfirmApps(defaultApps);
                    setIsConfigured({
                        ...isConfigured,
                        'Applications ': defaultApps.filter((newObj) => newObj?.isChecked === true).length === 0 ? false : true
                    });
                }
                if (result[1].status === 'fulfilled') {
                    let values = getWindowsPolicyInitialValues();
                    const configured = JSON.parse(JSON.stringify(isConfigured));
                    Object.entries(result[1].value?.policy).forEach((arr) => {
                        let parentKey = arr[0];
                        let parentValueObj = arr[1];
                        if (typeof parentValueObj === 'object' && !Array.isArray(parentValueObj) && parentValueObj !== null)
                            Object.entries(parentValueObj).forEach((childArr) => {
                                let childKey = childArr[0];
                                let childVal = childArr[1];
                                windowsPolicySchema.forEach((cardObj) => {
                                    cardObj.fields.forEach((obj) => {
                                        if (obj?.inputType === 'restrictions') {
                                            obj.fields.forEach((restrictionCardObj) => {
                                                restrictionCardObj.fields.forEach((restrictionObj) => {
                                                    if (restrictionObj.myId === childKey && restrictionObj.parentId === parentKey) {
                                                        values = { ...values, [childKey]: childVal };
                                                        configured[cardObj.label] = true;
                                                    }
                                                });
                                            });
                                        } else if (obj?.inputType === 'array') {
                                            values = { ...values, [obj.value]: result[1].value?.policy[obj.value] };
                                            if (result[1].value?.policy[obj.value] && result[1].value?.policy[obj.value]?.length > 0) {
                                                configured[cardObj.label] = true;
                                            }
                                        } else if (obj.myId === childKey && obj.parentId === parentKey) {
                                            values = { ...values, [childKey]: childVal };
                                            configured[cardObj.label] = true;
                                        } else if (
                                            customConfig(obj) &&
                                            obj?.parentId === parentKey &&
                                            obj?.subParentId === childKey &&
                                            obj.myId in childVal
                                        ) {
                                            values = { ...values, [obj.myId]: childVal[obj.myId] };
                                        }
                                    });
                                });
                            });
                    });
                    if ('WlanXml' in result[1].value.policy) {
                        values = { ...values, WlanXml: result[1].value?.policy['WlanXml'] };
                        configured['Wi-Fi'] = true;
                    }
                    initValues = { ...values, policyName: result?.[1]?.value?.name || '' };
                    if (result[1].value?.apps?.length > 0) {
                        setApps((prev) => {
                            let updatedApps = prev.map((app) => {
                                let newApp = { ...app };
                                result[1].value?.apps.forEach((obj) => {
                                    if (app.productid === obj.productId) {
                                        newApp = { ...newApp, isChecked: true };
                                    }
                                });
                                return newApp;
                            });
                            setConfirmApps(updatedApps);
                            return updatedApps;
                        });
                        configured['Applications '] = true;
                    }

                    setCode(result[1].value?.code);
                    setFormInitialValues(initValues);
                    setIsConfigured(configured);
                    setLoading(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const checkConfirmDisabled = () => {
        let isWifi = false;
        let isApplication = false;
        let keysToCheck = [];
        let mandatoryKeys = [];
        selected.fields.forEach((field) => {
            if (field.inputType === 'restrictions') {
                field.fields.forEach((restrictCardObj) => {
                    restrictCardObj.fields.forEach((restrictObj) => {
                        keysToCheck.push(restrictObj.myId);
                    });
                });
            } else if (field.inputType === 'array') {
                isWifi = true;
            } else if (field.inputType === 'applications') {
                isApplication = true;
            } else {
                keysToCheck.push(field.myId);
                if (enableOnCondition(field, validation) && field.mandatory === true) {
                    mandatoryKeys.push(field.myId);
                }
            }
        });
        let keysWithErrors = Object.keys(validation.errors);

        let flag = keysToCheck.some((key) => keysWithErrors.includes(key));

        if (mandatoryKeys.length > 0) {
            flag = flag || mandatoryKeys.some((key) => validation.values[key] === '');
        }

        if (isApplication) {
            flag = flag || apps.filter((obj) => obj?.isChecked === true).length === 0;
        }
        if (isWifi) {
            flag =
                flag || (validation.values[selected.fields[0]?.value] ? validation.values[selected.fields[0]?.value]?.length === 0 : true);
        }
        return flag;
    };

    const onAppCheck = (isChecked, ind) => {
        let updatedApps = [...apps];
        updatedApps[ind] = { ...updatedApps[ind], isChecked: !isChecked };
        setApps(updatedApps);
    };

    const handleDeleteConfirm = () => {
        let schema = deletingSchema;
        if (schema?.label === 'Applications ') {
            setApps((prev) => {
                let updatedApps = prev.map((app) => {
                    let newApp = { ...app };
                    if (newApp.name === AGENT_APP_NAME) {
                        newApp = { ...newApp, isChecked: true };
                    } else {
                        newApp = { ...newApp, isChecked: false };
                    }
                    return newApp;
                });
                setConfirmApps(updatedApps);
                setIsConfigured({
                    ...isConfigured,
                    [schema.label]: updatedApps.filter((newObj) => newObj?.isChecked === true).length === 0 ? false : true
                });
                return updatedApps;
            });
        } else {
            let values = { ...validation.values };
            schema?.fields.forEach((obj) => {
                if (obj?.inputType === 'restrictions') {
                    obj?.fields?.forEach((restrictionCardObj) => {
                        restrictionCardObj?.fields?.forEach((restrictionObj) => {
                            values = { ...values, [restrictionObj?.myId]: restrictionObj?.defaultValue };
                        });
                    });
                } else if (obj?.inputType === 'array') {
                    values = { ...values, [obj?.value]: [] };
                } else {
                    values = { ...values, [obj?.myId]: obj?.defaultValue };
                }
            });
            validation.setValues({ ...values });
            confirmValidation.setValues({ ...values });
            setIsConfigured({ ...isConfigured, [schema.label]: false });
        }
        setDeletingSchema('');
        setDeleteModal(false);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to clear the configuration for {deletingSchema.label}?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={deletingSchema.image} alt="deleteGroup" width={30} height={30} />
                </div>
            </div>
        );
    };

    const handleDeleteConfig = (e, schema) => {
        e.stopPropagation();
        setDeletingSchema(schema);
        setDeleteModal(true);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={isShowCardData ? selected.label : `${formType?.charAt(0)?.toUpperCase() + formType?.slice(1)} Policy`}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        customBack={isShowCardData ? true : false}
                        backLink={!isShowCardData ? 'windowspolicies' : handleBack}
                        label={selected.label}
                    />
                    <Card>
                        <CardHeader>
                            {selected === '' ? (
                                <Row>
                                    <Col
                                        lg={6}
                                        className="d-flex flex-column gap-2"
                                        // className='border-end'
                                    >
                                        <Row className="pt-3">
                                            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                                <Label className="form-label d-flex align-items-center">
                                                    Policy Type <span className="ps-1">:</span>
                                                </Label>
                                            </Col>
                                            <Col>
                                                <div className="input-group fw-medium">{profileTypeLabel(polType)}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <InputComponent
                                                field={{
                                                    label: 'Policy Name',
                                                    value: 'policyName',
                                                    helpText: 'Enter policy name',
                                                    type: 'text',
                                                    exclude: true,
                                                    mandatory: true,
                                                    codeBased: false,
                                                    formType: formType
                                                }}
                                                formType={formType}
                                                handleKeyDown={handleKeyDown}
                                                index={'index'}
                                                validation={validation}
                                            />
                                        </Row>
                                    </Col>
                                    {/* <Col lg={6} className="ps-8 d-flex flex-column gap-2">
                                    <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold ">
                                        <div className="fs-14 ">Configuration Type</div>
                                    </div>
                                    <div className="font-size-12 fw-medium ">
                                        Based on configuration type you can select and configure the details
                                    </div>
                                    <Row>
                                        <Col xs={7} md={7} sm={7} lg={7} xl={7}>
                                            <Select
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.label}
                                                options={[]}
                                                placeholder={'Select Configuration'}
                                                noOptionsMessage={() => 'No data found'}
                                            />
                                        </Col>
                                    </Row>
                                </Col> */}
                                </Row>
                            ) : (
                                <div>
                                    <div className="fw-semibold fs-16">{selected?.label}</div>
                                    <div className="text-muted">{selected?.helpText}</div>
                                </div>
                            )}
                        </CardHeader>
                        <CardBody className={`${!validation.values.policyName ? 'mask pe-none' : ''}`}>
                            {!selected && (
                                <Row>
                                    {policyDetails?.map((schema, index) =>
                                        (formType === 'view' && isConfigured[schema.label]) || formType !== 'view' ? (
                                            <Col xs={6} sm={3} md={4} lg={''} xl={''} className="min-width-per-20 flex-grow-0" key={index}>
                                                <Card
                                                    className="min-height-150 shadow-card cursor-pointer"
                                                    onClick={() => handlePolicyDetail(schema, index)}
                                                >
                                                    <CardBody className="d-flex align-items-center justify-content-center">
                                                        {isConfigured[schema.label] && formType !== 'view' && (
                                                            <span
                                                                className="text-danger fs-12 text-decoration-underline curosr-pointer position-absolute end-10 top-5 fw-medium"
                                                                onClick={(e) => handleDeleteConfig(e, schema)}
                                                            >
                                                                Clear
                                                            </span>
                                                        )}
                                                        <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                                                            <img src={schema.image} alt={schema.label} height={30} width={30} />
                                                            <div className="fs-14 fw-medium text-center">{schema.label}</div>
                                                            <div
                                                                className={`${
                                                                    isConfigured[schema.label] ? 'text-success' : 'text-muted'
                                                                } d-flex align-items-center gap-2`}
                                                            >
                                                                <i
                                                                    className={`${
                                                                        isConfigured[schema.label]
                                                                            ? 'ri-checkbox-circle-fill'
                                                                            : 'ri-information-2-line'
                                                                    }`}
                                                                />
                                                                <span className="fs-12 text-center">
                                                                    {isConfigured[schema.label] ? 'Configured' : 'Not Configured'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ) : null
                                    )}
                                </Row>
                            )}
                            {selected && selected !== '' && (
                                <Row sm={12} className="m-0 px-0 gap-1">
                                    {selected?.fields?.map((field, index) => {
                                        return (
                                            enableOnCondition(field, validation) && (
                                                <Row className={'m-0 p-0 gap-1'} key={index}>
                                                    <DynamicComponents
                                                        field={field}
                                                        index={index}
                                                        formType={formType}
                                                        validation={validation}
                                                        apps={apps}
                                                        handleKeyDown={handleKeyDown}
                                                        onAppCheck={onAppCheck}
                                                        setLoading={setLoading}
                                                    />
                                                </Row>
                                            )
                                        );
                                    })}
                                </Row>
                            )}
                        </CardBody>
                        <CardFooter>
                            {!selected ? (
                                <div className="gap-2 d-flex justify-content-end">
                                    <Button type="button" className="btn btn-light" onClick={() => history.push('/windowspolicies')}>
                                        Cancel
                                    </Button>
                                    {formType !== 'view' && (
                                        <Button
                                            size="md"
                                            type="button"
                                            color="primary"
                                            disabled={
                                                !validation.values.policyName ||
                                                validation.values.policyName === '' ||
                                                Object.values(isConfigured).filter((bol) => bol === true).length === 0
                                            }
                                            onClick={validation.handleSubmit}
                                        >
                                            {formType === 'edit' ? 'Update' : 'Save'}
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                <div className="gap-2 d-flex justify-content-end">
                                    <Button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() => {
                                            validation.setValues({ ...confirmValidation.values });
                                            if (selected?.label === 'Applications ') {
                                                setApps(confirmApps);
                                            }
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                            setSelected('');
                                            setIsShowCardData(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {formType !== 'view' && (
                                        <Button
                                            size="md"
                                            disabled={checkConfirmDisabled()}
                                            type="button"
                                            color="primary"
                                            onClick={confirmValidation.handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>
                            )}
                        </CardFooter>
                    </Card>
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={deleteModal}
                    hideDeleteMessage={true}
                    message={message()}
                    confirmText={'Yes, Delete it!'}
                    onDeleteClick={handleDeleteConfirm}
                    onCloseClick={() => {
                        setDeletingSchema('');
                        setDeleteModal(false);
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AddWindowsPolicy;
