/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
// Import Data
import navdata from '../LayoutMenuData';
// i18n
import { withTranslation } from 'react-i18next';
import { AuthUser, TenantConfig } from '../../Components/Common/Util';

const VerticalLayout = (props) => {
    const navData = navdata().props.children;
    let menuItems = [];
    let splitMenuItems = [];
    let menuSplitContainer = 12;
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';
    let roles = user?.data?.privileges;
    let tenant = TenantConfig();
    let menuItemsList = [];

    const checkPrivilege = (subItem) => {
        if (subItem.showFor) {
            return subItem.showFor?.includes(user.data?.email);
        }
        if (subItem.multipleConditions) {
            let found = true;
            subItem.key?.forEach((key) => (found = found && !!tenant?.[key]));
            return found;
        } else if (subItem.doNotCheck) return true;
        else if (tenant?.[subItem.key]) return true;
        else return false;
    };

    navData?.forEach((value) => {
        if (value.subItems) {
            let subMenu = [];
            value?.subItems?.forEach((subItem) => {
                let checkValid = checkPrivilege(subItem);
                if (checkValid) {
                    if (subItem.reader || subItem.editor) {
                        roles?.forEach((role) => {
                            if (role === subItem.reader || role === subItem.editor) {
                                let item = subMenu.findIndex((menu) => menu.link === subItem.link);
                                if (item === -1) subMenu.push(subItem);
                            }
                        });
                    } else subMenu.push(subItem);
                }
            });
            if (subMenu.length > 0) {
                value.subItems = subMenu;
                menuItemsList.push(value);
            }
        } else {
            if (value.reader || value.editor) {
                let checkValid = checkPrivilege(value);
                if (checkValid)
                    roles?.forEach((role) => {
                        if (role === value.reader || role === value.editor) {
                            let item = menuItemsList.findIndex((menu) => menu.link === value.link);
                            if (item === -1) menuItemsList.push(value);
                        }
                    });
            } else {
                let checkValid = checkPrivilege(value);
                if (checkValid) menuItemsList.push(value);
            }
        }
    });
    menuItemsList.forEach(function (value, key) {
        if (value['isHeader']) {
            menuSplitContainer++;
        }
        if (key >= menuSplitContainer) {
            let val = value;
            val.childItems = value.subItems;
            val.isChildItem = value.subItems ? true : false;
            delete val.subItems;
            splitMenuItems.push(val);
        } else {
            menuItems.push(value);
        }
    });

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + window.location.href;
            const ul = document.getElementById('navbar-nav');
            const items = ul.getElementsByTagName('a');
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);
            let matchingMenuItem = itemsArray.find((x) => {
                let routeArr = x.href?.split('/').filter((v) => v !== '');
                return pathName.includes(routeArr[3]);
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        if (props.layoutType === 'vertical') {
            initMenu();
        }
    }, [window.location.href, props.layoutType]);

    function activateParentDropdown(item) {
        item.classList.add('active');
        let parentCollapseDiv = item.closest('.collapse.menu-dropdown');

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement.children[0].classList.add('active');
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
                if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling)
                    parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.classList.add('active');
                if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.closest('.collapse')) {
                    parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.closest('.collapse').classList.add('show');
                    parentCollapseDiv.parentElement
                        .closest('.collapse')
                        .previousElementSibling.closest('.collapse')
                        .previousElementSibling.classList.add('active');
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains('active'));

        actiItems.forEach((item) => {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', false);
            }
            item.classList.remove('active');
        });
    };

    return (
        <React.Fragment>
            {/* menu Items */}
            {(menuItems || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {item['isHeader'] ? (
                            <li className="menu-title">
                                <span data-key="t-menu">{props.t(item.label)} </span>
                            </li>
                        ) : item.subItems ? (
                            <li className="nav-item">
                                <Link
                                    onClick={item.click}
                                    className="nav-link menu-link"
                                    to={item.link ? item.link : '/#'}
                                    data-bs-toggle="collapse"
                                >
                                    <i className={item.icon}></i>
                                    <span data-key="t-apps">{props.t(item.label)}</span>
                                    {item.badgeName ? (
                                        <span className={'badge badge-pill bg-' + item.badgeColor} data-key="t-new">
                                            {item.badgeName}
                                        </span>
                                    ) : null}
                                </Link>
                                <Collapse className="menu-dropdown" isOpen={item.stateVariables} id="sidebarApps">
                                    <ul className="nav nav-sm flex-column test">
                                        {/* subItms  */}
                                        {item.subItems &&
                                            (item.subItems || []).map((subItem, index) => (
                                                <React.Fragment key={index}>
                                                    {!subItem.isChildItem ? (
                                                        <li className="nav-item">
                                                            <Link to={subItem.link ? subItem.link : '/#'} className="nav-link">
                                                                <i className={subItem.icon}></i>
                                                                {props.t(subItem.label)}
                                                                {subItem.badgeName ? (
                                                                    <span
                                                                        className={'badge badge-pill bg-' + subItem.badgeColor}
                                                                        data-key="t-new"
                                                                    >
                                                                        {subItem.badgeName}
                                                                    </span>
                                                                ) : null}
                                                            </Link>
                                                        </li>
                                                    ) : (
                                                        <li className="nav-item">
                                                            <Link
                                                                onClick={subItem.click}
                                                                className="nav-link"
                                                                to="/#"
                                                                data-bs-toggle="collapse"
                                                            >
                                                                <i className={subItem.icon}></i>
                                                                {props.t(subItem.label)}
                                                            </Link>
                                                            <Collapse
                                                                className="menu-dropdown"
                                                                isOpen={subItem.stateVariables}
                                                                id="sidebarEcommerce"
                                                            >
                                                                <ul className="nav nav-sm flex-column">
                                                                    {/* child subItms  */}
                                                                    {subItem.childItems &&
                                                                        (subItem.childItems || []).map((childItem, inde) => (
                                                                            <React.Fragment key={inde}>
                                                                                {!childItem.childItems ? (
                                                                                    <li className="nav-item">
                                                                                        <Link
                                                                                            to={childItem.link ? childItem.link : '/#'}
                                                                                            className="nav-link"
                                                                                        >
                                                                                            <i className={childItem.icon}></i>
                                                                                            {props.t(childItem.label)}
                                                                                        </Link>
                                                                                    </li>
                                                                                ) : (
                                                                                    <li className="nav-item">
                                                                                        <Link
                                                                                            to="/#"
                                                                                            className="nav-link"
                                                                                            onClick={childItem.click}
                                                                                            data-bs-toggle="collapse"
                                                                                        >
                                                                                            <i className={childItem.icon}></i>
                                                                                            {props.t(childItem.label)}
                                                                                            <span
                                                                                                className="badge badge-pill bg-danger"
                                                                                                data-key="t-new"
                                                                                            >
                                                                                                New
                                                                                            </span>
                                                                                        </Link>
                                                                                        <Collapse
                                                                                            className="menu-dropdown"
                                                                                            isOpen={childItem.stateVariables}
                                                                                            id="sidebaremailTemplates"
                                                                                        >
                                                                                            <ul className="nav nav-sm flex-column">
                                                                                                {childItem.childItems.map(
                                                                                                    (subChildItem, ind) => (
                                                                                                        <li className="nav-item" key={ind}>
                                                                                                            <Link
                                                                                                                to={subChildItem.link}
                                                                                                                className="nav-link"
                                                                                                                data-key="t-basic-action"
                                                                                                            >
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        subChildItem.icon
                                                                                                                    }
                                                                                                                ></i>
                                                                                                                {props.t(
                                                                                                                    subChildItem.label
                                                                                                                )}
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    )
                                                                                                )}
                                                                                            </ul>
                                                                                        </Collapse>
                                                                                    </li>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                </ul>
                                                            </Collapse>
                                                        </li>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </ul>
                                </Collapse>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to={item.link ? item.link : '/#'}>
                                    <i className={item.icon}></i>
                                    <span>{props.t(item.label)}</span>
                                    {item.badgeName ? (
                                        <span className={'badge badge-pill bg-' + item.badgeColor} data-key="t-new">
                                            {item.badgeName}
                                        </span>
                                    ) : null}
                                </Link>
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};

export default withRouter(withTranslation()(VerticalLayout));
