import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

// import images
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AuthProtected } from '../../Routes/AuthProtected';
import { useEnv } from '../../envContext';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import { authModes, authUser, enterpriseAccount, tenantConfig } from '../../store/actions';
import { tenantConfig as tenant } from '../constants/TenantConfig';
import { AuthUser, TenantConfig, prepareTenantData } from './Util';

const ProfileDropdown = () => {
    const urlconf = useEnv();
    let api = new APIClient();
    const history = useHistory();
    let dispatch = useDispatch();
    let accountId = localStorage.getItem('account');
    let tenantObj = TenantConfig();
    let tenantData = tenant?.[urlconf.REACT_APP_COMPANY_ID];
    const [userName, setUserName] = useState('Admin');
    let user = AuthUser();
    const obj = user ? JSON.parse(user) : '';

    useEffect(() => {
        if (user) {
            let name = defineName();
            setUserName(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName]);

    const defineName = () => {
        let name = '';
        if (obj.data?.firstname || obj?.data?.lastname) {
            if (obj.data?.firstname) name = obj?.data?.firstname;
            if (obj?.data?.lastname) name = name + ' ' + obj?.data?.lastname;
        } else if (obj?.data?.username) name = obj?.data?.username;
        else name = obj?.data?.email?.split('@')?.[0]?.replaceAll('.', ' ');
        return name;
    };

    // Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handleClick = () => {
        api.get(url.LOGOUT, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    sessionStorage.clear();
                    localStorage.clear();
                    dispatch(authUser(null));
                    dispatch(enterpriseAccount(null));
                    dispatch(authModes(null));
                    dispatch(tenantConfig(tenant?.[urlconf.REACT_APP_COMPANY_ID]));
                    history.push('/account');
                }
            })
            .catch((err) => {});
    };

    const userLabel = () => {
        let name = userName?.split(' ');
        if (name?.length === 1) return name?.[0]?.charAt(0)?.toUpperCase();
        else return name?.[0]?.charAt(0)?.toUpperCase() + name?.[1]?.charAt(0)?.toUpperCase();
    };

    const updateTenant = () => {
        api.get(url.TENANT_BY_CODE + accountId, '', domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                const ten = prepareTenantData(resp.data?.Config, tenantObj, tenantData);
                dispatch(tenantConfig(ten));
                history.push(window.location.hash?.replaceAll('#', ''));
                <AuthProtected />;
            })
            .catch((err) => {});
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <span className="rounded-circle header-profile-user border border-2 d-flex align-items-center justify-content-center fs-15 bg-success text-white">
                            {userLabel()}
                        </span>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                {userName ? userName : 'Tectoro'}
                            </span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text text-truncate">
                                {obj?.data?.roleName ? obj?.data?.roleName : 'Tenant owner'}
                            </span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName ? userName : 'Tectoro'}!</h6>
                    {/* <DropdownItem href="/#/profile">
                        <i className="ri-account-circle-fill text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span>
                    </DropdownItem> */}
                    {/* <DropdownItem href="/apps-chat">
                        <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle">Messages</span>
                    </DropdownItem>
                    <DropdownItem href="/apps-tasks-kanban">
                        <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle">Taskboard</span>
                    </DropdownItem>
                    <DropdownItem href="/pages-faqs">
                        <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem href="/pages-profile">
                        <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle">
                            Balance : <b>$5971.67</b>
                        </span>
                    </DropdownItem>
                    <DropdownItem href="/pages-profile-settings">
                        <span className="badge bg-soft-success text-success mt-1 float-end">New</span>
                        <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle">Settings</span>
                    </DropdownItem>
                    <DropdownItem href="/auth-lockscreen-basic">
                        <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle">Lock screen</span>
                    </DropdownItem> */}
                    {(obj?.data?.email?.toLowerCase() === 'yaswanthbabu.cholla@tectoro.com' ||
                        obj?.data?.email?.toLowerCase() === 'suribabu.balam@tectoro.com' ||
                        obj?.data?.email?.toLowerCase() === 'adithyakeshav.thummanapally@tectoro.com') && (
                        <DropdownItem onClick={updateTenant}>
                            <i className="ri ri-refresh-line text-muted fs-16 align-middle me-1"></i>{' '}
                            <span className="align-middle">Apply Latest Tenant</span>
                        </DropdownItem>
                    )}
                    <DropdownItem href="/#/login" onClick={handleClick}>
                        <i className="ri-logout-box-r-line text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
