import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import { DevicesInfo, LicenseInfo, details, tableData } from './TableSchema';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';

const License = () => {
    const [isSubmit, setIssubmit] = useState(false);
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    let api = new APIClient();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formTypeandid = formTypeAndId['formType'];
    document.title = 'Licenses';

    useEffect(() => {
        getLicenses();
    }, []);

    const getLicenses = () => {
        setLoading(true);
        api.get(url.LICENSE, '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            })
            .catch((err) => setLoading(false));
    };

    const handleClickView = (row) => {
        history.push('/license/view');
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License Management" pageTitle="License" />
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                            <Card>
                                <Row>
                                    {LicenseInfo.map((ele, index) => (
                                        <Col
                                            key={index}
                                            sm={4}
                                            className={`text-center p-5 ${index !== LicenseInfo.length - 1 ? 'border-end' : ''} `}
                                        >
                                            <div className="text-muted fw-medium fs-14">
                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                    {ele.title}
                                                </EllipsisToolTip>
                                            </div>
                                            <div className="fw-medium py-1 fw-semibold fs-16">{ele.value}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                            <Card>
                                <Row>
                                    {DevicesInfo.map((ele, index) => (
                                        <Col
                                            key={index}
                                            sm={4}
                                            className={`text-center p-5 ${index !== DevicesInfo.length - 1 ? 'border-end' : ''}`}
                                        >
                                            <div className="text-muted d-flex justify-content-center align-items-center fw-medium fs-14">
                                                {ele.icon}
                                                <div className="fw-medium py-1 fw-semibold fs-16">
                                                    <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                        {ele.title}
                                                    </EllipsisToolTip>
                                                </div>
                                            </div>
                                            <div className="fw-medium py-1 fw-semibold fs-16">{ele.value}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <TableContainer
                        hideDisplayTotalCount={true}
                        data={[...details]}
                        columns={tableData}
                        customPageSize={5}
                        handleClickView={handleClickView}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default License;
