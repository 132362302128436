import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getFormTypeAndRecordId } from '../../../Components/Common/Util';
import LAVA_IMAGE from '../../../assets/images/lavaodm.png';
import ACER_IMAGE from '../../../assets/images/acerodm.png';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import Loader from '../../../Components/Common/Loader';
import { useEnv } from '../../../envContext';
import { ACER_SCHEMA, LAVA_SCHEMA } from './Schema';
import { toast } from 'react-toastify';
import toastMessages from '../../../common/messages/toastMessages';

const AddODMFeatures = () => {
    document.title = 'Add ODM Feature';
    const api = new APIClient();
    const config = useEnv();
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const formType = formTypeAndId['formType'];
    const recordID = formTypeAndId['recordID'];

    const platform = window.location.hash?.includes('lava') ? 'lava' : 'acer';

    const [formValues, setFormValues] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (recordID) getById();
    }, []);

    const getById = () => {
        setLoading(true);
        api.get(url.CONFIG + '/' + recordID)
            .then((resp) => {
                resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setFormValues(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: formValues.name ? formValues.name : '',
            // Navigation Bar
            home: formValues?.config?.navigationBar?.home ? formValues?.config?.navigationBar?.home : false,
            recent: formValues?.config?.navigationBar?.recent ? formValues?.config?.navigationBar?.recent : false,
            back: formValues?.config?.navigationBar?.back ? formValues?.config?.navigationBar?.back : false,
            // Hard Keys
            volumeUp: formValues?.config?.hardKeys?.volumeUp ? formValues?.config?.hardKeys?.volumeUp : false,
            volumeDown: formValues?.config?.hardKeys?.volumeDown ? formValues?.config?.hardKeys?.volumeDown : false,
            power: formValues?.config?.hardKeys?.power ? formValues?.config?.hardKeys?.power : false,
            notifications: formValues?.config?.hardKeys?.notifications ? formValues?.config?.hardKeys?.notifications : false,
            factoryMode: formValues?.config?.hardKeys?.factoryMode ? formValues?.config?.hardKeys?.factoryMode : false,
            splitWindow: formValues?.config?.hardKeys?.splitWindow ? formValues?.config?.hardKeys?.splitWindow : false,
            deviceRecoryMode: formValues?.config?.hardKeys?.deviceRecoryMode ? formValues?.config?.hardKeys?.deviceRecoryMode : false
        },
        onSubmit: (values) => {
            setLoading(true);
            let obj = {
                name: values?.name,
                configType: 'ODMFEATURES',
                config: {
                    type: platform,
                    navigationBar: {
                        home: values?.home,
                        recent: values?.recent,
                        back: values?.back
                    },
                    hardKeys: {
                        volumeUp: values?.volumeUp,
                        volumeDown: values?.volumeDown,
                        power: values?.power,
                        splitWindow: platform === 'lava' ? values?.splitWindow : undefined,
                        notifications: platform === 'acer' ? values?.notifications : undefined,
                        factoryMode: values?.factoryMode,
                        deviceRecoryMode: values?.deviceRecoryMode
                    }
                }
            };
            let apiService;
            if (recordID) {
                apiService = api.update(url.CONFIG + '/' + recordID, obj);
            } else {
                apiService = api.create(url.CONFIG, obj);
            }
            apiService
                .then((resp) => {
                    resp = config.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') {
                        toast.success(recordID ? toastMessages.ODMFeaturesUpdated : toastMessages.ODMFeaturesCreated);
                        history.push('/odmfeatures');
                    }
                    setLoading(false);
                })
                .catch((error) => setLoading(false));
        }
    });

    const toggleComponent = (props) => {
        return (
            <>
                <Row key={props.index} className={'mb-3 ' + (props.field.class ? props.field.class : '')}>
                    {props.field.label && (
                        <Col xs={6} sm={6} md={6} xl={6} lg={6}>
                            <Label className="fs-14 mb-0 ">{props?.field?.label}</Label>
                            <div>
                                <div className="text-muted  mb-2 word-wrap-break">{props.field.helpText && props.field.helpText}</div>
                            </div>
                        </Col>
                    )}

                    <Col xs={4} sm={4} md={4} xl={4} lg={4}>
                        {props.formType !== 'view' ? (
                            <div className="form-check form-switch form-switch-lg">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name={props.field.value}
                                    id={props.field.value}
                                    checked={props.validation.values[props.field.value] || false}
                                    onChange={props.validation.handleChange}
                                    onBlur={props.validation.handleBlur}
                                    value={props.validation.values[props.field.value] || false}
                                />
                            </div>
                        ) : props.validation.values?.[props.field.value] ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={'page-content'}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="Add ODM Features"
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink={'odmfeatures'}
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">
                                ODM Features
                                <span className="badge-soft-success ms-1 p-1 rounded-3 fs-12">
                                    <img src={platform === 'lava' ? LAVA_IMAGE : ACER_IMAGE} alt="img" height={15} className="pe-1" />
                                </span>
                            </div>
                            <div className="fs-13">
                                ODM features customizable hardware, robust quality control, tailored software integration, and dedicated
                                customer support.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name={'name'}
                                            id={'name'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['name'] || ''}
                                            invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['name'] ? (
                                    <span className="ps-1">{validation?.values['name']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['name'] && validation.errors['name'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            {(platform === 'lava' ? LAVA_SCHEMA : ACER_SCHEMA).map((field, index) =>
                                field?.inputType ? (
                                    toggleComponent({ field: field, validation: validation })
                                ) : (
                                    <div className="mb-2" key={index}>
                                        <div className="fw-semibold fs-14">{field?.label}</div>
                                        <div className="fs-12">{field?.helpText}</div>
                                    </div>
                                )
                            )}
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex align-items-center justify-content-end">
                                <Row>
                                    <Col>
                                        <Button type="button" className="btn btn-light w-100" onClick={() => history.push('/odmfeatures')}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    {formType !== 'view' && (
                                        <Col>
                                            <Button
                                                size="md"
                                                type="button"
                                                className="d-flex align-items-center justify-content-center w-100"
                                                color={'primary'}
                                                onClick={validation.handleSubmit}
                                            >
                                                {formType === 'edit' ? 'Update' : 'Save'}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </CardFooter>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddODMFeatures;
