/* eslint-disable max-nested-callbacks */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import { AuthUser, IsAuthorized, convertUTCtoIST, ellipsisToolTip, sortByAsc } from '../../../Components/Common/Util';
import { COUNTRIES } from '../../../Components/constants/Countries';
import { GROUP_COLORS, priviliges } from '../../../Components/constants/constants';
import noUser from '../../../assets/images/nouser.png';
import userDelete from '../../../assets/images/userDelete.png';
import userActivate from '../../../assets/images/user_activate.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { usersSchema } from '../TableSchema';
import AddUsers from './AddUsers';

const Users = () => {
    document.title = 'Users';
    const urlconf = useEnv();
    let history = useHistory();
    const api = new APIClient();

    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [usersTableSchema, setUsersTableSchema] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [mode, setMode] = useState();
    const [currentDetailsObj, setCurrentDetailsObj] = useState();
    const [errors, setErrors] = useState({});
    const [displayType, setDisplayType] = useState('table');
    const [groups, setGroups] = useState([]);
    const [roleType, setRoleType] = useState('');
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });

    useEffect(() => {
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        getUsers(searchParams);
        let findPriv = user?.data?.privileges?.includes(priviliges.USER_EDITOR);
        let schema = [...usersSchema];
        if (!findPriv) schema = usersSchema.filter((policy, index) => index < usersSchema.length - 1);
        setUsersTableSchema([...schema]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUsers = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.USERS, params, domains.IDM)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((ele, index) => {
                        ele.status = ele.status === 'A' ? 'active' : 'inactive';
                        ele.modifiedBy = ele.modifiedby ? ele.modifiedby : 'Tectoro';
                        ele.modifiedDate = ele.modifieddate ? convertUTCtoIST(ele.modifieddate) : '—';
                        ele.avatarColor = GROUP_COLORS[index % 10];
                        ele.systemUser = ele?.modifiedby === 'SYSTEM';
                        ele.name = ele.name ? ele.name : '—';
                        ele.email = ele.email ? ele.email : '—';
                        ele.roleName = ele.roledata?.name ? ele.roledata?.name : '—';
                        ele.countryCode =
                            ele.phone?.length === 10
                                ? {
                                      name: 'India',
                                      code: 'IN',
                                      emoji: '🇮🇳',
                                      unicode: 'U+1F1EE U+1F1F3',
                                      image: 'IN.svg',
                                      dial_code: '91',
                                      disaplay_dial_code: '+91'
                                  }
                                : COUNTRIES?.find((country) => country.dial_code === ele.phone?.slice(0, 2));
                        ele.phone = ele.phone ? (ele.phone?.length > 10 ? ele.phone?.slice(2) : ele.phone) : '';
                        ele.rowEdit = true;
                        ele.rowDelete = true;
                    });
                    setUsers(resp.data);
                    setTotalRecords(resp.totalRecords);
                } else {
                    setUsers([]);
                    setTotalRecords(0);
                }
                setLoading(false);
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        setMode('add');
        setOffcanvasModal(true);
        setSelectedRow('');
    };

    const handleEdit = (row) => {
        setMode('edit');
        setOffcanvasModal(true);
        setSelectedRow(row);
    };

    const handleClickView = (row) => {
        setSelectedRow(row);
        setMode('view');
        setOffcanvasModal(true);
    };

    const handleDelete = (row) => {
        setDeleteModal(true);
        setSelectedRow(row);
    };

    const toggle = useCallback(async () => {
        if (offcanvasModal) setOffcanvasModal(false);
        else setOffcanvasModal(true);
    }, [offcanvasModal]);

    const saveFormData = (detailsObj, allGroupAccess) => {
        detailsObj = {
            ...detailsObj,
            firstname: detailsObj?.firstname?.trim(),
            lastname: detailsObj?.lastname?.trim(),
            email: detailsObj?.email?.trim(),
            allGroupAccess: allGroupAccess,
            username: detailsObj?.username,
            phone: detailsObj?.phone
        };
        setCurrentDetailsObj({ ...detailsObj });
    };

    const formErrors = (err) => {
        setErrors({ ...err });
    };

    const handleGroupSelection = (groupArr) => {
        setGroups(groupArr);
    };

    const handleRoleTypeChange = (option) => {
        setRoleType(option);
    };

    const handleOffcanvasBody = () => {
        return (
            <div className="position-reltive">
                <AddUsers
                    mode={mode}
                    record={selectedRow?.original ? selectedRow?.original : selectedRow}
                    toggle={toggle}
                    handleSubmit={handleSubmit}
                    saveFormData={saveFormData}
                    formErrors={formErrors}
                    handleGroupSelection={handleGroupSelection}
                    handleRoleTypeChange={handleRoleTypeChange}
                />
            </div>
        );
    };

    const compareGroups = () => {
        let originalGroupsArr = sortByAsc(selectedRow?.original?.groups || []);
        let currentGroupsArr = currentDetailsObj?.allGroupAccess ? ['*'] : sortByAsc(groups?.length > 0 ? groups : []);
        if (originalGroupsArr?.join(',') === currentGroupsArr?.join(',')) return undefined;
        return currentGroupsArr;
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = {
            name: currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname,
            firstname: currentDetailsObj.firstname,
            lastname: currentDetailsObj.lastname,
            email: currentDetailsObj.email,
            role: roleType?.value,
            groups: currentDetailsObj?.allGroupAccess ? ['*'] : groups?.length > 0 ? groups : [],
            extra: {},
            phone: currentDetailsObj?.phone ? currentDetailsObj?.countryCode?.dial_code + currentDetailsObj?.phone : undefined,
            authType: 'password',
            username: currentDetailsObj.username
        };
        let updateParams = {};
        if (mode === 'edit') {
            updateParams.email = selectedRow?.original?.email === currentDetailsObj?.email ? undefined : currentDetailsObj.email;
            updateParams.firstname =
                selectedRow?.original?.firstname === currentDetailsObj?.firstname ? undefined : currentDetailsObj?.firstname;
            updateParams.name =
                selectedRow?.original?.firstname === currentDetailsObj?.firstname &&
                selectedRow?.original?.lastname === currentDetailsObj?.lastname
                    ? undefined
                    : currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname;
            updateParams.lastname =
                selectedRow?.original?.lastname === currentDetailsObj?.lastname ? undefined : currentDetailsObj?.lastname;
            updateParams.name =
                selectedRow?.original?.firstname === currentDetailsObj?.firstname &&
                selectedRow?.original?.lastname === currentDetailsObj?.lastname
                    ? undefined
                    : currentDetailsObj.firstname + ' ' + currentDetailsObj.lastname;
            updateParams.role = selectedRow?.original?.role === roleType?.value ? undefined : roleType?.value;
            updateParams.groups = compareGroups();
            updateParams.phone =
                selectedRow?.original?.phone === currentDetailsObj?.phone &&
                selectedRow?.original?.countryCode?.dial_code === currentDetailsObj?.countryCode?.dial_code
                    ? undefined
                    : currentDetailsObj?.countryCode?.dial_code + currentDetailsObj.phone;
            params._id = selectedRow?.original?._id ? selectedRow?.original?._id : selectedRow?._id;
        }
        createNdUpdateData(mode === 'edit' ? updateParams : params, mode);
    };

    const createNdUpdateData = (params, modeType) => {
        if (saveDisabled()) {
            toast.error(toastMessages.fillAllMandatoryFields);
            return;
        }
        let apiService = '';
        setLoading(true);
        if (modeType === 'edit')
            apiService = api.patch(
                url.USERS + '/' + (selectedRow?.original?._id ? selectedRow?.original?._id : selectedRow?._id),
                params,
                false,
                domains.IDM
            );
        else apiService = api.create(url.USERS, params, false, domains.IDM);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(modeType === 'edit' ? toastMessages.userUpdated : toastMessages.userCreated);
                    setLoading(false);
                    setCurrentDetailsObj({});
                    getUsers(searchParams);
                    toggle();
                }
            })
            .catch((_err) => {
                setLoading(false);
            });
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        setDeleteModal(false);

        api.patch(
            url.USERS + '/' + (selectedRow?.original?._id ? selectedRow?.original?._id : selectedRow?._id),
            { status: selectedRow?.original?.status === 'active' ? 'I' : 'A' },
            false,
            domains.IDM
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(selectedRow?.original?.status === 'active' ? toastMessages.userDeactivated : toastMessages.userActivated);
                    getUsers(searchParams);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const saveDisabled = () => {
        let flag = true;
        Object.keys(errors).forEach((element) => {
            if (errors[element]) flag = false;
        });
        return (
            !currentDetailsObj?.firstname ||
            !currentDetailsObj?.lastname ||
            !currentDetailsObj?.email ||
            currentDetailsObj.phone.length !== 10 ||
            (mode !== 'edit' && !currentDetailsObj?.username) ||
            !flag ||
            !roleType?.value
        );
    };

    const handleGlobalSearch = useAsyncDebounce((val) => {
        getUsers({ ...searchParams, page: 1, username: val });
    }, 200);

    const onStatusChange = (row) => {
        setSelectedRow(row);
        setDeleteModal(true);
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">
                    Are you sure you want to {selectedRow?.original?.status === 'active' ? 'deactivate' : 'activate'} User?
                </div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img
                        src={selectedRow?.original?.status === 'active' ? userDelete : userActivate}
                        alt="deleteGroup"
                        width={60}
                        height={60}
                    />
                </div>
                <div>
                    User Name:{' '}
                    <span className="fw-semibold">{selectedRow?.original?.name ? selectedRow?.original?.name : selectedRow?.name}</span>
                </div>
            </div>
        );
    };

    const handleDisaplyChange = (type) => {
        setDisplayType(type);
    };

    const handlePageChange = (page) => {
        getUsers({ ...searchParams, page: page });
    };

    const handleAuditLog = (row) => {
        history.push('/users/auditlog/' + row?.original?._id);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Users" history={history} homeLink="Dashboard" />
                    {/* <Card className="mb-0">
                        <CardBody>
                            <Col sm="auto" className="d-flex align-items-center justify-content-between">
                                <Row className="g-2 align-items-center">
                                    <Col className="d-flex justify-content-start">
                                        <h4 className={'card-title mb-0'}>{`All Users (${totalRecords})`}</h4>
                                    </Col>
                                </Row>


                                {displayType !== 'table' && (
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                                            <tbody className="border-0 d-flex">
                                                <tr>
                                                    <td>
                                                        <h4 className="text-truncate fs-14 mb-0">
                                                            <i className="ri-stop-fill align-middle fs-22 text-black me-1"></i>
                                                            Tenant/Self
                                                        </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className="text-truncate fs-14 mb-0">
                                                            <i className="ri-stop-fill align-middle fs-22 text-dark-purple me-1"></i>
                                                            Full Access
                                                        </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4 className="text-truncate fs-14 mb-0">
                                                            <i className="ri-stop-fill align-middle fs-22 text-dark-blue me-1"></i>
                                                            Partial Access
                                                        </h4>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                <Col sm="auto">
                                    <Row className="align-items-center">
                                        <div className="d-flex col-sm-auto">
                                            <div className="form-icon flex-nowrap me-2">
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    id="search-bar-1"
                                                    value={value || ''}
                                                    className="form-control form-control-icon dash-filter-picker"
                                                    placeholder={'Search by Name'}
                                                    onChange={(e) => {
                                                        setValue(e.target.value);
                                                        handleGlobalSearch(e.target.value);
                                                    }}
                                                />
                                                <i className="ri-search-line link-info"></i>
                                            </div>
                                            <IsAuthorized
                                                privRequired={priviliges.USER_EDITOR}
                                                yes={() => (
                                                    <Col sm="auto" className="d-flex justify-content-end">
                                                        <div>
                                                            <Button type="button" color="primary" onClick={() => handleAddNew()}>
                                                                + {'Add New'}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                )}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Col>
                        </CardBody>
                    </Card> */}
                    {displayType === 'table' ? (
                        <Col xl={12}>
                            <TableContainer
                                loading={loading}
                                isGlobalFilter={true}
                                tableHeader={'All Users'}
                                addButton={true}
                                searchFilter2={true}
                                searchPlaceHolder="Username"
                                totalRecords={totalRecords}
                                handleGlobalSearch={handleGlobalSearch}
                                columns={usersTableSchema}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleAddNew={handleAddNew}
                                handleClickView={handleClickView}
                                data={users}
                                pageNum={searchParams.page}
                                customPageSize={searchParams.size}
                                onPageChange={handlePageChange}
                                onClickSwitch={onStatusChange}
                                handleAuditLog={handleAuditLog}
                                editor={priviliges.USER_EDITOR}
                                deletor={priviliges.USER_EDITOR}
                                reader={priviliges.USER_READER}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-nowrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </Col>
                    ) : (
                        <Row className="team-list grid-view-filter">
                            {users?.length > 0 ? (
                                users.map((item, key) => {
                                    return (
                                        <Col key={key} xl={2} lg={3} md={4} sm={6} xs={12}>
                                            <Card
                                                className={`team-box card-height-100 ${
                                                    !item.rowDelete
                                                        ? ''
                                                        : item?.userAccess === 'Partial Access'
                                                        ? 'border-2 border-dark-blue border-bottom'
                                                        : 'border-2 border-dark-purple border-bottom'
                                                }`}
                                            >
                                                <div className="card ribbon-box border h-100 ribbon-fill shadow-none right mb-lg-0">
                                                    <CardBody className={'p-4 card-height-100'}>
                                                        <div
                                                            className={`ribbon ribbon-${
                                                                !item.rowDelete
                                                                    ? 'black right-n75'
                                                                    : item?.userAccess === 'Partial Access'
                                                                    ? 'dark-blue right-n75'
                                                                    : 'dark-purple right-n75'
                                                            } fs-20 fw-bold`}
                                                        ></div>
                                                        <Row className="align-items-center team-row">
                                                            <Col lg={4} className="col">
                                                                <div className="team-profile-img mt-0 mb-2 ">
                                                                    <div className="avatar-sm br-9 flex-shrink-0 position-relative">
                                                                        <div
                                                                            className={`avatar-title text-uppercase br-9 text-white fw-medium fs-18 ${
                                                                                !item.rowDelete
                                                                                    ? 'bg-black'
                                                                                    : item?.userAccess === 'Partial Access'
                                                                                    ? 'bg-dark-blue'
                                                                                    : 'bg-dark-purple'
                                                                            }`}
                                                                        >
                                                                            {item?.name?.charAt(0) +
                                                                                item?.name?.split(' ').slice(-1).toString()?.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-content">
                                                                        <div className="d-flex align-items-center justify-content-center mb-1">
                                                                            <div className="text-center me-2 mb-0 fs-14 fw-medium width-200">
                                                                                {ellipsisToolTip(item.name)}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="d-flex align-items-center justify-content-center mb-2">
                                                                            <div className="me-2 mb-0 text-center fs-13 fw-medium text-primary">
                                                                                {`No.of Privileges (${
                                                                                    item.roles?.length > roles?.length
                                                                                        ? roles.length
                                                                                        : item?.roles?.length
                                                                                })`}
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <IsAuthorized
                                                                                privRequired={'DEVICEGROUP_ADMIN'}
                                                                                yes={() => {
                                                                                    return (
                                                                                        item.rowEdit && (
                                                                                            <div
                                                                                                className={`me-2 mb-0 text-center fs-13 fw-medium text-primary cursor-pointer ${
                                                                                                    item.rowEdit
                                                                                                        ? 'border-2 border-primary border-end pe-2'
                                                                                                        : ''
                                                                                                } `}
                                                                                                onClick={() => handleEdit(item)}
                                                                                            >
                                                                                                Edit
                                                                                            </div>
                                                                                        )
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <div
                                                                                className={`me-2 mb-0 text-center fs-13 fw-medium text-primary cursor-pointer ${
                                                                                    item.rowDelete ? '' : ''
                                                                                } `}
                                                                                onClick={() => handleClickView(item)}
                                                                            >
                                                                                View
                                                                            </div>
                                                                            <IsAuthorized
                                                                                privRequired={priviliges.USER_EDITOR}
                                                                                yes={() => {
                                                                                    return (
                                                                                        item.rowDelete && (
                                                                                            <div
                                                                                                className="border-2 border-primary border-start pe-2 ps-2 mb-0 text-center fs-13 fw-medium text-primary cursor-pointer"
                                                                                                onClick={() => handleDelete(item)}
                                                                                            >
                                                                                                Delete
                                                                                            </div>
                                                                                        )
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="text-danger fs-10 mt-1 fw-semibold">
                                                                            {!item.rowDelete && (
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <div className="me-1 fs-12">*</div>
                                                                                    <div>
                                                                                        {item.systemUser
                                                                                            ? 'You cannot delete default account'
                                                                                            : 'You cannot delete your own account'}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </div>
                                            </Card>
                                        </Col>
                                    );
                                })
                            ) : (
                                <Card className="card-height-100">
                                    <CardBody>
                                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                            <div className="d-flex align-items-center flex-column">
                                                <img src={noUser} alt="No Users" width={80} height={80} />
                                                <p className="text-muted fs-15 mb-0">No Users found.</p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Row>
                    )}

                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={message()}
                        show={deleteModal}
                        confirmText={selectedRow?.original?.status === 'active' ? 'Yes, Deactivate it!' : 'Yes, Activate it!'}
                        onDeleteClick={handleDeleteConfirmation}
                        onCloseClick={() => {
                            setDeleteModal(false);
                            setSelectedRow('');
                        }}
                        deleteMessage={'Are you sure you want to delete this user ?'}
                    />
                    <OffcanvasModal
                        direction="end"
                        toggle={toggle}
                        open={offcanvasModal}
                        hideSaveButton={mode === 'view'}
                        handleCloseClick={toggle}
                        OffcanvasModalID="usersModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName="w-40"
                        saveText={mode === 'edit' ? 'Update' : 'Save'}
                        offcanvasHeader={mode === 'add' ? 'Add New User' : `${mode?.charAt(0)?.toUpperCase() + mode?.slice(1)} User`}
                        saveDisabled={saveDisabled()}
                        handleSaveClick={handleSubmit}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Users;
