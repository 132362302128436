/* eslint-disable no-unsafe-optional-chaining */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Slider from 'react-rangeslider';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    Alert,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormFeedback,
    Input,
    Label,
    Row,
    UncontrolledTooltip
} from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { TenantConfig, Tooltip, adddateWithTime, getFormTypeAndRecordId, timeWithout530 } from '../../../Components/Common/Util';
import AndroidTvImage from '../../../assets/images/androidtv.png';
import AndroidIcon from '../../../assets/images/svg/android.svg';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import tvPoliciesFieldsSchema from './TvPoliciesFieldsSchema';
import Flatpickr from 'react-flatpickr';

const AddAndroidTVPolicy = ({ code, mode }) => {
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = mode ? mode : formTypeAndId['formType'];
    let recordID = code ? code : formTypeAndId['recordID'];
    document.title = formType === 'view' ? 'View TV Policy' : formType === 'edit' ? 'Edit TV Policy' : 'Add TV Policy';
    let tenant = TenantConfig();
    const [selected, setSelected] = useState('');
    const [profileDetails, setProfileDetails] = useState(JSON.parse(JSON.stringify(tvPoliciesFieldsSchema)));
    const [wallpapers, setWallpapers] = useState([]);
    const [selectedTab, setSelectedTab] = useState('non-playstore');
    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);
    const [formValues, setFormValues] = useState('');
    const [configurations, setConfigurations] = useState({ geofenceConfigs: [] });

    const nonPlayStoreName = (
        <div className={`d-flex align-items-center gap-2 fw-medium ${selectedTab === 'non-playstore' ? 'text-success' : 'text-muted'}`}>
            <img src={AndroidTvImage} alt="chrome_image" height="30" />
            Non Play Store Apps
        </div>
    );

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelected({ ...profileDetails?.[0] });
        handlePromise();
    }, []);

    const handlePromise = () => {
        setLoading(true);
        const applicationsPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 1000 };
            api.get(url.TV_APPS, params)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Application failed.');
                })
                .catch((err) => reject('Application failed.'));
        });

        const assetsPromise = new Promise((resolve, reject) => {
            api.get(url.ASSETS, { page: 1, size: 2000 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Asset failed.');
                })
                .catch((err) => reject('Asset failed.'));
        });

        const policyPromisee = new Promise((resolve, reject) => {
            if (recordID) {
                api.get(url.TV_POLICIES + '/' + recordID)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy failed.');
                    })
                    .catch((err) => reject('Policy failed.'));
            } else {
                reject('Policy failed.');
            }
        });

        const geofencePromise = new Promise((resolve, reject) => {
            api.get(url.CONFIG, { page: 1, size: 100000, metrics: false, configType: 'GEOFENCE' })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Geofence failed.');
                })
                .catch((err) => reject('Geofence failed.'));
        });

        Promise.allSettled([policyPromisee, applicationsPromise, assetsPromise, geofencePromise])
            .then((result) => {
                let apps = [];
                if (result[1].status === 'fulfilled') {
                    apps = result[1].value;
                }
                let wallpaperArr = [];
                if (result[2].status === 'fulfilled') {
                    if (result[2]?.value?.length > 0) {
                        result[2]?.value?.forEach((ele) => {
                            let obj = { label: ele.name, value: ele.serverFileName, serverFileName: ele.serverFileName };
                            if (ele.assetType === 'Wallpaper') wallpaperArr.push(obj);
                        });
                    }
                    setWallpapers(wallpaperArr);
                }

                let configObj = { geofenceConfigs: [] };
                if (result[3].status === 'fulfilled') {
                    configObj.geofenceConfigs = result[3].value;
                }
                if (result[0].status === 'fulfilled') {
                    let wallpaperObj = wallpaperArr.find((wall) => wall.value === result[0]?.value?.policy?.wallpaper);
                    let geofenceObj = configObj?.geofenceConfigs?.find(
                        (fence) => fence.id === result?.[0]?.value?.policy?.config?.GEOFENCE?.restrictions?.zones?.[0]?.id
                    );
                    let onTime = new Date();

                    if (result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOnTime) {
                        let timeArr =
                            result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOnTime?.split(':');
                        onTime.setHours(timeArr[0]);
                        onTime.setMinutes(timeArr[1]);
                    }
                    let offTime = new Date();
                    if (result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOffTime) {
                        let timeArr =
                            result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOffTime?.split(':');
                        offTime.setHours(timeArr[0]);
                        offTime.setMinutes(timeArr[1]);
                    }

                    setFormValues({
                        name: result[0].value?.name,
                        wallpaper: wallpaperObj,
                        createdBy: result[0]?.value?.createdBy,
                        createdTime: result[0]?.value?.createdTime,
                        /*
                         * launcherExitPassword: result[0]?.value?.policy?.launcherExitPassword,
                         * enableScreamer: !!result[0]?.value?.policy?.enableScreamer
                         * enableLocation: !!result[0]?.value?.policy?.enableLocation,
                         * enableRemote: !!result[0]?.value?.policy?.enableRemote,
                         */
                        geofence: geofenceObj,
                        enableVolume: !!result[0]?.value?.policy?.config?.VOLUME?.featureEnabled,
                        enableInputMethod: !!result[0]?.value?.policy?.config?.INPUTMETHOD?.featureEnabled,
                        inputDropdown: [
                            { label: 'HDMI1 ', value: 'HDMI1 ' },
                            { label: 'HDMI2 ', value: 'HDMI2 ' },
                            { label: 'HDMI3 ', value: 'HDMI3 ' },
                            { label: 'HDMI4 ', value: 'HDMI4 ' }
                        ].find((option) => option.value === result[0]?.value?.policy?.config?.INPUTMETHOD?.restrictions?.inputmethod),
                        enablepowerControl: !!result[0]?.value?.policy?.config?.POWERCONTROLS?.featureEnabled,
                        enablePowerStatus: [
                            { label: 'ON', value: true },
                            { label: 'OFF', value: false }
                        ].find((option) => option.value === result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.powerStatus),
                        enableSchedulepowerControl:
                            !!result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.enableschedulePowerControls,
                        frequency: result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.frequency,
                        volumeLevel: result[0]?.value?.policy?.config?.VOLUME?.restrictions?.value,
                        shutdowntime: result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOffTime
                            ? offTime
                            : '',

                        powerontime: result[0]?.value?.policy?.config?.POWERCONTROLS?.restrictions?.schedulePowerControls?.powerOnTime
                            ? onTime
                            : ''
                    });
                    let packageArr = result[0]?.value?.policy?.apps?.map((app) => app.packageName);
                    apps.forEach((app) => {
                        if (packageArr.includes(app.packageName)) {
                            app.selected = true;
                        }
                    });
                }
                let selectedApps = apps.filter((app) => app.selected);
                let unSelectedApps = apps.filter((app) => !app.selected);
                let finalApps = selectedApps.concat(formType === 'view' ? [] : unSelectedApps);
                setApplications(finalApps);
                setApplicationsBackup(finalApps);
                setConfigurations(configObj);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: formValues.id ? formValues.id : '',
            policyName: formValues.name ? formValues.name : '',
            wallpaper: formValues.wallpaper ? formValues.wallpaper : '',
            /*
             * launcherExitPassword: formValues?.launcherExitPassword ? formValues?.launcherExitPassword : '',
             * enableLocation: !!formValues?.enableLocation,
             * enableRemote: !!formValues?.enableRemote,
             * enableScreamer: !!formValues?.enableScreamer
             */
            enableVolume: !!formValues?.enableVolume,
            volumeLevel: formValues?.volumeLevel || formValues?.volumeLevel === 0 ? formValues?.volumeLevel : 100,
            enableInputMethod: !!formValues?.enableInputMethod,
            inputDropdown: formValues.inputDropdown,
            enablepowerControl: !!formValues.enablepowerControl,
            enablePowerStatus: formValues?.enablePowerStatus,
            enableSchedulepowerControl: !!formValues?.enableSchedulepowerControl,
            powerontime: formValues?.powerontime,
            shutdowntime: formValues?.shutdowntime,
            frequency: formValues?.frequency ? formValues?.frequency : [],
            geofence: formValues?.geofence ? formValues?.geofence : []
        },
        validationSchema: Yup.object({
            policyName: Yup.string().required('Please enter your policy name')
        })
    });

    useEffect(() => {
        if (!validation.values?.enablepowerControl) {
            validation.setValues({
                ...validation.values,
                enablePowerStatus: '',
                enableSchedulepowerControl: false,
                frequency: [],
                powerontime: '',
                shutdowntime: ''
            });
        } else if (!validation.values?.enableSchedulepowerControl) {
            validation.setValues({
                ...validation.values,
                frequency: [],
                powerontime: '',
                shutdowntime: ''
            });
        }
    }, [validation.values?.enablepowerControl, validation.values?.enableSchedulepowerControl]);

    const prepareApplicationData = () => {
        let selectedApps = [];
        applicationsBackup.forEach((app) => {
            if (app.selected) {
                selectedApps.push({
                    appName: app.appName,
                    packageName: app.packageName,
                    version: app.appVersion,
                    apkUrl: app.appServerFileName,
                    iconUrl: app.iconServerFileName
                });
            }
        });
        return selectedApps;
    };
    const handleSubmit = () => {
        let dataObj = {
            name: validation.values.policyName,
            policy: {
                wallpaper: validation.values?.wallpaper?.serverFileName,
                /*
                 * launcherExitPassword: validation.values?.launcherExitPassword,
                 * enableLocation: !!validation.values.enableLocation,
                 * enableRemote: !!validation.values?.enableRemote,
                 * enableScreamer: !!validation.values?.enableScreamer,
                 */
                apps: prepareApplicationData(),
                config: {
                    VOLUME: {
                        featureEnabled: !!validation.values.enableVolume,
                        restrictions: { value: validation.values.volumeLevel }
                    },
                    INPUTMETHOD: {
                        featureEnabled: !!validation.values.enableInputMethod,
                        restrictions: {
                            inputmethod: validation.values?.inputDropdown?.value
                        }
                    },
                    POWERCONTROLS: {
                        featureEnabled: !!validation.values?.enablepowerControl,
                        restrictions: {
                            powerStatus: validation.values?.enablePowerStatus?.value,
                            enableschedulePowerControls: !!validation.values?.enableSchedulepowerControl,
                            schedulePowerControls: {
                                frequency: validation.values?.frequency,
                                powerOnTime: timeWithout530(validation.values?.powerontime, false),
                                powerOffTime: timeWithout530(validation.values?.shutdowntime, false)
                            }
                        }
                    }
                }
            }
        };
        if (tenant.ENABLE_TV_GEOFENCE && validation.values?.geofence?.id)
            dataObj.policy.config = {
                ...dataObj.policy.config,
                GEOFENCE: {
                    featureEnabled: !!validation.values?.geofence?.id,
                    restrictions: { zones: [{ ...validation.values.geofence.config, id: validation.values.geofence.id }] }
                }
            };
        let apiService;
        if (formType === 'edit') apiService = api.update(url.TV_POLICIES + '/' + recordID, dataObj);
        else apiService = api.create(url.TV_POLICIES, dataObj);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.androidTvPolicyUpdated : toastMessages.androidTvPolicyCreated);
                    history.push('/atvpolicies');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleLauncherPasswordChange = (e) => {
        let regex = new RegExp(/^[0-9]+$/);
        let num = Number(e.target.value);
        let valid = regex.test(num);
        if (valid && e.target.value?.length <= 6) {
            validation.setValues({ ...validation.values, launcherExitPassword: e.target.value });
        }
    };

    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Col
                    xs={6}
                    md={6}
                    sm={6}
                    lg={code || field.codeBased ? 7 : 3}
                    xl={code || field.codeBased ? 7 : 3}
                    className={`d-flex ${field.notes ? '' : 'align-items-center'} justify-content-between`}
                >
                    <Label className={`mb-0 fw-medium d-flex ${field.notes ? '' : 'align-items-center'}`}>
                        {field.label}
                        {field.mandatory && formType !== 'view' ? (
                            <span className="red-color ps-1">*</span>
                        ) : field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${field.notes ? '' : 'align-items-center'} ${
                                        field.mandatoryTooltip ? 'link-danger' : 'blue-icon'
                                    } ps-1 fs-15`}
                                    id={`inputComponent-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    <div className="input-group">
                        {formType !== 'view' ? (
                            <Input
                                name={field.value}
                                id={field.value}
                                className="form-control"
                                placeholder={`Enter ${field.label}`}
                                type={field.type}
                                validate={{ required: { value: true } }}
                                onChange={
                                    field.value === 'launcherExitPassword'
                                        ? (e) => handleLauncherPasswordChange(e)
                                        : validation.handleChange
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values[field.value] || ''}
                                invalid={validation.touched[field.value] && validation.errors[field.value] ? true : false}
                            />
                        ) : validation?.values[field.value] ? (
                            validation?.values[field.value]
                        ) : (
                            '–'
                        )}
                        {validation.touched[field.value] && validation.errors[field.value] ? (
                            <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                        ) : null}
                    </div>
                    {field.notes && (
                        <Alert className={`alert-borderless p-1 alert-${field.class} text-center mt-1 mb-0`} role="alert">
                            {field.notes}
                        </Alert>
                    )}
                </Col>
            </React.Fragment>
        );
    };

    const handleProfileDetail = (profileObj, index) => {
        profileObj = { ...profileObj, active: true };
        let profileDetailsArr = [];
        profileDetails.map((detail) => {
            if (profileDetails.indexOf(detail) === index) return profileDetailsArr.push(profileObj);
            else return profileDetailsArr.push({ ...detail, active: false });
        });
        setSelected(profileObj);
        setProfileDetails(profileDetailsArr);
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={code ? 7 : 3} xl={code ? 7 : 3} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' ? (
                        <div className="form-check form-switch form-switch-lg">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name={field.value}
                                id={field.value}
                                checked={validation.values[field.value] || false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />
                        </div>
                    ) : validation?.values[field.value] ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </Col>
            </>
        );
    };

    const selectComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field?.label && (
                    <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={code ? 7 : 3}
                        xl={code ? 7 : 3}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            {field.mandatory && mode !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                )}

                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' ? (
                        <Select
                            getOptionValue={(option) => (field.optionValue ? option[field.optionValue] : option.value)}
                            getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                            isMulti={field.isMulti ? true : false}
                            isClearable={field.isMulti || field.removable ? true : false}
                            closeMenuOnSelect={field.isMulti ? false : true}
                            id={field.label}
                            name={field.value}
                            // isDisabled={field.value === 'delegatedScopes' ? selectedAppData?.code === 'LAUNCHER' : false}
                            options={
                                field.optionsVal === 'wallpapers'
                                    ? wallpapers
                                    : field.optionsVal === 'geofenceConfigs'
                                    ? configurations?.geofenceConfigs
                                    : field.options
                            }
                            placeholder={`Select ${field.label ? field.label : field.placeholder}`}
                            onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                            onChange={(selectedOption) => {
                                validation.handleChange({ target: { name: field.value, value: selectedOption } });
                            }}
                            value={validation.values[field.value] || ''}
                            isSearchable={true}
                            noOptionsMessage={() => 'No data found'}
                        />
                    ) : validation?.values?.[field.value]?.length > 0 ? (
                        validation?.values?.[field.value]
                            ?.map((v) => v?.label)
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name ? (
                        validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name
                    ) : validation?.values[field.value] ? (
                        validation?.values[field.value]
                    ) : (
                        '–'
                    )}
                </Col>
            </React.Fragment>
        );
    };
    const checkValue = (value) => {
        return value !== null && value !== undefined;
    };
    const formatLabel = (value, label) => {
        if (checkValue(label)) return value + ' ' + label;
        else return value;
    };
    const sliderComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Col xs={6} md={6} sm={6} lg={code ? 7 : 3} xl={code ? 7 : 3} className="d-flex align-items-center justify-content-between">
                    <Label className="mb-0 fw-medium d-flex align-items-center">
                        {field.label}
                        {field.mandatory && mode !== 'view' ? (
                            <span className="red-color ps-1">*</span>
                        ) : field.helpText ? (
                            <>
                                <i
                                    className={`ri-information-line d-flex ${field.notes ? '' : 'align-items-center'} blue-icon ps-1 fs-15`}
                                    id={`inputComponent-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                    {formType !== 'view' ? (
                        <React.Fragment>
                            <div className="d-flex align-items-center justify-content-between">
                                <span>{field.min}</span>
                                <span>Value: {validation.values[field.value] ? validation.values[field.value] : 0}%</span>
                                <span>{field.max}</span>
                            </div>
                            <div className="custom-range-slider-info">
                                <Slider
                                    className="my-3"
                                    min={field.min}
                                    max={field.max}
                                    step={field.step}
                                    format={(value) => formatLabel(value, '%')}
                                    onBlur={() => validation.handleBlur({ target: { name: [field.value] } })}
                                    onChange={
                                        formType === 'view'
                                            ? null
                                            : (e) =>
                                                  validation.handleChange({
                                                      target: { name: [field.value], value: e }
                                                  })
                                    }
                                    value={validation.values[field.value]}
                                />
                            </div>
                        </React.Fragment>
                    ) : validation?.values?.[field.value]?.length > 0 ? (
                        validation?.values?.[field.value]
                            ?.map((v) => v?.label)
                            ?.toString()
                            ?.split(',')
                            ?.join(', ')
                    ) : validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name ? (
                        validation?.values?.[field.value]?.label || validation?.values?.[field.value]?.name
                    ) : validation?.values[field.value] ? (
                        validation?.values[field.value]
                    ) : (
                        '–'
                    )}
                </Col>
            </React.Fragment>
        );
    };

    const checkboxComponent = (field, index) => {
        const handleCheckChange = (key, value) => {
            validation.setValues({ ...validation.values, [key]: value });
        };

        return (
            <React.Fragment key={index}>
                <Row className="mb-2">
                    <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={code ? 7 : 3}
                        xl={code ? 7 : 3}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            {field.mandatory && mode !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        {field.checkboxes?.map((checkfield, ind) => {
                            return (
                                <div className="form-check form-check-inline align-items-center" key={ind}>
                                    {formType !== 'view' ? (
                                        <>
                                            <Input
                                                name={field.value}
                                                id={field.value}
                                                className="form-check-input"
                                                placeholder={`Enter ${field.label}`}
                                                type={'checkbox'}
                                                validate={{ required: { value: true } }}
                                                onChange={() =>
                                                    handleCheckChange(
                                                        field?.value,
                                                        validation.values?.[field.value]?.includes(checkfield)
                                                            ? validation.values?.[field.value]?.filter((d) => d !== checkfield)
                                                            : [...validation.values?.[field.value], checkfield]
                                                    )
                                                }
                                                onBlur={validation.handleBlur}
                                                value={validation.values[field.value] || ''}
                                                checked={validation.values?.[field?.value]?.includes(checkfield)}
                                            />
                                            <Label>{checkfield}</Label>
                                        </>
                                    ) : validation?.values[field.value] ? (
                                        validation?.values[field.value]
                                    ) : (
                                        '–'
                                    )}
                                    {validation.touched[field.value] && validation.errors[field.value] ? (
                                        <FormFeedback type="invalid">{validation.errors[field.value]}</FormFeedback>
                                    ) : null}
                                </div>
                            );
                        })}

                        {field.notes && (
                            <Alert className={`alert-borderless p-1 alert-${field.class} text-center mt-1 mb-0`} role="alert">
                                {field.notes}
                            </Alert>
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const timePickerComponent = (field, index) => {
        const handleTime = (value, date) => {
            value === 'powerontime'
                ? validation?.setValues({ ...validation.values, [value]: date, shutdowntime: '' })
                : validation?.setValues({ ...validation.values, [value]: date });
        };
        return (
            <React.Fragment key={index}>
                <Row className="mb-2">
                    <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={code ? 7 : 3}
                        xl={code ? 7 : 3}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="mb-0 fw-medium d-flex align-items-center">
                            {field.label}
                            {field.mandatory && mode !== 'view' ? (
                                <span className="red-color ps-1">*</span>
                            ) : field.helpText ? (
                                <>
                                    <i
                                        className={`ri-information-line d-flex ${
                                            field.notes ? '' : 'align-items-center'
                                        } blue-icon ps-1 fs-15`}
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <span className="ps-1">:</span>
                            )}
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                placeholder={field.label}
                                value={validation?.values?.[field?.value]}
                                name={field?.value}
                                className="form-control form-control-icon min-width-160 me-2"
                                options={field.options}
                                onChange={(evt) => {
                                    handleTime(field?.value, evt);
                                }}
                            />
                            <i className={field.icon} />
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };
    const dynamicComponents = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field.inputType === 'input'
                    ? inputComponent(field, index)
                    : field.inputType === 'toggle'
                    ? toggleButton(field, index)
                    : field.inputType === 'select'
                    ? selectComponent(field, index)
                    : field.inputType === 'slider'
                    ? sliderComponent(field, index)
                    : field.inputType === 'checkbox'
                    ? checkboxComponent(field, index)
                    : field.inputType === 'timePicker'
                    ? timePickerComponent(field, index)
                    : field.isHeader && <div className="fw-semibold text-decoration-underline text-success fs-14">{field.label} :</div>}
            </React.Fragment>
        );
    };
    /*
     * useEffect(() => {
     *     if (!validation.values?.enableSchedulepowerControl) {
     *         validation.setValues({ ...formValues, frequency: [], shutdowntime: '', poweronstatus: '' });
     *     }
     *     //  validation.setF
     *     validation.setValues({
     *         ...formValues,
     *         enableSchedulepowerControl: false,
     *         enablePowerStatus: '',
     *         frequency: [],
     *         shutdowntime: '',
     *         poweronstatus: ''
     *     });
     * }, [validation.values?.enableSchedulepowerControl, validation.values?.enablepowerControl]);
     */

    const handleAppSelection = (e, app, index) => {
        let apps = JSON.parse(JSON.stringify(applicationsBackup));
        apps.forEach((appObj) => {
            if (appObj.packageName === app.packageName) {
                appObj.selected = e.target.checked;
            }
        });
        let selectedApps = apps.filter((appObj) => appObj.selected);
        let unselectedApps = apps.filter((appObj) => !appObj.selected);
        setApplicationsBackup(apps);
        setApplications(selectedApps.concat(unselectedApps));
    };

    const saveDisabled = () => {
        return (
            validation.values.policyName === '' ||
            (selected.key === 'powerControls' &&
                validation.values.enableSchedulepowerControl &&
                (!validation.values?.frequency || !validation.values?.powerontime || !validation.values?.shutdowntime))
            /*
             *  ||
             * validation.values.launcherExitPassword?.length < 3 ||
             * validation.values.launcherExitPassword?.includes('.')
             */
        );
    };

    const handleAppsTabContent = (type) => {
        return type === 'non-playstore' ? (
            <div className=" mt-3 mb-3">
                <Row>
                    {applications?.length > 0 ? (
                        applications.map((app, index) => {
                            return (
                                <Col key={index} xl={code ? 6 : 2} ms={code ? 6 : 4} xs={code ? 12 : 6}>
                                    <Card>
                                        <CardHeader className="p-3 d-flex justify-content-end algin-items-center">
                                            <input
                                                className="form-check-input cursor-pointer"
                                                type="checkbox"
                                                disabled={formType === 'view'}
                                                checked={app.selected ? app.selected : false}
                                                onChange={(e) => handleAppSelection(e, app, index)}
                                            />
                                        </CardHeader>
                                        <CardBody>
                                            <div className=" d-flex align-items-center flex-column">
                                                <div className="avatar-sm flex-shrink-0">
                                                    <img
                                                        src={
                                                            app.iconUrl !== '' && app.iconUrl !== null && app.iconUrl !== undefined
                                                                ? app.iconUrl
                                                                : AndroidIcon
                                                        }
                                                        alt={''}
                                                        className="rounded-2"
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>
                                                <div className="flex-grow-1 mt-1 w-100">
                                                    <>
                                                        <p
                                                            className="fs-14 fw-medium m-0 text-muted text-center mb-1 text-truncate"
                                                            id={`title-${index}`}
                                                        >
                                                            {app.appName}
                                                        </p>
                                                        {Tooltip(`title-${index}`, app.appName)}
                                                    </>
                                                    <>
                                                        <p
                                                            className="fs-14 m-0 mb-1 text-muted text-center text-truncate"
                                                            id={`package-${index}`}
                                                        >
                                                            {app.packageName}
                                                        </p>
                                                        {Tooltip(`package-${index}`, app.packageName)}
                                                    </>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })
                    ) : (
                        <div className="fs-12 text-center">{'No data found.'}</div>
                    )}
                </Row>
            </div>
        ) : (
            ''
        );
    };

    const appsTabs = [{ tabID: 'non-playstore', tabName: nonPlayStoreName, tabContent: handleAppsTabContent('non-playstore') }];

    const handleShowOn = (field) => {
        let flag = true;
        field.showOn?.forEach((showOn) => (flag = flag && !!validation.values[showOn]));
        return flag;
    };
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`${code ? '' : 'page-content'} ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    {!code && (
                        <BreadCrumb
                            pageTitle={formType === 'view' ? 'View TV Policy' : formType === 'edit' ? 'Edit Tv Policy' : 'Add TV Policy'}
                            history={history}
                            homeLink="Dashboard"
                            showBack={true}
                            backLink="atvpolicies"
                        />
                    )}
                    <div className="h-100">
                        <Card className="card-height-100">
                            <CardHeader className="p-4">
                                <Row className="d-flex gap-2 flex-column">
                                    <Col lg={6} className="d-flex flex-column gap-2">
                                        <Row>
                                            {inputComponent({
                                                label: 'Policy Name',
                                                value: 'policyName',
                                                type: 'text',
                                                exclude: true,
                                                mandatory: true,
                                                codeBased: false
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="p-0">
                                <Row className={`${!validation.values.policyName ? 'mask pe-none' : ''}`}>
                                    <Col xs={12} sm={12} md={12} lg={code ? 5 : 3} xl={code ? 4 : 2}>
                                        <Card className="h-100 border mb-0 shadow-none">
                                            <div className="chat-message-list">
                                                <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                                    {profileDetails.map((profile, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                onClick={() => handleProfileDetail(profile, index)}
                                                                className={
                                                                    'd-flex align-items-center p-2 cursor-pointer' +
                                                                    (profile.active ? ' active' : '')
                                                                }
                                                            >
                                                                <i className={`${profile.icon} fs-18 ms-2 me-2`}></i>
                                                                <span className="text-truncate" id={'label-' + index}>
                                                                    {profile.label}
                                                                    {profile.label?.length > 22 && Tooltip('label-' + index, profile.label)}
                                                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </Card>
                                    </Col>
                                    {selected && selected !== '' && (
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={selected ? (code ? 7 : 9) : code ? 7 : 6}
                                            xl={selected ? (code ? 8 : 10) : code ? 8 : 7}
                                        >
                                            <Row sm={12} className="p-4 gap-3 align-items-center">
                                                {selected.key !== 'apps' ? (
                                                    selected.fields?.length > 0 &&
                                                    selected.fields.map((field, index) => {
                                                        return (
                                                            (field.hide
                                                                ? false
                                                                : field.conditionalHide
                                                                ? field.hideOn === 'tenant'
                                                                    ? tenant.ENABLE_TV_GEOFENCE
                                                                    : field.hideOn === 'value'
                                                                    ? validation.values[field.field]
                                                                    : field.hideOn === 'showon'
                                                                    ? handleShowOn(field)
                                                                    : true
                                                                : true) && (
                                                                <Row
                                                                    className={`p-0 ${field.notes ? '' : 'align-items-center'}`}
                                                                    key={index}
                                                                >
                                                                    {dynamicComponents(field, index)}
                                                                </Row>
                                                            )
                                                        );
                                                    })
                                                ) : (
                                                    <TabsComponent
                                                        tabsList={appsTabs}
                                                        defaultTabID={'non-playstore'}
                                                        mode={formType}
                                                        nomuteBg={true}
                                                        toggleTab={(tab) => setSelectedTab(tab)}
                                                    />
                                                )}
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                            <CardFooter>
                                {selected && selected !== '' && !code && (
                                    <Row className="p-0 m-0">
                                        <Col>
                                            <div className="gap-2 d-flex justify-content-end">
                                                <button type="reset" className="btn btn-light" onClick={() => history.push('/atvpolicies')}>
                                                    Cancel
                                                </button>
                                                {formType !== 'view' && !code && (
                                                    <button
                                                        className="btn btn-success"
                                                        id="add-btn"
                                                        disabled={saveDisabled()}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        {formType === 'edit' ? 'Update' : 'Save'}
                                                    </button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </CardFooter>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddAndroidTVPolicy;
