import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { TenantConfig, getDateOnly, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { AndroidPolicyQR, enrolmentProcess, qrSecurityOptions } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import { useFormik } from 'formik';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';

const EnrollmentToken = () => {
    document.title = 'Enrollment Token';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    let tenant = TenantConfig();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash, true);
    let policyName = localStorage.getItem('policyNameForTokens');
    let recordID = formTypeAndId['recordID'];
    let polType = formTypeAndId['queryType'];
    let tokenExpirationOptions = [
        { label: '1 Month', value: 30 },
        { label: '3 Months', value: 90 },
        { label: '6 Months', value: 180 },
        { label: '1 Year', value: 365 },
        { label: '2 Years', value: 730 },
        { label: '3 Years', value: 1095 }
    ];

    const [loading, setLoading] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [tokenDetails, setTokenDetails] = useState([]);
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrCodeString, setQRCodeString] = useState('');
    const [selectedTab, setSelectedTab] = useState('tokens');
    const [selectedRow, setSelectedRow] = useState({});

    const detailsObj = {
        educationScreens: true,
        systemApps: false,
        allowOffline: false,
        screenOn: false,
        wifiId: undefined,
        securityType: undefined,
        password: undefined,
        pin: undefined,
        securityOptions: 'withoutLogin',
        tokenExpiration: undefined
    };
    const [qrDetailsObj, setQrDetailObj] = useState({ ...detailsObj });
    const [qrDetails, setQrDetails] = useState(false);
    const [qrDetailss, setQrDetailss] = useState(false);

    useEffect(() => {
        if (recordID) getTokens('signintokens');
    }, []);
    const getTokens = (tab) => {
        setLoading(true);
        api.get(url.POLICIES + '/' + recordID + `/${tab}`)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    resp.data.forEach((ele) => {
                        ele.createdDate = getDateOnly(ele?.createdTime);
                        ele.expiryDate = getDateOnly(ele?.expirationTime);
                        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                        const firstDate = new Date(ele.expirationTime);
                        const secondDate = new Date();
                        const diffDays = Math.round((firstDate - secondDate) / oneDay);
                        ele.daysLeft = diffDays > 0 ? `${diffDays} Days Left` : 'Expired';
                        ele.badgeClass = diffDays <= 0 ? 'danger' : diffDays <= 15 ? 'warning' : 'success';
                        ele.showQr = diffDays > 0 ? true : false;
                    });
                    setTokenDetails(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleAddNew = () => {
        setQrDetailss(true);
    };

    const handleTokenConfirmation = (item) => {
        setLoading(true);
        setQrDetailss(false);

        let tokenBody = {
            id: recordID,
            multipleEnrollments: true,
            signIn: qrDetailsObj.securityOptions === 'withAuthentic' || qrDetailsObj?.securityOptions === 'withStatic' ? true : false,

            token: item?.enrollmentToken,
            config: {
                systemApps: !!qrDetailsObj?.systemApps,
                educationScreens: !!qrDetailsObj?.educationScreens,
                allowOffline: !!qrDetailsObj?.allowOffline,
                screenOn: !!qrDetailsObj?.screenOn,
                pin: qrDetailsObj?.pin ? qrDetailsObj?.pin : undefined,
                securityType: qrDetailsObj?.wifiId ? qrDetailsObj?.securityType?.value : undefined,
                wifiId: qrDetailsObj?.wifiId ? qrDetailsObj?.wifiId : undefined,
                password: qrDetailsObj?.password ? qrDetailsObj?.password : undefined,
                noOfDays: qrDetailsObj?.tokenExpiration?.value
            }
        };
        api.create(url.POLICIES + '/signurl', tokenBody)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    getTokens('signintokens');
                    setQRCodeString(resp.data);
                }
                setShowTokenModal(true);
                setQrDetailObj({ ...detailsObj });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleQR = (item) => {
        setQrDetailObj({
            ...qrDetailsObj,
            allowOffline: item.config?.allowOffline,
            educationScreens: item.config?.educationScreens,
            screenOn: item.config?.screenOn,
            systemApps: item.config?.systemApps,
            pin: item.config?.pin,
            securityOptions: !item.signinToken ? 'withoutLogin' : item?.config?.pin ? 'withStatic' : 'withAuthentic',
            wifiId: item.config?.wifiId || '',
            password: item.config?.password || '',
            tokenExpiration: tokenExpirationOptions?.find((opt) => opt.value === item?.config?.noOfDays)
                ? tokenExpirationOptions?.find((opt) => opt.value === item?.config?.noOfDays)
                : item?.config?.noOfDays,
            securityType: qrSecurityOptions?.find((ele) => ele.value === item.config?.securityType)
        });
        setSelectedRow(item);
        setQrDetails(true);
    };

    const handleQRConfirmation = (item) => {
        setLoading(true);
        setQrDetails(false);
        let tokenBody = {
            id: recordID,
            multipleEnrollments: true,
            signIn: qrDetailsObj.securityOptions === 'withAuthentic' || qrDetailsObj?.securityOptions === 'withStatic' ? true : false,

            token: item?.signinToken ? item?.signinToken : item?.enrollmentToken,
            config: {
                systemApps: !!qrDetailsObj?.systemApps,
                educationScreens: !!qrDetailsObj?.educationScreens,
                allowOffline: !!qrDetailsObj?.allowOffline,
                screenOn: !!qrDetailsObj?.screenOn,
                pin: qrDetailsObj?.pin ? qrDetailsObj?.pin : undefined,
                securityType: qrDetailsObj?.wifiId ? qrDetailsObj?.securityType?.value : undefined,
                wifiId: qrDetailsObj?.wifiId ? qrDetailsObj?.wifiId : undefined,
                password: qrDetailsObj?.password ? qrDetailsObj?.password : undefined,
                noOfDays: qrDetailsObj?.tokenExpiration?.value ? qrDetailsObj?.tokenExpiration?.value : qrDetailsObj?.tokenExpiration
            }
        };
        api.create(url.POLICIES + '/token/qrcode', tokenBody)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(
                        !tenant.ENABLE_SIGN_IN
                            ? toastMessages.qrGenerated
                            : item.token
                            ? toastMessages.qrGeneratedWithoutSignIn
                            : toastMessages.qrGeneratedWithSignIn
                    );
                    setShowQRModal(true);
                    setQRCodeString(resp.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const copyToClipboard = (e, index) => {
        var copyText = document.getElementById(`content${index}`)?.innerHTML;
        navigator.clipboard.writeText(copyText).then(() => {
            toast.success(toastMessages.tokenCopied);
        });
    };

    const Tooltip = (id, label) => {
        return (
            <UncontrolledTooltip placement="bottom" target={id}>
                {label}
            </UncontrolledTooltip>
        );
    };

    const handleSelectChange = (ev) => {
        // setTokenExpiration(ev.target.value);
    };

    const QrValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            securityOptions: 'withoutLogin'
        },
        onSubmit: (values) => {}
    });

    const radioButton = (field) => {
        return (
            <Row>
                {field?.label && (
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Label className="mb-3 fw-semibold fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios?.map((ele, ind) => {
                            return (
                                <Col className="form-check form-check-inline col-12 mb-2" key={ind}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={field.value}
                                        name={field.value}
                                        value={ele.val}
                                        // disabled={qrDetails}
                                        onChange={(e) => {
                                            setQrDetailObj({
                                                ...qrDetailsObj,
                                                [field.value]: e.target.value,
                                                pin: ''
                                            });
                                        }}
                                        checked={qrDetailsObj[field.value] === ele.val}
                                    />
                                    <Label className="mb-0 d-flex">
                                        {ele.label}
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={`radioButton-${ind}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`radioButton-${ind}`}>
                                            {ele?.toolTip}
                                        </UncontrolledTooltip>
                                    </Label>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>
        );
    };
    const inputComponent = (field, index) => {
        return (
            <>
                {field?.label && (
                    <Col
                        xs={field.smallLabel ? 2 : 4}
                        sm={field.smallLabel ? 2 : 4}
                        md={field.smallLabel ? 2 : 4}
                        xl={field.smallLabel ? 2 : 4}
                        lg={field.smallLabel ? 2 : 4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </Label>
                    </Col>
                )}

                <Col
                    xs={field.largeBox ? 6 : 4}
                    sm={field.largeBox ? 6 : 4}
                    md={field.largeBox ? 6 : 4}
                    xl={field.largeBox ? 6 : 4}
                    lg={field.largeBox ? 6 : 4}
                >
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                type={field.type}
                                // disabled={qrDetails}
                                maxLength={field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => setQrDetailObj({ ...qrDetailsObj, pin: e.target.value })}
                                value={qrDetailsObj?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </>
        );
    };

    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field);
            case 'input':
                return inputComponent(field);
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showKey ? flag && qrDetailsObj[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleTabContent = (tab) => {
        return (
            <Row>
                {tokenDetails?.length > 0 ? (
                    tokenDetails.map((item, index) => {
                        return (
                            <Col xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
                                <Card className="border card-height-100">
                                    <CardHeader>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="text-muted">Token ID :</div>
                                                <div className="fw-medium ms-2" id={`content${index}`}>
                                                    {item.enrollmentToken}
                                                </div>
                                                <i
                                                    id={`copy-${index}`}
                                                    onClick={(e) => copyToClipboard(e, index)}
                                                    className="ri-file-copy-line ms-2 fs-18 cursor-pointer link-secondary"
                                                ></i>
                                                {Tooltip(`copy-${index}`, 'Copy')}
                                            </div>
                                            {item.showQr && (
                                                <div>
                                                    <i
                                                        id={`qrcode-${index}`}
                                                        className="ri-qr-code-line me-2 fs-18 cursor-pointer link-secondary"
                                                        onClick={() => handleQR(item)}
                                                    />
                                                    {Tooltip(`qrcode-${index}`, 'QR Code')}
                                                </div>
                                            )}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="text-muted">Created Date :</div>
                                                <div className="fw-medium ms-2">{item.createdDate}</div>
                                            </div>
                                            {/* {selectedTab === 'tokens' && ( */}
                                            <>
                                                <div className="d-flex align-items-center">
                                                    <div className="text-muted">Expiry Date :</div>
                                                    <div className="fw-medium ms-2">{item?.expiryDate}</div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span className={'lh-lg badge badge-soft-' + item.badgeClass}>{item.daysLeft}</span>
                                                </div>
                                            </>
                                            {/* )} */}
                                        </div>
                                    </CardBody>
                                    <div
                                        className={`progress progress-bar animated-progess rounded-bottom rounded-0 height-3 bg-${item.badgeClass}`}
                                    ></div>
                                </Card>
                            </Col>
                        );
                    })
                ) : (
                    <NoRecordsFound text="Tokens" />
                )}
            </Row>
        );
    };

    const handleQrDetails = (e, key) => {
        setQrDetailObj({ ...qrDetailsObj, [key]: e.target.checked });
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                        {field.label}
                        {field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex padding-left-30">
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={field.value}
                            id={field.value}
                            // disabled={qrDetails}
                            checked={qrDetailsObj[field.value] || false}
                            onChange={(e) => handleQrDetails(e, field.value)}
                        />
                    </div>
                </Col>
            </>
        );
    };

    const handleQRModalBody = () => {
        return qrDetails || qrDetailss ? (
            <>
                <div className="border-bottom mb-2">{qrHeader()}</div>
                <div className="w-100 border-bottom">
                    <div className="fs-14 fw-semibold mb-2">Enrollment Steps :</div>
                    <Row className="mb-3">
                        {enrolmentProcess?.map((qrDetail, ind) => {
                            return (
                                <Row className="mb-2" key={ind}>
                                    {toggleButton(qrDetail, ind)}
                                </Row>
                            );
                        })}

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Wi-Fi SSID
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-SSID'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-SSID'}>
                                    {`
									1. configured Wi-Fi network should be used during device provisioning and connect automatically.
									2. Skipping Wi-Fi isn't allowed.
									`}
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'wifiId'}
                                    id={'wifiId'}
                                    // disabled={qrDetails}
                                    className={'form-control'}
                                    placeholder={'Enter Wi-Fi SSID'}
                                    type={'text'}
                                    onChange={(e) => setQrDetailObj({ ...qrDetailsObj, wifiId: e.target.value })}
                                    value={qrDetailsObj.wifiId}
                                />
                            </div>
                        </Col>

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Security
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-Security'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-Security'}>
                                    Security
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={7}
                            xl={7}
                            className={`${
                                qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none'
                                    ? 'mb-2'
                                    : ''
                            }`}
                        >
                            <div className="form-check">
                                <Select
                                    getOptionValue={(option) => option.label}
                                    getOptionLabel={(option) => option.label}
                                    id="securityType"
                                    name="securityType"
                                    // isDisabled={qrDetails}
                                    classNamePrefix={'custom-select w-100'}
                                    options={qrSecurityOptions}
                                    placeholder="Select Security"
                                    onChange={(selectedOption) => setQrDetailObj({ ...qrDetailsObj, securityType: selectedOption })}
                                    value={qrDetailsObj.securityType || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>

                        {qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none' && (
                            <>
                                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                        Password
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={'toggleButton-Password'}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={'toggleButton-Password'}>
                                            Password
                                        </UncontrolledTooltip>
                                    </Label>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={7} xl={7}>
                                    <div className="form-check ">
                                        <Input
                                            type={'text'}
                                            id={'password'}
                                            name={'password'}
                                            disabled={qrDetails}
                                            className={'form-control'}
                                            placeholder={'Enter password'}
                                            onChange={(e) => setQrDetailObj({ ...qrDetailsObj, password: e.target.value })}
                                            value={qrDetailsObj.password}
                                        />
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
                <div className="mt-2">
                    {AndroidPolicyQR?.map((field, index) => {
                        return (
                            <React.Fragment key={index}>
                                {(field?.showOn ? handleShowOn(field) : true) && dynamicComponent(field)}
                            </React.Fragment>
                        );
                    })}
                </div>
            </>
        ) : (
            <Row className="p-4 d-flex align-items-center justify-content-center">
                <div className="text-center mt-2">
                    <h5 className="text-primary">QR Code</h5>
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        Please scan for the device enrollment
                    </Alert>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                </div>
            </Row>
        );
    };

    const qrHeader = () => {
        return (
            <>
                <div className="d-flex align-items-center ">
                    <Label className="p-0 m-0 fw-semibold">Policy :</Label>
                    <p className="p-0 m-0 ms-2 fw-normal">{policyName}</p>
                </div>
                <div className="mb-3  mt-2">
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xs={12} sm={12} md={12} xl={5} lg={5}>
                            <Label className="form-label d-flex align-items-center fw-medium">
                                Token Expiration <span className="red-color ps-1">*</span>
                            </Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={7} lg={7}>
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                isMulti={false}
                                isClearable={false}
                                isDisabled={qrDetails}
                                id="tokenExpiration"
                                name="tokenExpiration"
                                options={tokenExpirationOptions}
                                placeholder="Select Token Expiration"
                                onChange={(selectedOption) => setQrDetailObj({ ...qrDetailsObj, tokenExpiration: selectedOption })}
                                value={qrDetailsObj?.tokenExpiration || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className="mb-3 mt-2">
                    <div className="d-flex align-items-center justify-content-start">
                        <span className="fw-semibold pe-3">Token Expiration :</span>
                        <span className={`text-${selectedRow?.daysLeft?.split(' ')?.[0] > 30 ? 'primary' : 'danger'}`}>
                            {selectedRow?.daysLeft}
                        </span>
                    </div>
                </div> */}
            </>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={'Enrollment Token'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="aepolicies"
                    />
                    <Card className="card-height-100">
                        <CardHeader>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Label className="p-0 m-0 mt-3 fw-semibold fs-16">Policy :</Label>
                                    <p className="p-0 m-0 mt-3 ms-2 fw-normal fs-14">{policyName}</p>
                                </div>
                                <Button type="button" color="success" onClick={handleAddNew}>
                                    + Create New Token
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {/* {tenant.ENABLE_SIGN_IN ? (
                                <TabsComponent tabsList={tabsList} defaultTabID={selectedTab} toggleTab={(tab) => handleSelectedTab(tab)} />
                            ) : ( */}
                            {handleTabContent()}
                            {/* )} */}
                        </CardBody>
                    </Card>
                </Container>
                {/* <DeleteModal
                    hideDeleteIcon={true}
                    show={showTokenModal}
                    confirmation={true}
                    onDeleteClick={handleTokenConfirmation}
                    onCloseClick={() => setShowTokenModal(false)}
                    deleteMessage={'Are you sure you want to create new enrollment token ?'}
                    customBody={true}
                    disabled={(selectedTab === 'tokens' ? !tokenExpiration : false) || loading}
                    deleteBody={handleTokenBody()}
                /> */}
                {/* <CommonModal
                    size={'md'}
                    show={showQRModal || qrDetails}
                    disabled={false}
                    hideSaveButton={qrDetails ? false : true}
                    modalheader={qrHeader()}
                    onCloseClick={() => {
                        setShowQRModal(false);
                        setQrDetails(false);
                        setSelectedRow('');
                        setQrDetailObj({
                            educationScreens: true,
                            systemApps: false,
                            allowOffline: false,
                            screenOn: false,
                            wifiId: undefined,
                            securityType: undefined,
                            password: undefined
                        });
                    }}
                    handleClick={() => handleQRConfirmation(selectedRow)}
                    handleModalBody={() => handleQRModalBody()}
                /> */}
                {/* <OffcanvasModal
                    direction="end"
                    toggle={() => {
                        setShowQRModal(false);
                        setQrDetails(false);
                        setSelectedRow('');
                        setTokenExpiration('');
                    }}
                    open={showQRModal || qrDetails}
                    handleCloseClick={() => {
                        setShowQRModal(false);
                        setQrDetails(false);
                        setSelectedRow('');
                        setQrDetailObj({
                            educationScreens: true,
                            systemApps: false,
                            allowOffline: false,
                            screenOn: false,
                            wifiId: undefined,
                            securityType: undefined,
                            password: undefined,
                            pin: undefined,
                            securityOptions: 'withoutLogin'
                        });
                        setTokenExpiration('');
                    }}
                    OffcanvasModalID="Qr_details"
                    hideSaveButton={qrDetails ? false : true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={() => handleQRModalBody()}
                    modalClassName={'w-40'}
                    saveText={'Generate QR'}
                    offcanvasHeader={'Enrollment'}
                    handleSaveClick={() => handleQRConfirmation(selectedRow)}
                /> */}
                <OffcanvasModal
                    direction="end"
                    toggle={() => {
                        if (qrDetailss || showTokenModal) {
                            setShowTokenModal(false);
                            setQrDetailss(false);
                            setQrDetailObj({ ...detailsObj });
                        } else {
                            setShowQRModal(false);
                            setQrDetails(false);
                        }
                        setSelectedRow('');
                    }}
                    open={showTokenModal || qrDetailss || showQRModal || qrDetails}
                    handleCloseClick={() => {
                        if (qrDetailss || showTokenModal) {
                            setShowTokenModal(false);
                            setQrDetailss(false);
                        } else {
                            setShowQRModal(false);
                            setQrDetails(false);
                        }
                        setSelectedRow('');
                        setQrDetailObj({ ...detailsObj });
                    }}
                    OffcanvasModalID="Qr_details"
                    hideSaveButton={qrDetailss || qrDetails ? false : true}
                    closeButtonBorder={true}
                    handleOffcanvasBody={() => handleQRModalBody()}
                    modalClassName={'w-40'}
                    saveText={'Generate QR'}
                    offcanvasHeader={'Enrollment'}
                    saveDisabled={
                        qrDetailss
                            ? !qrDetailsObj.tokenExpiration || qrDetailsObj.securityOptions === 'withStatic'
                                ? qrDetailsObj?.pin?.length !== 4
                                : false
                            : false
                    }
                    handleSaveClick={() => (qrDetailss ? handleTokenConfirmation(selectedRow) : handleQRConfirmation(selectedRow))}
                />
            </div>
        </React.Fragment>
    );
};

export default EnrollmentToken;
