import React, { useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import { WIFISCHEMA, WIFIFENCE_ACTIONS } from '../../../Components/constants/constants';
import { useFormik } from 'formik';
import Select from 'react-select';
import { sortBy } from '../../../Components/Common/Util';
import ADDWIFI from '../../../assets/images/addWifi.png';
import { DynamicComponents } from '../Actions';
import Assigning from '../Assigning';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AddWifiFence = () => {
    const history = useHistory();
    const [wifiArray, setWifiArray] = useState([]);
    const [isEdit, setISEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [groups, setGroups] = useState([]);
    const [flatGroups, setFlatGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const Schema = [
        { label: 'Wifi Name', accessor: 'wifiName' },
        { label: 'SSID', accessor: 'ssid' },
        { label: 'Security', accessor: 'security' }
    ];
    const formType = '';

    const data = { wifiName: 'Tectoro Wi-Fi', ssid: '32398493324324', security: '32398493324324' };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            configurationName: '',
            wifiName: '',
            ssid: '',
            security: ''
        },
        onSubmit: (values) => {
            if (isEdit) {
                let newWifi = [...wifiArray];
                newWifi.splice(editIndex, 1, values);
                setWifiArray(newWifi);
                setISEdit(false);
                setEditIndex(null);
            } else {
                const newWifi = [...wifiArray, values];
                setWifiArray(newWifi);
            }
            validation.resetForm();
        }
    });

    const handleAddWifi = () => {
        validation.handleSubmit();
    };

    const handleEditWifi = (wifi, ind) => {
        validation.setValues({ ...validation.values, wifiName: wifi.wifiName, ssid: wifi.ssid, security: wifi.security });
        setEditIndex(ind);
        setISEdit(true);
    };

    const handleDeleteWifi = (ind) => {
        let obj = [...wifiArray];
        obj.splice(ind, 1);
        setWifiArray(obj);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };
    const selectComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={12} lg={12}>
                        <Label className={'mb-1 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word mb-1 fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                        {formType !== 'view' ? (
                            <Select
                                getOptionValue={(option) => (field.optionVal ? option[field.optionVal] : option.value)}
                                getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                                isMulti={field.isMulti ? true : false}
                                isClearable={field.isMulti ? true : false}
                                id={field.label}
                                name={field.value}
                                options={field.optionsKey ? sortBy([field.optionsKey], field.optionLabel) : field.options}
                                placeholder={`Select ${field.label}`}
                                onBlur={() => validation.handleBlur({ target: { name: field.value } })}
                                onChange={(selectedOption) => {
                                    validation.handleChange({ target: { name: field.value, value: selectedOption } });
                                }}
                                value={validation?.values[field.value] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        ) : validation?.values?.[field.value]?.length > 0 ? (
                            validation?.values?.[field.value]
                                ?.map((v) => v.label)
                                ?.toString()
                                ?.split(',')
                                ?.join(', ')
                        ) : validation?.values[field.value]?.label ? (
                            validation?.values[field.value]?.label
                        ) : validation?.values[field.value] ? (
                            validation?.values[field.value]
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={12} lg={12}>
                        <Label className={'mb-1 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word fs-11 mb-1">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                        <div className="input-group">
                            {formType !== 'view' ? (
                                <Input
                                    name={field.value}
                                    id={field.value}
                                    className="form-control"
                                    placeholder={`Enter ${field.label}`}
                                    type={field.type}
                                    validate={{ required: { value: true } }}
                                    maxLength={field.maxLength}
                                    onChange={validation?.handleChange}
                                    onBlur={validation?.handleBlur}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={validation?.values[field?.value] || ''}
                                    invalid={validation?.touched?.[field?.value] && validation?.errors?.[field?.value] ? true : false}
                                />
                            ) : validation?.values[field.value] ? (
                                validation?.values[field.value]
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const handleFields = (field, index) => {
        return field.inputType === 'input'
            ? inputComponent(field, index)
            : field.inputType === 'select'
            ? selectComponent(field, index)
            : '';
    };

    const stepperData = [
        {
            title: 'Configuration',
            icon: 1,
            disabled: false,
            renderTab: (
                <React.Fragment>
                    <div className="fw-semibold mt-3">Wi-Fi Configuration :</div>
                    <div className="text-muted mb-3 fs-11">
                        Wi-Fi fencing allows administrators to enforce rules or restrictions on devices based on predefined Wi-Fi network
                        parameters or SSID criteria.
                    </div>
                    <Row className="bg-grey-1 p-1 rounded-3">
                        <Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7} className="mt-3 pe-5 min-height-408">
                            <div className="border border-1 rounded-3 p-3 bg-white">
                                <div>
                                    <span className="fw-semibold fs-12">Note : </span>
                                    <span className="fs-10">
                                        Wi-Fi fencing allows administrators to enforce rules or restrictions on devices based on predefined
                                        Wi-Fi network parameters or SSID criteria.
                                    </span>
                                </div>
                                {WIFISCHEMA?.map((fieldObj, ind) => handleFields(fieldObj, ind))}
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button
                                        className="bg-soft-success text-success fw-semibold fs-14 border-0"
                                        onClick={handleAddWifi}
                                        disabled={
                                            validation.values.wifiName === '' ||
                                            validation.values.ssid === '' ||
                                            validation.values.security === ''
                                        }
                                    >
                                        {isEdit ? 'Update' : '+ Add'}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        {wifiArray.length > 0 && (
                            <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5} className="mt-3">
                                <div className="fw-semibold mb-2 fs-14">Configured SSIDs</div>
                                {wifiArray.map((wifi, ind) => (
                                    <Card key={ind} className="px-4 py-3 mb-4 rounded-3">
                                        <Row>
                                            <Col className="d-flex justify-content-start col-10 py-1 px-2">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img src={ADDWIFI} alt="img" height={30} width={30} />
                                                </div>
                                                <div className="px-4">
                                                    <div className="fw-medium mb-1">Wifi Name</div>
                                                    <div className="text-muted fs-12 width-100 overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                        {wifi.wifiName}
                                                    </div>
                                                </div>
                                                <div className="pe-4">
                                                    <div className="fw-medium mb-1">SSID</div>
                                                    <div className="text-muted fs-12 width-100 overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                        {wifi.ssid}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="fw-medium mb-1">Security</div>
                                                    <div className="text-muted fs-12 width-100 overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                                        {wifi.security.value}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="d-flex justify-content-end align-items-center col-2">
                                                <UncontrolledDropdown className="">
                                                    <DropdownToggle
                                                        tag="button"
                                                        type="button"
                                                        className="btn btn-link text-muted text-decoration-none p-0"
                                                    >
                                                        <i className="ri-more-fill fs-18 fw-medium" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem onClick={() => handleEditWifi(wifi, ind)}>
                                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDeleteWifi(ind)}>
                                                            <i className="ri-delete-bin-6-line align-bottom me-2 text-muted"></i> Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            )
        },
        {
            title: 'Actions',
            icon: 2,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        {WIFIFENCE_ACTIONS?.map((field, index) => (
                            <DynamicComponents key={index} field={field} index={index} formType={''} validation={validation} />
                        ))}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Assignment',
            icon: 3,
            disabled: false,
            renderTab: (
                <Row>
                    <Col md={6}>
                        <div className="fw-semibold mt-3">
                            Assign Group<span className="text-danger px-1">*</span>
                        </div>
                        <div className="text-muted mb-3 fs-11">Please specify the group to which the configurations should apply </div>
                        <Assigning groups={groups} flatGroups={flatGroups} selectedGroups={selectedGroups} />
                    </Col>
                </Row>
            )
        }
    ];

    const handleSave = () => {
        history.push('/wififence');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb pageTitle="Add Wifi Fence" history={''} homeLink="Dashboard" />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">Wifi-Fence</div>
                            <div className="fs-13">
                                A Wi-Fi fence in EMM (Enterprise Mobility Management) is a virtual boundary created using Wi-Fi signals to
                                control and monitor devices within a specific area, enhancing security and management capabilities for
                                enterprise networks.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name="configurationName"
                                            id={'configurationName'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['configurationName'] || ''}
                                            invalid={
                                                validation.touched['configurationName'] && validation.errors['configurationName']
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                ) : validation?.values['configurationName'] ? (
                                    <span className="ps-1">{validation?.values['configurationName']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['configurationName'] && validation.errors['configurationName'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['configurationName']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomStepperComponent stepperData={stepperData} enableSaveBtn={true} handleSave={handleSave} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddWifiFence;
