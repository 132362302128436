/* eslint-disable max-lines */
import { options } from '../../CommonFeatures/AppProtectionPolicy/Schema';

export const PoliciesFieldsSchema = [
    {
        label: 'General Settings',
        icon: 'ri-settings-5-line',
        active: true,
        fields: [
            {
                label: 'Allow fun game',
                value: 'funDisabled',
                helpText: 'Controls user access on playing fun games upon tapping on Android version',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Camera Access',
                value: 'cameraAccess',
                inputType: 'select',
                helpText: 'Controls user access on camera',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile'],
                options: [
                    { label: 'Unspecific', value: 'CAMERA_ACCESS_UNSPECIFIED' },
                    { label: 'User Choice', value: 'CAMERA_ACCESS_USER_CHOICE' },
                    { label: 'Disable', value: 'CAMERA_ACCESS_DISABLED' },
                    { label: 'Enforce', value: 'CAMERA_ACCESS_ENFORCED' }
                ]
            },
            {
                label: 'Allow Auto Timezone',
                value: 'autoDateAndTimeZone',
                helpText: 'Controls user access on auto timezone',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'AUTO_DATE_AND_TIME_ZONE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'AUTO_DATE_AND_TIME_ZONE_USER_CHOICE' },
                    { label: 'Enforce', value: 'AUTO_DATE_AND_TIME_ZONE_ENFORCED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Stay On Plugged Modes',
                value: 'stayOnPluggedModes',
                helpText: 'The plugged mode through which the device stays awake',
                inputType: 'select',
                isMulti: true,
                options: [
                    { label: 'Battery Plugged Mode Unspecified', value: 'BATTERY_PLUGGED_MODE_UNSPECIFIED' },
                    { label: 'AC', value: 'AC' },
                    { label: 'USB', value: 'USB' },
                    { label: 'Wireless', value: 'WIRELESS' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'FRP Admin Emails',
                value: 'frpAdminEmail',
                inputType: 'chips',
                list: 'frpAdminEmails',
                validator: 'email',
                invalidMsg: 'Please enter valid mail ID',
                helpText: 'Given Email Id needs to be provided after hard factory reset to re-use the device.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            /*
             * {
             *     isHeader: true,
             *     label: 'Personal Profile Settings',
             *     profileType: ['Work Profile']
             * },
             * {
             *     label: 'Allow Camera on Personal Profile',
             *     value: 'personalCameraDisabled',
             *     parentValue: 'personalUsagePolicies',
             *     helpText: 'Controls user access on camera in Personal Profile',
             *     inputType: 'toggle',
             *     profileType: ['Work Profile']
             * },
             * {
             *     label: 'Allow Screen Capture on Personal Profile',
             *     value: 'personalScreenCaptureDisabled',
             *     parentValue: 'personalUsagePolicies',
             *     helpText: 'Controls user access on capturing screen in Personal Profile',
             *     inputType: 'toggle',
             *     profileType: ['Work Profile']
             * },
             * {
             *     label: 'Max Days With Work Off',
             *     value: 'maxDaysWithWorkOff',
             *     parentValue: 'personalUsagePolicies',
             *     inputType: 'slider',
             *     minVal: 0,
             *     maxVal: 10,
             *     step: 1,
             *     formatLabel: 'days',
             *     helpText: 'Controls how long the work profile can stay off.',
             *     profileType: ['Work Profile']
             * },
             * {
             *     label: 'Personal Play Store Mode',
             *     value: 'personalPlayStoreMode',
             *     parentValue: 'personalUsagePolicies',
             *     inputType: 'select',
             *     helpText:
             *         'On selection Whitelist, user can view only the apps pushed by the Admin. On selection of Blacklist, user can view all the apps in the playstore',
             *     options: [
             *         { label: 'Play Store Mode Unspecified', value: 'PLAY_STORE_MODE_UNSPECIFIED' },
             *         { label: 'Allow List', value: 'ALLOWLIST' },
             *         { label: 'Black List', value: 'BLACKLIST' }
             *     ],
             *     profileType: ['Work Profile']
             * },
             */
            {
                isHeader: true,
                label: 'Cross Profile Settings',
                profileType: ['Work Profile']
            },
            {
                label: 'Cross Profile Copy Paste',
                value: 'crossProfileCopyPaste',
                parentValue: 'crossProfilePolicies',
                helpText: 'Controls the user access to copy the text from Work profile to Personal profile and vice versa',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'CROSS_PROFILE_COPY_PASTE_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'COPY_FROM_WORK_TO_PERSONAL_DISALLOWED' },
                    { label: 'Allowed', value: 'CROSS_PROFILE_COPY_PASTE_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Cross Profile Data Sharing',
                value: 'crossProfileDataSharing',
                parentValue: 'crossProfilePolicies',
                helpText: 'Controls the user access to share the data from Personal to Work profile and vice versa.',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'CROSS_PROFILE_DATA_SHARING_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'CROSS_PROFILE_DATA_SHARING_DISALLOWED' },
                    { label: 'Personal Disallowed', value: 'DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED' },
                    { label: 'Allowed', value: 'CROSS_PROFILE_DATA_SHARING_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Show Work Contacts in Personal Profile',
                value: 'showWorkContactsInPersonalProfile',
                parentValue: 'crossProfilePolicies',
                helpText: 'Controls the user access to view contacts in Personal profile which has been stored in Work profile',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED' },
                    { label: 'Allowed', value: 'SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Allowed Work Profile Widgets',
                value: 'workProfileWidgetsDefault',
                parentValue: 'crossProfilePolicies',
                helpText: 'Admin controls the widgets shown in Work profile to be reflected in Personal Profile and vice versa',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'WORK_PROFILE_WIDGETS_DEFAULT_UNSPECIFIED' },
                    { label: 'Allowed', value: 'WORK_PROFILE_WIDGETS_DEFAULT_ALLOWED' },
                    { label: 'Disallowed', value: 'WORK_PROFILE_WIDGETS_DEFAULT_DISALLOWED' }
                ],
                profileType: ['Work Profile']
            }
        ]
    },
    {
        label: 'Launcher Settings',
        icon: 'ri-android-line',
        active: false,
        appFields: [
            {
                label: 'Installation Type',
                value: 'installType',
                inputType: 'text',
                parentValue: 'applications',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile'],
                helpText: 'Installation Type',
                val: { label: 'Kiosk', value: 'KIOSK' }
            },
            {
                label: 'Default Permission Policy',
                value: 'defaultPermissionPolicy',
                inputType: 'select',
                parentValue: 'applications',
                helpText: 'The default policy for all permissions requested by the app.',
                options: [
                    { label: 'Unspecific', value: 'PERMISSION_POLICY_UNSPECIFIED' },
                    { label: 'Prompt', value: 'PROMPT' },
                    { label: 'Grant', value: 'GRANT' },
                    { label: 'Deny', value: 'DENY' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Disable',
                value: 'disabled',
                inputType: 'toggle',
                helpText: 'Disable',
                parentValue: 'applications',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Delegated Scopes',
                value: 'delegatedScopes',
                inputType: 'select',
                isMulti: true,
                parentValue: 'applications',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile'],
                helpText: 'Delegated Scopes',
                options: [
                    { label: 'Delegated Scope Unspecified', value: 'DELEGATED_SCOPE_UNSPECIFIED' },
                    { label: 'Cert Install', value: 'CERT_INSTALL' },
                    { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                    { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                    { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                    { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                    { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' },
                    { label: 'Network Activity Logs', value: 'NETWORK_ACTIVITY_LOGS' },
                    { label: 'Security Logs', value: 'SECURITY_LOGS' },
                    { label: 'Cert Selection', value: 'CERT_SELECTION' }
                ]
            },
            {
                label: 'App Auto Update Mode',
                value: 'autoUpdateMode',
                inputType: 'select',
                parentValue: 'applications',
                helpText: 'Whether App Auto Update Mode is enabled.',
                options: [
                    { label: 'Unspecific', value: 'AUTO_UPDATE_MODE_UNSPECIFIED' },
                    { label: 'Default', value: 'AUTO_UPDATE_DEFAULT' },
                    { label: 'Postponed', value: 'AUTO_UPDATE_POSTPONED' },
                    { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Additional Permissions',
                value: 'permissionGrants',
                isMulti: true,
                inputType: 'select',
                parentValue: 'applications',
                objectKey: 'permission',
                helpText: 'Additional Permissions',
                options: [
                    { label: 'Manage External Storage', value: 'MANAGE_EXTERNAL_STORAGE' },
                    { label: 'Package Usage Stats', value: 'PACKAGE_USAGE_STATS' },
                    { label: 'Read Call Log', value: 'READ_CALL_LOG' },
                    { label: 'Read Contacts', value: 'READ_CONTACTS' },
                    { label: 'Read External Storage', value: 'READ_EXTERNAL_STORAGE' },
                    { label: 'Read Phone State', value: 'READ_PHONE_STATE' },
                    { label: 'Read Privileged Phone State', value: 'READ_PRIVILEGED_PHONE_STATE' },
                    { label: 'Receive Boot Completed', value: 'RECEIVE_BOOT_COMPLETED' },
                    { label: 'Use ICC Auth with Device Identifier', value: 'USE_ICC_AUTH_WITH_DEVICE_IDENTIFIER' },
                    { label: 'Write Contacts', value: 'WRITE_CONTACTS' },
                    { label: 'Write External Storage', value: 'WRITE_EXTERNAL_STORAGE' },
                    { label: 'Access Coarse Location', value: 'ACCESS_COARSE_LOCATION' },
                    { label: 'Access Fine Location', value: 'ACCESS_FINE_LOCATION' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Extension Config',
                value: 'extensionConfig',
                inputType: 'toggle',
                helpText:
                    'Configuration to enable an app as an extension app, with the capability of interacting with Android Device Policy offline. For Android versions 13 and above, extension apps are exempt from battery restrictions so will not be placed into the restricted App Standby Bucket. Extensions apps are also protected against users clearing their data or force-closing the application, although admins can continue to use the clear app data command on extension apps if needed for Android 13 and above.',
                objectKey: 'extensionConfig',
                parentValue: 'applications',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'VPN Exemption',
                value: 'alwaysOnVpnLockdownExemption',
                inputType: 'select',
                removable: true,
                helpText: 'Enabling this will make the application VPN free.',
                options: [
                    { label: 'Always on VPN lockdown excemption unspecified', value: 'ALWAYS_ON_VPN_LOCKDOWN_EXEMPTION_UNSPECIFIED' },
                    { label: 'VPN lockdown enforced', value: 'VPN_LOCKDOWN_ENFORCED' },
                    { label: 'VPN lockdown exemption', value: 'VPN_LOCKDOWN_EXEMPTION' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile']
            },
            {
                label: 'Track',
                value: 'accessibleTrackIds',
                inputType: 'select',
                removable: true,
                helpText: 'Select the tracks.',
                optionsVal: 'tracks',
                optionLabel: 'trackAlias',
                optionValue: 'trackId',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Managed Configuration',
                value: 'managedConfigId',
                inputType: 'select',
                removable: true,
                helpText: 'Select the maanged configuration.',
                optionsVal: 'managedConfigs',
                optionLabel: 'title',
                optionValue: 'managedConfigId',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Permitted Accessibility Service',
                value: 'permittedAccessibilityServices',
                inputType: 'toggle',
                helpText: 'Permitted Accessibility Service',
                objectKey: 'packageNames',
                parentValue: 'applications',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Connected Work And Personal App',
                value: 'connectedWorkAndPersonalApp',
                inputType: 'select',
                parentValue: 'applications',
                helpText:
                    'Controls whether the app can communicate with itself across a device’s work and personal profiles, subject to user consent.',
                options: [
                    { label: 'Unspecific', value: 'CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED' },
                    { label: 'Allowed', value: 'CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Work Profile Widgets',
                value: 'workProfileWidgets',
                inputType: 'select',
                parentValue: 'applications',
                helpText: 'Specifies whether the app installed in the work profile is allowed to add widgets to the home screen.',
                options: [
                    { label: 'Unspecific', value: 'WORK_PROFILE_WIDGETS_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'WORK_PROFILE_WIDGETS_DISALLOWED' },
                    { label: 'Allowed', value: 'WORK_PROFILE_WIDGETS_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Credential Provider Policy (from Android 14 and above)',
                value: 'credentialProviderPolicy',
                inputType: 'select',
                helpText: 'Whether the app is allowed to act as a credential provider on Android 14 and above.',
                options: [
                    { label: 'Unspecified', value: 'CREDENTIAL_PROVIDER_POLICY_UNSPECIFIED' },
                    { label: 'Allowed', value: 'CREDENTIAL_PROVIDER_ALLOWED' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'User Control Settings (from Android 11 and above)',
                value: 'userControlSettings',
                helpText:
                    'Specifies whether user control is permitted for the app. User control includes user actions like force-stopping and clearing app data. Supported on Android 11 and above.',
                inputType: 'select',
                options: [
                    { label: 'Unspecified', value: 'USER_CONTROL_SETTINGS_UNSPECIFIED' },
                    { label: 'Allowed', value: 'USER_CONTROL_ALLOWED' },
                    { label: 'Disallowed', value: 'USER_CONTROL_DISALLOWED' }
                ],
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            }
        ],
        fields: [
            {
                isHeader: true,
                label: 'Kiosk Settings',
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Power Button Actions',
                value: 'powerButtonActions',
                parentValue: 'kioskCustomization',
                inputType: 'select',
                removable: true,
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Unspecify Power Button Actions', value: 'POWER_BUTTON_ACTIONS_UNSPECIFIED' },
                    { label: 'Avail Power Button', value: 'POWER_BUTTON_AVAILABLE' },
                    { label: 'Block Power Button', value: 'POWER_BUTTON_BLOCKED' }
                ],
                helpText: 'Control user access on Power Button',
                normal: true
            },
            {
                label: 'System Error Warnings',
                value: 'systemErrorWarnings',
                parentValue: 'kioskCustomization',
                inputType: 'select',
                removable: true,
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Unspecify System Error Warnings', value: 'SYSTEM_ERROR_WARNINGS_UNSPECIFIED' },
                    { label: 'Enable System Error Warnings', value: 'ERROR_AND_WARNINGS_ENABLED' },
                    { label: 'Mute System Error Warnings', value: 'ERROR_AND_WARNINGS_MUTED' }
                ],
                helpText: 'Controls the pop-ups of System Error Warnings',
                normal: true
            },
            {
                label: 'System Navigation',
                value: 'systemNavigation',
                parentValue: 'kioskCustomization',
                inputType: 'select',
                removable: true,
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Unspecify System Navigation', value: 'SYSTEM_NAVIGATION_UNSPECIFIED' },
                    { label: 'Enable System Navigation', value: 'NAVIGATION_ENABLED' },
                    { label: 'Disable System Navigation', value: 'NAVIGATION_DISABLED' },
                    { label: 'System Navigation Home Button Only', value: 'HOME_BUTTON_ONLY' }
                ],
                helpText: 'Controls user interaction with device interface',
                normal: true
            },
            {
                label: 'Status Bar',
                value: 'statusBar',
                parentValue: 'kioskCustomization',
                inputType: 'select',
                removable: true,
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Unspecify Status Bar', value: 'STATUS_BAR_UNSPECIFIED' },
                    { label: 'Enable Notifications and System Info', value: 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED' },
                    { label: 'Disable Notifications and System Info', value: 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED' },
                    { label: 'System Info Only', value: 'SYSTEM_INFO_ONLY' }
                ],
                helpText: 'Controls the information displayed such as the time, battery level, connectivity status and notifications ',
                normal: true
            },
            {
                label: 'Device Settings',
                value: 'deviceSettings',
                parentValue: 'kioskCustomization',
                inputType: 'select',
                removable: true,
                profileType: ['Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Unspecify Device Settings', value: 'DEVICE_SETTINGS_UNSPECIFIED' },
                    { label: 'Allow Settings Access', value: 'SETTINGS_ACCESS_ALLOWED' },
                    { label: 'Block Settings Access', value: 'SETTINGS_ACCESS_BLOCKED' }
                ],
                helpText: 'Controls user access to modify system settings',
                normal: true
            }
        ]
    },
    /*
     * {
     *     label: 'Display Settings',
     *     icon: 'ri-article-line',
     *     active: false,
     *     fields: [
     *         {
     *             label: 'Allow user to change wallpaper',
     *             value: 'setWallpaperDisabled',
     *             helpText: 'Whether changing the wallpaper',
     *             inputType: 'toggle',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher']
     *         },
     *         {
     *             label: 'Lock screen Owner Details',
     *             value: 'deviceOwnerLockScreenInfo',
     *             helpText: 'The device owner information to be shown on the lock screen',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Single App'],
     *             type: 'text',
     *             objectKey: 'defaultMessage',
     *             inputType: 'input'
     *         },
     *         {
     *             label: 'Short Support Message',
     *             value: 'shortSupportMessage',
     *             helpText:
     *                 'A message displayed to the user in the settings screen wherever functionality has been disabled by the admin. If the message is longer than 200 characters it may be truncated.',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Single App'],
     *             type: 'text',
     *             objectKey: 'defaultMessage',
     *             inputType: 'input'
     *         },
     *         {
     *             label: 'Long Support Message',
     *             value: 'longSupportMessage',
     *             helpText: 'A message displayed to the user in the device administators settings screen.',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Single App'],
     *             type: 'text',
     *             objectKey: 'defaultMessage',
     *             inputType: 'input'
     *         },
     *         {
     *             label: 'Skip First Use Hints Enabled',
     *             value: 'skipFirstUseHintsEnabled',
     *             inputType: 'toggle',
     *             helpText: 'Flag to skip hints on the first use',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Work Profile', 'Single App']
     *         },
     *         {
     *             label: 'Allow Set User Icon',
     *             value: 'setUserIconDisabled',
     *             helpText: 'Whether changing the user icon is disabled.',
     *             inputType: 'toggle',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Single App']
     *         },
     *         {
     *             label: 'Screen Time Out',
     *             value: 'maximumTimeToLock',
     *             inputType: 'select',
     *             profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Single App'],
     *             options: [
     *                 { label: 'No Restriction', value: '0' },
     *                 { label: '15 Seconds', value: '15000' },
     *                 { label: '30 Seconds', value: '30000' },
     *                 { label: '1 Minute', value: '60000' },
     *                 { label: '5 Minutes', value: '300000' }
     *             ],
     *             helpText:
     *                 'Maximum time in milliseconds for user activity until the device locks. A value of 0 means there is no restriction.'
     *         },
     *     ]
     * },
     */
    {
        label: 'Password Settings',
        icon: 'ri-phone-lock-line',
        active: false,
        fields: [
            {
                label: 'Password Quality',
                value: 'passwordQuality',
                inputType: 'select',
                parentValue: 'passwordPolicies',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                helpText: 'Specifies the password type required for user authentication at the device level.',
                options: [
                    { label: 'Password Quality Unspecified', value: 'PASSWORD_QUALITY_UNSPECIFIED' },
                    { label: 'Biometric Weak', value: 'BIOMETRIC_WEAK' },
                    { label: 'Something', value: 'SOMETHING' },
                    { label: 'Numeric', value: 'NUMERIC' },
                    { label: 'Numeric Complex', value: 'NUMERIC_COMPLEX' },
                    { label: 'Alphabetic', value: 'ALPHABETIC' },
                    { label: 'Alphanumeric', value: 'ALPHANUMERIC' },
                    { label: 'Complex', value: 'COMPLEX' }
                ]
            },
            /*
             * {
             *     label: 'Unified Lock Settings',
             *     value: 'unifiedLockSettings',
             *     inputType: 'select',
             *     parentValue: 'passwordPolicies',
             *     profileType: ['Work Profile'],
             *     helpText:
             *         'Controls whether a unified lock is allowed for the device and the work profile, on devices running Android 9 and above with a work profile. This has no effect on other devices.',
             *     options: [
             *         { label: 'Unified Lock Settings Unspecified', value: 'UNIFIED_LOCK_SETTINGS_UNSPECIFIED' },
             *         { label: 'Allow Unified Work and Personal Lock', value: 'ALLOW_UNIFIED_WORK_AND_PERSONAL_LOCK' },
             *         { label: 'Require Separate Work Lock', value: 'REQUIRE_SEPARATE_WORK_LOCK' }
             *     ]
             * },
             */
            {
                label: 'Max Password History',
                value: 'passwordHistoryLength',
                parentValue: 'passwordPolicies',
                inputType: 'slider',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                minVal: 0,
                maxVal: 10,
                helpText:
                    // eslint-disable-next-line quotes
                    'Choose the maximum number of previous passcodes stored in history that cannot be reused by an end user.'
            },
            {
                label: 'Password Expiration Timeout',
                value: 'passwordExpirationTimeout',
                parentValue: 'passwordPolicies',
                inputType: 'slider',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                minVal: 0,
                maxVal: 10,
                step: 0.5,
                formatLabel: 's',
                helpText: 'Choose the maximum duration for which the passcode remains active.'
            },
            {
                label: 'Maximum Failed Passwords For Wipe',
                value: 'maximumFailedPasswordsForWipe',
                parentValue: 'passwordPolicies',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                inputType: 'slider',
                minVal: 0,
                maxVal: 10,
                helpText:
                    'Secure the device by automatically wiping it after a specified number of unsuccessful attempts to prevent unauthorized access.'
            },
            {
                label: 'Credential Provider Policy Default',
                value: 'credentialProviderPolicyDefault',
                helpText: 'Controls which apps are allowed to act as credential providers on Android 14 and above',
                inputType: 'select',
                options: [
                    { label: 'Unspecified', value: 'CREDENTIAL_PROVIDER_POLICY_DEFAULT_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED' },
                    { label: 'Disallowed except system', value: 'CREDENTIAL_PROVIDER_DEFAULT_DISALLOWED_EXCEPT_SYSTEM' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            }
        ]
    },
    {
        label: 'Communication Settings',
        icon: 'ri-chat-settings-line',
        active: false,
        fields: [
            {
                label: 'Allow Outgoing Phone Calls (from Android 7.0)',
                value: 'outgoingCallsDisabled',
                helpText: 'Controls user access on outgoing phone calls',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow Send/Receive SMS (from Android 7.0)',
                value: 'smsDisabled',
                helpText: 'Controls user access on send or receive messages',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow Bluetooth',
                value: 'bluetoothDisabled',
                helpText: 'Controls user access on Bluetooth',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow Bluetooth Contact Sharing',
                value: 'bluetoothContactSharingDisabled',
                inputType: 'toggle',
                helpText: 'Whether bluetooth contact sharing is disabled.',
                profileType: ['Work Profile']
            },
            {
                label: 'Allow Bluetooth Config (from Android 7.0)',
                value: 'bluetoothConfigDisabled',
                inputType: 'toggle',
                helpText: 'Controls user access on Bluetooth configuration',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow NFC Beam',
                value: 'outgoingBeamDisabled',
                helpText: 'Controls user access on sharing data through NFC',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Location Accuracy',
                value: 'locationMode',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'LOCATION_MODE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'LOCATION_USER_CHOICE' },
                    { label: 'Enforce', value: 'LOCATION_ENFORCED' },
                    { label: 'Disable', value: 'LOCATION_DISABLED' }
                ],
                helpText: 'Controls user access on enabling location',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Allow user to share location',
                value: 'shareLocationDisabled',
                inputType: 'toggle',
                helpText: 'Controls user access on sharing location',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile']
            },
            {
                label: 'Allow Volume Controls',
                value: 'adjustVolumeDisabled',
                inputType: 'toggle',
                helpText: 'Controls user access on volume buttons of the device',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Printing Policy',
                value: 'printingPolicy',
                helpText: 'Controls whether printing is allowed',
                inputType: 'select',
                profileType: ['Fully Managed Device', 'Tectoro Launcher'],
                options: [
                    { label: 'Unspecific', value: 'PRINTING_POLICY_UNSPECIFIED' },
                    { label: 'Disallow', value: 'PRINTING_DISALLOWED' },
                    { label: 'Allow', value: 'PRINTING_ALLOWED' }
                ]
            },
            {
                label: 'Allow Microphone',
                value: 'microphoneAccess',
                helpText: 'Controls user access to microphone on the device',
                inputType: 'select',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher'],
                options: [
                    { label: 'Unspecific', value: 'MICROPHONE_ACCESS_UNSPECIFIED' },
                    { label: 'User Choice', value: 'MICROPHONE_ACCESS_USER_CHOICE' },
                    { label: 'Disable', value: 'MICROPHONE_ACCESS_DISABLED' },
                    { label: 'Enforce', value: 'MICROPHONE_ACCESS_ENFORCED' }
                ]
            },
            {
                label: 'Default Permission Policy',
                value: 'defaultPermissionPolicyInPolicy',
                inputType: 'select',
                helpText: 'Controls the applications default permissions in the device',
                options: [
                    { label: 'Unspecific', value: 'PERMISSION_POLICY_UNSPECIFIED' },
                    { label: 'Prompt', value: 'PROMPT' },
                    { label: 'Grant', value: 'GRANT' },
                    { label: 'Deny', value: 'DENY' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            }
        ]
    },
    {
        label: 'Wi-Fi Settings',
        icon: 'ri-wifi-line',
        active: false,
        customBody: true
    },
    {
        label: 'Network Settings',
        active: false,
        icon: 'ri-global-line',
        fields: [
            {
                label: 'Allow Mobile Network Changes',
                value: 'mobileNetworksConfigDisabled',
                helpText: 'Controls user access to mobile networks',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow Cell Broadcasts Config (from Android 7.0)',
                value: 'cellBroadcastsConfigDisabled',
                helpText: 'Controls user access to receive broadcast messages',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Allow Create Windows',
                value: 'createWindowsDisabled',
                helpText: 'Controls if user is allowed to use screen for multiple apps at a time',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Allow the user to reset network',
                value: 'networkResetDisabled',
                helpText: 'Controls user access on network settings like Bluetooth connections, VPN, WiFi passwords etc.',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            /*
             * {
             *     label: 'VPN Configuration',
             *     value: 'alwaysOnVpnPackage',
             *     objectKey: 'packageName',
             *     type: 'text',
             *     inputType: 'input',
             *     helpText: 'Configuration for an always-on VPN connection.',
             *     profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher']
             * },
             */
            {
                label: 'Allow Data Roaming (from Android 7.0)',
                value: 'dataRoamingDisabled',
                helpText: 'Controls user access on Data Roaming',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Network Escape Hatch Enabled',
                value: 'networkEscapeHatchEnabled',
                inputType: 'toggle',
                helpText:
                    // eslint-disable-next-line quotes
                    'Allows temporary network to connect the device',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Preferential Network Service',
                value: 'preferentialNetworkService',
                inputType: 'select',
                helpText: 'Controls preferential network service on the work profile',
                options: [
                    { label: 'Unspecific', value: 'PREFERENTIAL_NETWORK_SERVICE_UNSPECIFIED' },
                    { label: 'Disable', value: 'PREFERENTIAL_NETWORK_SERVICE_DISABLED' },
                    { label: 'Enforce', value: 'PREFERENTIAL_NETWORK_SERVICE_ENABLED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Enable Configuring VPN',
                value: 'vpnConfigDisabled',
                inputType: 'toggle',
                helpText: 'Controls user access on configuring VPN settings',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Block Network',
                value: 'lockdownEnabled',
                inputType: 'toggle',
                helpText: 'Controls user access on blocking network',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                hide: 'vpnConfigDisabled'
            },
            {
                label: 'VPN Package',
                value: 'packageName',
                mandatory: true,
                inputType: 'input',
                helpText: 'Enter the name of VPN to which device must be configured',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                hide: 'vpnConfigDisabled',
                notes: 'The package name must be added in applications.',
                class: 'warning'
            },
            {
                label: 'Allow USB File Transfer',
                value: 'usbFileTransferDisabled',
                helpText: 'Controls user access on file transfer files through USB',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                notes: 'USB File Transfer is deprecated. Please use USB Data Access instead.',
                class: 'warning'
            },
            {
                label: 'Wi-Fi State (from Android 13.0)',
                value: 'wifiState',
                parentValue: 'deviceRadioState',
                helpText: 'Controls user access to change Wi-Fi state to On and Off',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'WIFI_STATE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'WIFI_STATE_USER_CHOICE' },
                    { label: 'Enabled', value: 'WIFI_ENABLED' },
                    { label: 'Disabled', value: 'WIFI_DISABLED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                inputType: 'note',
                val: 'wifiState',
                note: 'Please note that this option disables the Wi-Fi option on the device.',
                noteColor: 'warning',
                noteValue: 'WIFI_DISABLED',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Airplane Mode State',
                value: 'airplaneModeState',
                parentValue: 'deviceRadioState',
                removable: true,
                helpText: 'Controls user access on Airplane mode',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'AIRPLANE_MODE_STATE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'AIRPLANE_MODE_USER_CHOICE' },
                    { label: 'Disabled', value: 'AIRPLANE_MODE_DISABLED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Ultra Wideband State (from Android 14 and above.)',
                value: 'ultraWidebandState',
                parentValue: 'deviceRadioState',
                inputType: 'select',
                helpText: 'Controls the state of the ultra wideband setting and whether the user can toggle it on or off.',
                options: [
                    { label: 'Unspecified', value: 'ULTRA_WIDEBAND_STATE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'ULTRA_WIDEBAND_USER_CHOICE' },
                    { label: 'Disabled', value: 'ULTRA_WIDEBAND_DISABLED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Cellular 2G State (from Android 14 and above.)',
                value: 'cellularTwoGState',
                parentValue: 'deviceRadioState',
                helpText: 'Controls whether cellular 2G setting can be toggled by the user or not.',
                inputType: 'select',
                options: [
                    { label: 'Unspecified', value: 'CELLULAR_TWO_G_STATE_UNSPECIFIED' },
                    { label: 'User Choice', value: 'CELLULAR_TWO_G_USER_CHOICE' },
                    { label: 'Disabled', value: 'CELLULAR_TWO_G_DISABLED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Minimum Wi-Fi Security Level (from Android 13 and above.)',
                value: 'minimumWifiSecurityLevel',
                parentValue: 'deviceRadioState',
                helpText: 'The minimum required security level of Wi-Fi networks that the device can connect to.',
                inputType: 'select',
                options: [
                    { label: 'Unspecified', value: 'MINIMUM_WIFI_SECURITY_LEVEL_UNSPECIFIED' },
                    { label: 'Open Network Security', value: 'OPEN_NETWORK_SECURITY' },
                    { label: 'Personal Network Security', value: 'PERSONAL_NETWORK_SECURITY' },
                    { label: 'Enterprise Network Security', value: 'ENTERPRISE_NETWORK_SECURITY' },
                    { label: 'Enterprise Bit 192 Network Security', value: 'ENTERPRISE_BIT192_NETWORK_SECURITY' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'USB Data Access',
                value: 'usbDataAccess',
                parentValue: 'deviceConnectivityManagement',
                helpText: 'Controls user access on USB data sharing.',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'USB_DATA_ACCESS_UNSPECIFIED' },
                    { label: 'Allow USB Data Transfer', value: 'ALLOW_USB_DATA_TRANSFER' },
                    { label: 'Disallow USB File Transfer', value: 'DISALLOW_USB_FILE_TRANSFER' },
                    { label: 'Disallow USB Data Transfer', value: 'DISALLOW_USB_DATA_TRANSFER' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Configure Wi-Fi',
                value: 'configureWifi',
                parentValue: 'deviceConnectivityManagement',
                helpText: 'Controls user access on add or configure to specific Wi-Fi networks',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'CONFIGURE_WIFI_UNSPECIFIED' },
                    { label: 'Allow Configuring Wi-Fi', value: 'ALLOW_CONFIGURING_WIFI' },
                    { label: 'Disallow Add Wi-Fi Config', value: 'DISALLOW_ADD_WIFI_CONFIG' },
                    { label: 'Disallow Configuring Wi-Fi', value: 'DISALLOW_CONFIGURING_WIFI' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Wi-Fi Direct Settings',
                value: 'wifiDirectSettings',
                parentValue: 'deviceConnectivityManagement',
                helpText: 'Controls user access to Wi-Fi direct settings.',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'WIFI_DIRECT_SETTINGS_UNSPECIFIED' },
                    { label: 'Allow Wi-Fi Direct', value: 'ALLOW_WIFI_DIRECT' },
                    { label: 'Disallow Wi-Fi Direct', value: 'DISALLOW_WIFI_DIRECT' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Tethering Settings',
                value: 'tetheringSettings',
                parentValue: 'deviceConnectivityManagement',
                helpText: 'Controls user access on different forms of tethering',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'TETHERING_SETTINGS_UNSPECIFIED' },
                    { label: 'Allow All Tethering', value: 'ALLOW_ALL_TETHERING' },
                    { label: 'Disallow Wi-Fi Tethering', value: 'DISALLOW_WIFI_TETHERING' },
                    { label: 'Disallow All Tethering', value: 'DISALLOW_ALL_TETHERING' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Wi-Fi SSID Policy Type (from Android 13 and above.)',
                value: 'wifiSsidPolicyType',
                inputType: 'select',
                removable: true,
                parentValue: 'deviceConnectivityManagement',
                helpText: 'Type of the Wi-Fi SSID policy to be applied.',
                options: [
                    { label: 'Allow', value: 'WIFI_SSID_ALLOWLIST' },
                    { label: 'Deny', value: 'WIFI_SSID_DENYLIST' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Wi-Fi SSID (from Android 13 and above.)',
                value: 'wifiSsids',
                inputType: 'input',
                conditionalMandatory: true,
                condition: 'wifiSsidPolicyType',
                helpText: 'Required. Wi-Fi SSID represented as a string.',
                notes: 'Enter SSIDs seperated by Comma (, )',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            }
        ]
    },
    {
        label: 'Security Settings',
        active: false,
        icon: 'ri-file-shield-2-line',
        fields: [
            {
                label: 'Allow Screen Capture',
                value: 'screenCaptureDisabled',
                helpText: 'Controls user access on capturing screenshots',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Allow External Storage',
                value: 'mountPhysicalMediaDisabled',
                showAlert: true,
                showAlertOn: false,
                alertMessage: 'Are you sure you want to disable the external storage.',
                helpText: 'Controls user access to allow mounting external storage',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Allow the user to do factory reset',
                value: 'factoryResetDisabled',
                helpText: 'Controls user access on performing soft factory reset on the device',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Allow Saving Credentials',
                value: 'credentialsConfigDisabled',
                helpText: 'Controls user access on saving credentials',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            /*
             * {
             *     label: 'Auto Approve App Permissions',
             *     value: 'defaultPermissionPolicy',
             *     inputType: 'select',
             *     helpText: 'The default policy for all permissions requested by the app.',
             *     options: [
             *         { label: 'Unspecific', value: 'PERMISSION_POLICY_UNSPECIFIED' },
             *         { label: 'Prompt', value: 'PROMPT' },
             *         { label: 'Grant', value: 'GRANT' },
             *         { label: 'Deny', value: 'DENY' }
             *     ],
             *     profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             */
            {
                label: 'Allow App Installations',
                value: 'installAppsDisabled',
                helpText: 'Controls user access to install applications on the device',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile']
            },
            {
                label: 'Allow App Uninstallations',
                value: 'uninstallAppsDisabled',
                helpText: 'Controls user access to uninstall applications on the device',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile']
            },
            {
                label: 'Google Play Protect Verify Apps',
                value: 'googlePlayProtectVerifyApps',
                parentValue: 'advancedSecurityOverrides',
                helpText: 'Controls user access to install apps that are Google play protected',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED' },
                    { label: 'Enforce', value: 'VERIFY_APPS_ENFORCED' },
                    { label: 'User Choice', value: 'VERIFY_APPS_USER_CHOICE' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Untrusted Apps Policy',
                value: 'untrustedAppsPolicy',
                parentValue: 'advancedSecurityOverrides',
                helpText: 'Controls user access on installing untrusted apps (Apps from unknown sources)',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'UNTRUSTED_APPS_POLICY_UNSPECIFIED' },
                    // { label: 'Allow Install in Personal Profile Only', value: 'ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY' },
                    { label: 'Disallow untrusted app installs', value: 'DISALLOW_INSTALL' },
                    { label: 'Allow untrusted app installs', value: 'ALLOW_INSTALL_DEVICE_WIDE' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Developer Settings',
                value: 'developerSettings',
                inputType: 'select',
                parentValue: 'advancedSecurityOverrides',
                options: [
                    { label: 'Unspecific', value: 'DEVELOPER_SETTINGS_UNSPECIFIED' },
                    { label: 'Disable', value: 'DEVELOPER_SETTINGS_DISABLED' },
                    { label: 'Allowed', value: 'DEVELOPER_SETTINGS_ALLOWED' }
                ],
                helpText: 'Controls user access to developer settings',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            }
        ]
    },
    {
        label: 'Compliance Policies',
        active: false,
        icon: 'ri-error-warning-line',
        fields: [
            {
                label: 'Encryption Policy (from Android 10 and above.)',
                value: 'encryptionPolicy',
                inputType: 'select',
                helpText:
                    'The sensitive data stored on your device remains protected from unauthorized access by encrypting the data through enabling password protect.',
                options: [
                    { label: 'Encryption Policy Unspecified', value: 'ENCRYPTION_POLICY_UNSPECIFIED' },
                    { label: 'Enable Without Password', value: 'ENABLED_WITHOUT_PASSWORD' },
                    { label: 'Enable With Password', value: 'ENABLED_WITH_PASSWORD' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'App Auto Update Policy',
                value: 'appAutoUpdatePolicy',
                helpText: 'Controls user access to perform auto app updation',
                inputType: 'select',
                removable: true,
                options: [
                    { label: 'Unspecific', value: 'APP_AUTO_UPDATE_POLICY_UNSPECIFIED' },
                    { label: 'User Choice', value: 'CHOICE_TO_THE_USER' },
                    { label: 'Never', value: 'NEVER' },
                    { label: 'Wi-Fi Only', value: 'WIFI_ONLY' },
                    { label: 'Always', value: 'ALWAYS' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'System Update Type',
                value: 'systemUpdate',
                inputType: 'select',
                options: [
                    { label: 'Unspecific', value: 'SYSTEM_UPDATE_TYPE_UNSPECIFIED' },
                    { label: 'Automatic', value: 'AUTOMATIC' },
                    { label: 'Windowed', value: 'WINDOWED' },
                    { label: 'Postpone', value: 'POSTPONE' }
                ],
                objectKey: 'type',
                helpText: 'Controls user access on system updates',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Start Minutes',
                value: 'systemUpdate',
                inputType: 'staticField',
                hide: 'WINDOWED',
                dataVal: '1',
                helpText:
                    'If the type is WINDOWED, the start of the maintenance window, measured as the number of minutes after midnight in the devices local time. This value must be between 0 and 1439, inclusive.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Work Profile', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'End Minutes',
                value: 'systemUpdate',
                inputType: 'staticField',
                hide: 'WINDOWED',
                dataVal: '60',
                helpText:
                    'If the type is WINDOWED, the end of the maintenance window, measured as the number of minutes after midnight in devices local time. This value must be between 0 and 1439, inclusive. If this value is less than startMinutes, then the maintenance window spans midnight. If the maintenance window specified is smaller than 30 minutes, the actual window is extended to 30 minutes beyond the start time.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Work Profile', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Freeze Period',
                value1: 'startDate',
                value2: 'endDate',
                value: 'systemUpdate',
                mandatory: true,
                inputType: 'DoubleDatePicker',
                hide: 'WINDOWED',
                helpText:
                    'An annually repeating time period in which over-the-air (OTA) system updates are postponed to freeze the OS version running on a device. To prevent freezing the device indefinitely, each freeze period must be separated by at least 60 days.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Work Profile', 'Tectoro Launcher', 'Single App']
            }
        ]
    },
    {
        label: 'User Account settings',
        active: false,
        icon: 'ri-user-settings-line',
        fields: [
            {
                label: 'Controls user access on adding/removing Google accounts',
                value: 'modifyAccountsDisabled',
                helpText: 'Whether adding or removing Google accounts is disabled.',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            /*
             * {
             *     label: 'Allow Adding Users',
             *     value: 'addUserDisabled',
             *     helpText: 'Whether adding new users and policies is disabled.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             * {
             *     label: 'Allow Removing Users',
             *     value: 'removeUserDisabled',
             *     helpText: 'Whether removing other users is disabled.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             */
            {
                label: 'Play Store Mode',
                value: 'playStoreMode',
                inputType: 'select',
                removable: true,
                helpText:
                    'On selection Whitelist, user can view only the apps pushed by the Admin. On selection of Blacklist, user can view all the apps in the playstore',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                options: [
                    { label: 'Play Store Mode Unspecified', value: 'PLAY_STORE_MODE_UNSPECIFIED' },
                    { label: 'White List', value: 'WHITELIST' },
                    { label: 'Black List', value: 'BLACKLIST' }
                ]
            },
            {
                label: 'Allow user to change wallpaper  (from Android 7.0)',
                value: 'setWallpaperDisabled',
                showAlert: true,
                showAlertOn: false,
                alertMessage: 'Are you sure you want to block user to set the wallpaper.',
                helpText: 'Controls user access on changing the wallpaper in the device',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Lock screen Owner Details',
                value: 'deviceOwnerLockScreenInfo',
                helpText: 'Enter the details to be displayed on Lock Screen',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                type: 'text',
                objectKey: 'defaultMessage',
                inputType: 'input'
            },
            {
                label: 'Short Support Message',
                value: 'shortSupportMessage',
                helpText:
                    'Message to be displayed to the user on disabling any functionality by Admin. If the message is longer than 200 characters, it may be truncated.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                type: 'text',
                objectKey: 'defaultMessage',
                inputType: 'input'
            },
            {
                label: 'Long Support Message',
                value: 'longSupportMessage',
                helpText: 'A message displayed to the user in the device administrator settings screen.',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                type: 'text',
                objectKey: 'defaultMessage',
                inputType: 'input'
            },
            {
                label: 'Skip First Use Hints Enabled',
                value: 'skipFirstUseHintsEnabled',
                inputType: 'toggle',
                helpText: 'Admin can control the First Use Hints whether to be flagged to user or not',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Allow Set User Icon',
                value: 'setUserIconDisabled',
                helpText: 'Controls user access to set user icon',
                inputType: 'toggle',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App']
            },
            {
                label: 'Screen Time Out',
                value: 'maximumTimeToLock',
                inputType: 'select',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                options: [
                    { label: 'No Restriction', value: '0' },
                    { label: '15 Seconds', value: '15000' },
                    { label: '30 Seconds', value: '30000' },
                    { label: '1 Minute', value: '60000' },
                    { label: '5 Minutes', value: '300000' }
                ],
                helpText:
                    'The maximum number of minutes for which the device can be idle without the user unlocking it, before the system locks it. '
            },
            {
                label: 'Home Screen Wallpaper',
                value: 'wallpaper',
                helpText: 'Admin can select the wallpaper that has to be displayed on the device',
                inputType: 'select',
                optionsVal: 'wallpapers',
                optionLabel: 'label',
                optionValue: 'value',
                removable: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Lock Screen Wallpaper',
                value: 'lockScreenWallpaper',
                helpText: 'Admin can select the lock screen wallpaper that has to be displayed o the device',
                inputType: 'select',
                optionsVal: 'wallpapers',
                optionLabel: 'label',
                optionValue: 'value',
                removable: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Boot Animation',
                value: 'bootAnimation',
                helpText: 'Admin can select the Boot Animation that has to be displayed when the device is powered on or restarted',
                inputType: 'select',
                optionsVal: 'bootAnimations',
                optionLabel: 'label',
                removable: true,
                optionValue: 'value',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            },
            {
                label: 'Shutdown Logo',
                value: 'shutdownlogo',
                helpText: 'Admin can select the Shutdown Logo that has to be displayed when the device is powered off or restarted',
                inputType: 'select',
                optionsVal: 'shutdownlogo',
                optionLabel: 'label',
                removable: true,
                optionValue: 'value',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher']
            }
        ]
    },
    {
        label: 'Keyguard Settings',
        icon: 'ri-shield-keyhole-line',
        active: false,
        fields: [
            {
                label: 'Allow Keyguard',
                value: 'keyguardDisabled',
                inputType: 'toggle',
                helpText: 'Admin can control the keyguard features whether to be enabled or not',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Keyguard Disabled Features',
                value: 'keyguardDisabledFeatures',
                inputType: 'select',
                removable: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                helpText: `Customize the keyguard features that are accessible from the dropdown
				        ⓘ Disable all current and future keyguard customizations.
                        ⓘ Disable secure camera on secure keyguard screen.
                        ⓘ Disable all notifications appear on secure keyguard screen.
                        ⓘ Disable unredacted notifications appear on secure keyguard screen.
                        ⓘ Disable trust agents on secure keyguard screen.
                        ⓘ Disable fingerprint sensor on secure keyguard screen.
                        ⓘ Disable text entry into notifications on secure keyguard screen.
                        ⓘ Disable face authentication on secure keyguard screen.
                        ⓘ Disable iris authentication on secure keyguard screen.
                        ⓘ Disable all biometric authentication on secure keyguard screen.
                        ⓘ Specifies if what is copied in the clipboard of this profile can be pasted in related policies
						`,
                isMulti: true,
                hide: 'keyguardDisabled',
                options: [
                    { label: 'Disable All Features', value: 'ALL_FEATURES' },
                    { label: 'Disable Camera', value: 'CAMERA' },
                    { label: 'Disable All Notifications', value: 'NOTIFICATIONS' },
                    { label: 'Disable Unredacted Notifications', value: 'UNREDACTED_NOTIFICATIONS' },
                    { label: 'Disable Trust Agents', value: 'TRUST_AGENTS' },
                    { label: 'Disable Fingerprint Unlock', value: 'DISABLE_FINGERPRINT' },
                    { label: 'Disable Face Authentication', value: 'FACE' },
                    { label: 'Disable Iris Authentication', value: 'IRIS' },
                    { label: 'Disable All Biometric Authentication', value: 'BIOMETRICS' },
                    { label: 'Keyguard Features Unspecified', value: 'KEYGUARD_DISABLED_FEATURE_UNSPECIFIED' },
                    { label: 'Shortcuts', value: 'SHORTCUTS' }
                ]
            }
        ]
    },
    {
        label: 'Status Reports',
        icon: 'ri-folder-chart-line',
        active: false,
        fields: [
            {
                label: 'App Install/Uninstall Reports',
                value: 'applicationReportsEnabled',
                parentValue: 'statusReportingSettings',
                helpText: 'By default, the installed/uninstalled apps reports are enabled. Admin can view the reports under Reports tab.',
                inputType: 'toggle',
                readOnly: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Device Security Reports',
                value: 'deviceSettingsEnabled',
                parentValue: 'statusReportingSettings',
                helpText: 'By default, Device Security reports are enabled. Admin can view the reports under Reports tab.',
                inputType: 'toggle',
                readOnly: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Android OS Software Reports',
                value: 'softwareInfoEnabled',
                parentValue: 'statusReportingSettings',
                helpText:
                    'By default, Android OS Software reports are enabled. Admin can view the reports under Reports tab and Dashboard.',
                inputType: 'toggle',
                readOnly: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Network Reports',
                value: 'networkInfoEnabled',
                parentValue: 'statusReportingSettings',
                helpText: 'By default, Network reports are enabled. Admin can view the reports under Reports tab.',
                inputType: 'toggle',
                readOnly: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            /*
             * {
             *     label: 'Memory Reports',
             *     value: 'memoryInfoEnabled',
             *     parentValue: 'statusReportingSettings',
             *     helpText: 'Memory Reports.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
             * },
             */
            /*
             * {
             *     label: 'Power Management Reports',
             *     value: 'powerManagementEventsEnabled',
             *     parentValue: 'statusReportingSettings',
             *     helpText: 'Power Management Reports.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
             * },
             * {
             *     label: 'Device Display Reports',
             *     value: 'displayInfoEnabled',
             *     parentValue: 'statusReportingSettings',
             *     helpText: 'Device Display Reports.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
             * },
             * {
             *     label: 'Hardware Status Reports',
             *     value: 'hardwareStatusEnabled',
             *     parentValue: 'statusReportingSettings',
             *     helpText: 'Hardware Status Reports.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
             * },
             */
            {
                label: 'System Property Reports',
                value: 'systemPropertiesEnabled',
                parentValue: 'statusReportingSettings',
                helpText: 'By default, System Property reports are enabled. Admin can view the reports under Reports tab.',
                inputType: 'toggle',
                readOnly: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            }
            /*
             * {
             *     label: 'Common Criteria Mode Reports',
             *     value: 'commonCriteriaModeEnabled',
             *     parentValue: 'statusReportingSettings',
             *     helpText: 'Common Criteria Mode Reports.',
             *     inputType: 'toggle',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
             * }
             */
        ]
    },
    {
        label: 'Setup Actions',
        icon: 'ri-home-gear-line',
        active: false,
        fields: [
            {
                label: 'Title',
                value: 'title',
                inputType: 'input',
                mandatory: true,
                maxLength: 20,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                helpText: 'Enter the Title of App'
            },
            {
                label: 'Description',
                value: 'description',
                inputType: 'input',
                mandatory: true,
                maxLength: 200,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                helpText: 'Specify the Description of the app'
            },
            {
                label: 'Launch App',
                value: 'launchApp',
                inputType: 'select',
                optionsVal: 'policies',
                mandatory: true,
                optionLabel: 'title',
                optionValue: 'packageName',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App'],
                helpText: 'Select the application from the drop-down that has to be launched soon after enrollment'
            }
        ]
    },
    {
        label: 'Applications',
        icon: 'ri-apps-line',
        extraContent: true,
        active: false,
        fields: [
            {
                label: 'Visibility in launcher',
                value: 'selected',
                helpText: 'Enabling this will show this app in launcher',
                inputType: 'toggle',
                apps: true,
                hideWhile: 'LAUNCHER',
                checkHide: 'code',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Installation Type',
                value: 'installType',
                inputType: 'select',
                parentValue: 'applications',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile'],
                helpText: 'Installation Type',
                options: [
                    { label: 'Kiosk', value: 'KIOSK', hidden: true },
                    { label: 'Available', value: 'AVAILABLE' },
                    { label: 'Force Installed', value: 'FORCE_INSTALLED' },
                    { label: 'Pre Installed', value: 'PREINSTALLED' },
                    { label: 'Required for Set Up', value: 'REQUIRED_FOR_SETUP' }
                ]
            },
            {
                label: 'Default Permission Policy',
                value: 'defaultPermissionPolicy',
                inputType: 'select',
                parentValue: 'applications',
                helpText: 'The default policy for all permissions requested by the app.',
                options: [
                    { label: 'Unspecific', value: 'PERMISSION_POLICY_UNSPECIFIED' },
                    { label: 'Prompt', value: 'PROMPT' },
                    { label: 'Grant', value: 'GRANT' },
                    { label: 'Deny', value: 'DENY' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            /*
             * {
             *     label: 'Disable',
             *     value: 'disabled',
             *     inputType: 'toggle',
             *     helpText: 'Disable',
             *     parentValue: 'applications',
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             */
            {
                label: 'Delegated Scopes',
                value: 'delegatedScopes',
                inputType: 'select',
                isMulti: true,
                parentValue: 'applications',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile'],
                helpText: 'Delegated Scopes',
                options: [
                    { label: 'Delegated Scope Unspecified', value: 'DELEGATED_SCOPE_UNSPECIFIED' },
                    { label: 'Cert Install', value: 'CERT_INSTALL' },
                    { label: 'Managed Configurations', value: 'MANAGED_CONFIGURATIONS' },
                    { label: 'Block Uninstall', value: 'BLOCK_UNINSTALL' },
                    { label: 'Permission Grant', value: 'PERMISSION_GRANT' },
                    { label: 'Package Access', value: 'PACKAGE_ACCESS' },
                    { label: 'Enable System App', value: 'ENABLE_SYSTEM_APP' },
                    { label: 'Network Activity Logs', value: 'NETWORK_ACTIVITY_LOGS' },
                    { label: 'Security Logs', value: 'SECURITY_LOGS' },
                    { label: 'Cert Selection', value: 'CERT_SELECTION' }
                ]
            },
            {
                label: 'App Auto Update Mode',
                value: 'autoUpdateMode',
                inputType: 'select',
                parentValue: 'applications',
                helpText: 'Whether App Auto Update Mode is enabled.',
                options: [
                    { label: 'Unspecific', value: 'AUTO_UPDATE_MODE_UNSPECIFIED' },
                    { label: 'Default', value: 'AUTO_UPDATE_DEFAULT' },
                    { label: 'Postponed', value: 'AUTO_UPDATE_POSTPONED' },
                    { label: 'High Priority', value: 'AUTO_UPDATE_HIGH_PRIORITY' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Additional Permissions',
                value: 'permissionGrants',
                isMulti: true,
                inputType: 'select',
                parentValue: 'applications',
                objectKey: 'permission',
                helpText: 'Additional Permissions',
                options: [
                    { label: 'Manage External Storage', value: 'MANAGE_EXTERNAL_STORAGE' },
                    { label: 'Package Usage Stats', value: 'PACKAGE_USAGE_STATS' },
                    { label: 'Read Call Log', value: 'READ_CALL_LOG' },
                    { label: 'Read Contacts', value: 'READ_CONTACTS' },
                    { label: 'Read External Storage', value: 'READ_EXTERNAL_STORAGE' },
                    { label: 'Read Phone State', value: 'READ_PHONE_STATE' },
                    { label: 'Read Privileged Phone State', value: 'READ_PRIVILEGED_PHONE_STATE' },
                    { label: 'Receive Boot Completed', value: 'RECEIVE_BOOT_COMPLETED' },
                    { label: 'Use ICC Auth with Device Identifier', value: 'USE_ICC_AUTH_WITH_DEVICE_IDENTIFIER' },
                    { label: 'Write Contacts', value: 'WRITE_CONTACTS' },
                    { label: 'Write External Storage', value: 'WRITE_EXTERNAL_STORAGE' },
                    { label: 'Access Coarse Location', value: 'android.permission.ACCESS_COARSE_LOCATION' },
                    { label: 'Access Fine Location', value: 'android.permission.ACCESS_FINE_LOCATION' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Single App', 'Work Profile']
            },
            {
                label: 'Extension Config',
                value: 'extensionConfig',
                inputType: 'toggle',
                helpText:
                    'Configuration to enable an app as an extension app, with the capability of interacting with Android Device Policy offline. For Android versions 13 and above, extension apps are exempt from battery restrictions so will not be placed into the restricted App Standby Bucket. Extensions apps are also protected against users clearing their data or force-closing the application, although admins can continue to use the clear app data command on extension apps if needed for Android 13 and above.',
                objectKey: 'extensionConfig',
                parentValue: 'applications',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'VPN Exemption',
                value: 'alwaysOnVpnLockdownExemption',
                inputType: 'select',
                removable: true,
                helpText: 'Enabling this will make the application VPN free.',
                parentValue: 'applications',
                options: [
                    { label: 'Always on VPN lockdown excemption unspecified', value: 'ALWAYS_ON_VPN_LOCKDOWN_EXEMPTION_UNSPECIFIED' },
                    { label: 'VPN lockdown enforced', value: 'VPN_LOCKDOWN_ENFORCED' },
                    { label: 'VPN lockdown exemption', value: 'VPN_LOCKDOWN_EXEMPTION' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Track',
                value: 'accessibleTrackIds',
                inputType: 'select',
                removable: true,
                helpText: 'Select the tracks.',
                optionsVal: 'tracks',
                optionLabel: 'trackAlias',
                optionValue: 'trackId',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Managed Configuration',
                value: 'managedConfigId',
                inputType: 'select',
                removable: true,
                helpText: 'Select the maanged configuration.',
                optionsVal: 'managedConfigs',
                optionLabel: 'title',
                optionValue: 'managedConfigId',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Permitted Accessibility Service',
                value: 'permittedAccessibilityServices',
                inputType: 'toggle',
                helpText: 'Permitted Accessibility Service',
                objectKey: 'packageNames',
                parentValue: 'applications',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Connected Work And Personal App',
                value: 'connectedWorkAndPersonalApp',
                inputType: 'select',
                parentValue: 'applications',
                removable: true,
                helpText:
                    'Controls whether the app can communicate with itself across a device’s work and personal profiles, subject to user consent.',
                options: [
                    { label: 'Unspecific', value: 'CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED' },
                    { label: 'Allowed', value: 'CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Work Profile Widgets',
                value: 'workProfileWidgets',
                inputType: 'select',
                parentValue: 'applications',
                removable: true,
                helpText: 'Specifies whether the app installed in the work profile is allowed to add widgets to the home screen.',
                options: [
                    { label: 'Unspecific', value: 'WORK_PROFILE_WIDGETS_UNSPECIFIED' },
                    { label: 'Disallowed', value: 'WORK_PROFILE_WIDGETS_DISALLOWED' },
                    { label: 'Allowed', value: 'WORK_PROFILE_WIDGETS_ALLOWED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Personal Application',
                value: 'personalApplication',
                inputType: 'toggle',
                parentValue: 'applications',
                helpText: 'Policy applied to applications in the personal profile.',
                profileType: ['Work Profile']
            },
            {
                label: 'Personal App Installation Type',
                value: 'personalAppInstallType',
                inputType: 'select',
                parentValue: 'applications',
                hide: 'personalApplication',
                removable: true,
                hideOnApplication: true,
                helpText: 'Installation Type for Personal App',
                options: [
                    // { label: 'Kiosk', value: 'KIOSK' },
                    { label: 'Available', value: 'AVAILABLE' },
                    { label: 'Blocked', value: 'BLOCKED' },
                    { label: 'Install Type Unspecified', value: 'INSTALL_TYPE_UNSPECIFIED' }
                ],
                profileType: ['Work Profile']
            },
            {
                label: 'Credential Provider Policy (from Android 14 and above)',
                value: 'credentialProviderPolicy',
                inputType: 'select',
                helpText: 'Whether the app is allowed to act as a credential provider on Android 14 and above.',
                options: [
                    { label: 'Unspecified', value: 'CREDENTIAL_PROVIDER_POLICY_UNSPECIFIED' },
                    { label: 'Allowed', value: 'CREDENTIAL_PROVIDER_ALLOWED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'User Control Settings (from Android 11 and above)',
                value: 'userControlSettings',
                helpText:
                    'Specifies whether user control is permitted for the app. User control includes user actions like force-stopping and clearing app data. Supported on Android 11 and above.',
                inputType: 'select',
                options: [
                    { label: 'Unspecified', value: 'USER_CONTROL_SETTINGS_UNSPECIFIED' },
                    { label: 'Allowed', value: 'USER_CONTROL_ALLOWED' },
                    { label: 'Disallowed', value: 'USER_CONTROL_DISALLOWED' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Install Priority',
                value: 'installPriority',
                helpText:
                    'this controls the relative priority of installation. A value of 0 (default) means this app has no priority over other apps. For values between 1 and 10,000, a lower value means a higher priority. Values outside of the range 0 to 10,000 inclusive are rejected.',
                inputType: 'input',
                maxVal: 10000,
                show: 'installType',
                showValues: ['FORCE_INSTALLED', 'PREINSTALLED'],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Install Constraints',
                isHeader: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                show: 'installType',
                showValues: ['FORCE_INSTALLED', 'PREINSTALLED']
            },
            {
                label: 'Network Type Constraint',
                value: 'networkTypeConstraint',
                inputType: 'select',
                parentValue: 'applications',
                removable: true,
                helpText: 'Network type constraint.',
                options: [
                    { label: 'Unspecified', value: 'NETWORK_TYPE_CONSTRAINT_UNSPECIFIED' },
                    { label: 'Install on any network', value: 'INSTALL_ON_ANY_NETWORK' },
                    { label: 'Install on unmetered networks', value: 'INSTALL_ONLY_ON_UNMETERED_NETWORK' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                show: 'installType',
                showValues: ['FORCE_INSTALLED', 'PREINSTALLED']
            },
            {
                label: 'Charging Constraint',
                value: 'chargingConstraint',
                inputType: 'select',
                parentValue: 'applications',
                removable: true,
                helpText: 'Charging constraint.',
                options: [
                    { label: 'Unspecified', value: 'CHARGING_CONSTRAINT_UNSPECIFIED' },
                    { label: 'Charging not required', value: 'CHARGING_NOT_REQUIRED' },
                    { label: 'Install only when charging', value: 'INSTALL_ONLY_WHEN_CHARGING' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                show: 'installType',
                showValues: ['FORCE_INSTALLED', 'PREINSTALLED']
            },
            {
                label: 'Device Idle Constraint',
                value: 'deviceIdleConstraint',
                inputType: 'select',
                parentValue: 'applications',
                removable: true,
                helpText: 'Device idle constraints.',
                options: [
                    { label: 'Unspecified', value: 'DEVICE_IDLE_CONSTRAINT_UNSPECIFIED' },
                    { label: 'Idle not required', value: 'DEVICE_IDLE_NOT_REQUIRED' },
                    { label: 'Install only during idle', value: 'INSTALL_ONLY_WHEN_DEVICE_IDLE' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                show: 'installType',
                showValues: ['FORCE_INSTALLED', 'PREINSTALLED']
            }
        ]
    },
    {
        label: 'Configurations',
        icon: 'ri-file-settings-line',
        active: false,
        fields: [
            {
                label: 'Firewall',
                value: 'firewall',
                hideOnTenant: 'FIREWALL',
                inputType: 'select',
                helpText: 'Select a Firewall that has been configured under Firewall in configurations tab',
                optionsVal: 'firewallConfigs',
                optionLabel: 'name',
                optionValue: 'config',
                removable: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            /*
             * {
             *     label: 'Geofence',
             *     value: 'geofence',
             *     hideOnTenant: 'GEOFENCE',
             *     inputType: 'select',
             *     helpText: 'Select a Geofence that has been configured under Geofence in configurations tab',
             *     optionsVal: 'geofenceConfigs',
             *     optionLabel: 'name',
             *     optionValue: 'config',
             *     removable: true,
             *     profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             */
            {
                label: 'Sim Setting',
                value: 'simsetting',
                hideOnTenant: 'SIM_SETTINGS',
                inputType: 'select',
                helpText: 'Select a Sim Settings that has been configured under Sim Settings in configurations tab',
                optionsVal: 'simSettings',
                optionLabel: 'name',
                optionValue: 'config',
                removable: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            /*
             * {
             *     label: 'Package Config',
             *     value: 'packages',
             *     hideOnTenant: 'PACKAGES',
             *     inputType: 'select',
             *     helpText: 'Select the Pacakge Config',
             *     optionsVal: 'packages',
             *     optionLabel: 'name',
             *     optionValue: 'config',
             *     removable: true,
             *     profileType: ['Fully Managed Device','Kiosk Launcher','Tectoro Launcher', 'Work Profile', 'Single App']
             * },
             */
            {
                label: 'Contact List',
                value: 'contacts',
                hideOnTenant: 'CONTACT_LIST',
                inputType: 'select',
                removable: true,
                helpText: 'Select a Contact list that has been configured under Contact list in configurations tab',
                optionsVal: 'contacts',
                optionLabel: 'name',
                optionValue: 'id',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Call Setting',
                value: 'callconfig',
                hideOnTenant: 'CALL_CONFIG',
                inputType: 'select',
                removable: true,
                helpText: 'Select a Call setting that has been configured under Call setting in configurations tab',
                optionsVal: 'callconfigs',
                optionLabel: 'name',
                optionValue: 'id',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Certificate',
                value: 'certificates',
                hideOnTenant: 'CERTIFICATE_MANAGEMENT',
                inputType: 'select',
                isMulti: true,
                removable: true,
                helpText: 'Select a Certificate that has been configured under certificate in configurations tab',
                optionsVal: 'certificates',
                optionLabel: 'aliasName',
                optionValue: 'serverFileName',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'ODM Feature Types',
                value: 'odmFeatureType',
                inputType: 'select',
                isMulti: true,
                removable: true,
                helpText: 'Select the type of ODM features required',
                options: [
                    { label: 'Acer', value: 'acer' },
                    { label: 'Lava', value: 'lava' }
                ],
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Acer ODM Feature',
                value: 'acerodmfeatures',
                showKey: 'odmFeatureType',
                showValue: 'acer',
                mandatory: true,
                inputType: 'select',
                removable: true,
                helpText: 'Select a Acer ODM Feature that has been configured under ODM Feature in configurations tab',
                optionsVal: 'acerodmfeatures',
                optionLabel: 'name',
                optionValue: 'config',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Lava ODM Feature',
                value: 'lavaodmfeatures',
                showKey: 'odmFeatureType',
                mandatory: true,
                showValue: 'lava',
                inputType: 'select',
                removable: true,
                helpText: 'Select a Lava ODM Feature that has been configured under ODM Feature in configurations tab',
                optionsVal: 'lavaodmfeatures',
                optionLabel: 'name',
                optionValue: 'config',
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App']
            },
            {
                label: 'Features',
                value: 'feature',
                optionsBasedOnTenant: true,
                inputType: 'select',
                helpText: 'Select a Features that has been configured under features in configurations tab',
                isMulti: true,
                profileType: ['Fully Managed Device', 'Kiosk Launcher', 'Tectoro Launcher', 'Work Profile', 'Single App'],
                options: [
                    { label: 'Enable Call Logs', value: 'enable_call_logs', hideOnTenant: 'enable_call_logs_config' },
                    { label: 'Enable App Usage Report', value: 'enable_app_usage_report', hideOnTenant: 'enable_app_usage_report_config' },
                    { label: 'Enable Violations', value: 'enable_violations', hideOnTenant: 'enable_violations_cofig' },
                    { label: 'Enable Location History', value: 'enable_location_history', hideOnTenant: 'enable_location_history_config' },
                    { label: 'Enable SIM Operators', value: 'enable_sim_operators', hideOnTenant: 'enable_sim_operators_config' },
                    { label: 'Enable Battery Percentage', value: 'enable_battery', hideOnTenant: 'enable_battery_config' },
                    {
                        label: 'Enable Last Modified Date',
                        value: 'enable_last_modified_date',
                        hideOnTenant: 'enable_last_modified_date_config'
                    },
                    { label: 'Enable Personal Account', value: 'enable_personal_account', hideOnTenant: 'enable_personal_account_config' },
                    { label: 'Enable Home Screen Wallpaper', value: 'enable_wallpaper', hideOnTenant: 'enable_wallpaper_config' },
                    {
                        label: 'Enable Lock Screen Wallpaper',
                        value: 'enable_lock_screen_wallpaper',
                        hideOnTenant: 'enable_lock_screen_wallpaper'
                    },
                    { label: 'Enable Boot Animation', value: 'enable_boot_animation', hideOnTenant: 'enable_boot_animation_config' },
                    { label: 'Enable Shutdown Logo', value: 'enable_shutdown_logo', hideOnTenant: 'enable_shutdown_logo' }
                ]
            }
        ]
    }
];
