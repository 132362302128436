import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TableContainer from '../../../Components/Common/TableContainer';
import { priviliges, COMMON_SCHEMA } from '../../../Components/constants/constants';
import { APIClient } from '../../../helpers/api_helper';
import { useAsyncDebounce } from 'react-table';
import { convertUTCtoIST, getFormTypeAndRecordId, convertDate } from '../../../Components/Common/Util';
import Loader from '../../../Components/Common/Loader';

const AuditLog = () => {
    const api = new APIClient();
    const history = useHistory();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const recordID = formTypeAndId['recordID'];
    const auditType = window.location.hash.split('/').reverse()[2];
    const [loading, setLoading] = useState(false);
    const [detailsObj, setDetailsObj] = useState({});
    const [allAudits, setAllAudits] = useState([]);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 100 });
    const [selectedObj, setSelectedObj] = useState(COMMON_SCHEMA[auditType]);

    useEffect(() => {
        // getPolicy();
        getAuditLogs(searchParams);
    }, []);

    // const getPolicy = () => {
    //     api.get(selectedObj.url + '/' + formTypeAndId?.recordID).then((resp) => {
    //         if (resp.status === 'success') setPolicy(resp.data);
    //     });
    // };

    const getAuditLogs = (params) => {
        setLoading(true);
        params.recordid = recordID;
        api.get(selectedObj.url, params, selectedObj.domain)
            .then((resp) => {
                if (resp?.status === 'success') {
                    const response = resp?.data?.length > 0 ? resp.data : [];
                    if (response?.length > 0) {
                        switch (auditType) {
                            case 'aepolicies': {
                                response.forEach((element) => {
                                    element.modifiedDate = convertUTCtoIST(element.lastmodifiedTime);
                                    element.modifiedBy = element.lastmodifiedBy;
                                    element.actions = 'nothing';
                                });
                            }
                            case 'users': {
                                response.forEach((element) => {
                                    element.date = convertUTCtoIST(element.lastmodifiedTime);
                                    element.loginDate = convertUTCtoIST(element.createdTime);
                                    element.actions = 'nothing';
                                });
                            }
                            case 'devicegroups': {
                                detailsObj.name = response[0].record.name;
                                detailsObj.code = response[0].record.code;
                                response.forEach((element) => {
                                    element.date = convertUTCtoIST(element.modifiedDate);
                                    element.action = 'Modified';
                                });
                            }
                        }
                    }
                    setAllAudits(response);
                    setLoading(false);
                }
            })
            .catch((error) => setLoading(false));
    };
    const handleGlobalSearch = useAsyncDebounce((val) => getAuditLogs({ ...searchParams, username: val }), 200);

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, from: convertDate(val?.[0], '-'), to: convertDate(val?.[1], '-') };
        else params = { ...searchParams, from: undefined, to: undefined };
        getAuditLogs(params);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="Audit Log" history={history} homeLink="Dashboard" showBack={true} backLink={auditType} />
                    <Card>
                        <CardHeader>
                            <div className="d-flex mb-2">
                                <div className="me-2 fw-medium">{selectedObj.name} :</div>
                                <div className="text-muted">{detailsObj.name}</div>
                            </div>
                            <div className="d-flex">
                                <div className="me-2 fw-medium">{selectedObj.code} :</div>
                                <div className="text-muted">{detailsObj.code}</div>
                            </div>
                        </CardHeader>
                        <CardBody className="p-0">
                            <TableContainer
                                isGlobalFilter={true}
                                searchFilter2={selectedObj?.searchFilter}
                                dropDownFilter2={selectedObj?.dropdownFilter}
                                dateRangeFilter={selectedObj?.dateRangeFilter}
                                tableHeader={'Audit Logs'}
                                datePlaceHolder={'Date Range'}
                                handleGlobalSearch={handleGlobalSearch}
                                handleDateSearch={handleDateSearch}
                                columns={selectedObj?.schema}
                                data={allAudits}
                                editor={priviliges.POLICY_EDITOR}
                                reader={priviliges.POLICY_READER}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-nowrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AuditLog;
